import { createSelector } from 'reselect';

interface itemsSlice {
  items: {
    editorMode: boolean;
    libraryVersion: string | null;

    items: { data: any; totalSize: number };

    propAnimations: any;

    propSearchAnimations: any;

    searchItems: {
      data: any;
      searchTerm: string;
      autoComplete: string[];
      tags: string;
      totalSize: number;
    };

    itemsProgress: { [itemId: string]: number };

    favoriteItems: {
      data: { [key: string]: any };
      favoriteItemsIds: { [key: string]: string };
    };

    downloadedItemsIds: { [key: string]: boolean };
    downloadedItems: {};

    instantiatedItems: { [key: string]: any };
    purchasedItems: { [key: string]: any };

    pendingItems: { [key: string]: any };

    downloadedItemsPending: boolean;
    pendingItemsLoading: boolean;

    subcategories?: {
      types: {
        [key: string]: {
          subcategory: string;
          subList?: string[];
          packID?: string;
        };
      };
      selectedSubCategory: string | null;
    };
    subcategoriesProgress: {
      [key: string]: { progress: number; progressText: string };
    };

    filters: {
      colors: string[];
      materialTypes: string[];
      eras: string[];
      subcategories: string[];
    };
  };
}

export const editorModeSelector = (store: itemsSlice) => store.items.editorMode;

export const libraryVersionSelector = (store: itemsSlice) =>
  store.items.libraryVersion;

export const downloadedItemsIdsSelector = (store: itemsSlice) =>
  store.items.downloadedItemsIds ?? {};

export const itemsProgressSelector = (store: itemsSlice) =>
  store.items.itemsProgress;

export const favoriteItemIdsSelector = (store: itemsSlice) =>
  store.items.favoriteItems.favoriteItemsIds;

export const subcategoriesProgressSelector = (store: itemsSlice) =>
  store.items.subcategoriesProgress;

export const propAnimationsSelector = (store: itemsSlice) =>
  store.items.propAnimations;

export const propAnimationsSearchSelector = (store: itemsSlice) =>
  store.items.propSearchAnimations;

export const downloadedItemsPendingSelector = (store: itemsSlice) =>
  store.items.downloadedItemsPending || store.items.pendingItemsLoading;

export const finishedDownloadedItemsSelector = createSelector(
  (store: itemsSlice) => store.items.downloadedItems ?? {},
  favoriteItemIdsSelector,
  downloadedItemsIdsSelector,
  itemsProgressSelector,
  (items, favoriteItemIds, downloadedItems, itemsProgress) => {
    return prepareItems(items, favoriteItemIds, downloadedItems, itemsProgress);
  }
);

export const pendingItemsSelector = createSelector(
  (store: itemsSlice) => store.items.pendingItems ?? {},
  favoriteItemIdsSelector,
  downloadedItemsIdsSelector,
  itemsProgressSelector,
  (items, favoriteItemIds, downloadedItems, itemsProgress) => {
    return prepareItems(items, favoriteItemIds, downloadedItems, itemsProgress);
  }
);

export const downloadedItemsSelector = createSelector(
  (store: itemsSlice) => store.items.downloadedItems ?? {},
  (store: itemsSlice) => store.items.pendingItems ?? {},
  favoriteItemIdsSelector,
  downloadedItemsIdsSelector,
  itemsProgressSelector,
  (items, pendingItems, favoriteItemIds, downloadedItems, itemsProgress) => {
    return prepareItems(
      { ...items, ...pendingItems },
      favoriteItemIds,
      downloadedItems,
      itemsProgress
    );
  }
);

export const instantiatedItemsSelector = createSelector(
  [
    (store: itemsSlice) => store.items.instantiatedItems,
    favoriteItemIdsSelector,
    downloadedItemsIdsSelector,
    itemsProgressSelector,
  ],
  (items, favoriteItemIds, downloadedItems, itemsProgress) => {
    return prepareItems(items, favoriteItemIds, downloadedItems, itemsProgress);
  }
);

export const purchasedItemsSelector = createSelector(
  [
    (store: itemsSlice) => store.items.purchasedItems,
    favoriteItemIdsSelector,
    downloadedItemsIdsSelector,
    itemsProgressSelector,
  ],
  (items, favoriteItemIds, downloadedItems, itemsProgress) => {
    return prepareItems(items, favoriteItemIds, downloadedItems, itemsProgress);
  }
);

export const purchasedInstantiatedItems = createSelector(
  [
    (store: itemsSlice) => store.items.purchasedItems,
    (store: itemsSlice) => store.items.instantiatedItems,
    favoriteItemIdsSelector,
    downloadedItemsIdsSelector,
    itemsProgressSelector,
  ],
  (
    purchasedItems,
    instantiatedItems,
    favoriteItemIds,
    downloadedItems,
    itemsProgress
  ) => {
    const items = { ...purchasedItems, ...instantiatedItems };
    return prepareItems(items, favoriteItemIds, downloadedItems, itemsProgress);
  }
);

export const userAssetsSelector = createSelector(
  (store: itemsSlice) => store.items.purchasedItems,
  (store: itemsSlice) => store.items.instantiatedItems,
  (store: itemsSlice) => store.items.favoriteItems.data,
  favoriteItemIdsSelector,
  downloadedItemsIdsSelector,
  itemsProgressSelector,
  (
    purchasedItems,
    instantiatedItems,
    favItems,
    favoriteItemIds,
    downloadedItems,
    itemsProgress
  ) => {
    const items = { ...purchasedItems, ...instantiatedItems, ...favItems };
    return prepareItems(items, favoriteItemIds, downloadedItems, itemsProgress);
  }
);

export const itemsSelector = createSelector(
  (store) => store.items.items.data,
  favoriteItemIdsSelector,
  downloadedItemsIdsSelector,
  itemsProgressSelector,
  (items, favoriteItemIds, downloadedItems, itemsProgress) => {
    return prepareItems(items, favoriteItemIds, downloadedItems, itemsProgress);
  }
);

export const searchItemsSelector = createSelector(
  (store) => store.items.searchItems.data,
  favoriteItemIdsSelector,
  downloadedItemsIdsSelector,
  itemsProgressSelector,
  (items, favoriteItemIds, downloadedItems, itemsProgress) => {
    return prepareItems(items, favoriteItemIds, downloadedItems, itemsProgress);
  }
);

export const favoriteItemsSelector = createSelector(
  (store) => store.items.favoriteItems.data,
  favoriteItemIdsSelector,
  downloadedItemsIdsSelector,
  (items, favoriteItemIds, downloadedItems) => {
    return prepareItems(items, favoriteItemIds, downloadedItems) ?? [];
  }
);

export const itemsSizeSelector = (store) => store.items.items.totalSize;
export const searchItemsSizeSelector = (store) =>
  store.items.searchItems.totalSize;
export const searchItemsFiltersSelector = (store) =>
  store.items.searchItems.filters;

export const selectedSubcategoryIndexSelector = (store: itemsSlice) =>
  store.items.subcategories?.selectedSubCategory;

export const subcategoriesSelector = (store: itemsSlice) => {
  let subcategories = store.items.subcategories?.types;
  const subCatArray = Object.values(subcategories ?? {})?.map((category) => ({
    name: category.subcategory,
    subList: category.subList,
    packId: category.packID,
  }));
  return subCatArray;
};

// export const selectedSubcategorySelector = createSelector(
//   [subcategoriesSelector, selectedSubcategoryIndexSelector],
//   (subcategories, index) => {
//     return index ? subcategories[index] : null;
//   }
// );

export const selectedSubcategorySelector = (store: itemsSlice) =>
  store.items?.subcategories?.selectedSubCategory;

const prepareItems = (
  items,
  favoriteItemIds,
  downloadedItems,
  itemsProgress = {}
) => {
  const result: any[] = Object.values(items ?? {});

  result.forEach((item: any, index) => {
    result[index] = { ...item, progress: itemsProgress[item.itemID] };
    if (downloadedItems[item.itemID]) {
      result[index] = { ...result[index], isDownloaded: true, progress: null };
    }
    if (favoriteItemIds[item.itemID]) {
      result[index] = { ...result[index], isFavorite: true };
    }
  });
  return result;
};

export const searchColorsSelector = (store: itemsSlice) =>
  store.items.filters.colors;

export const searchMaterialTypesSelector = (store: itemsSlice) =>
  store.items.filters.materialTypes;

export const searchErasSelector = (store: itemsSlice) =>
  store.items.filters.eras;

export const subcategoryFiltersSelector = (store: itemsSlice) =>
  store.items.filters.subcategories;

export const searchOptionsSelector = createSelector(
  // [subcategoryFiltersSelector],
  (store: itemsSlice) => store.items.searchItems.autoComplete,
  (items) => {
    if (!items) return [];
    let options: string[] = [];
    // subcategories.forEach((item) => {
    //   options.push(...item.split('/'));
    // });
    options.push(...items);

    // Remove duplicates
    options = options.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });

    // Trim
    options = options.map((option) => option.trim());

    // Sort
    // options = options.sort((a, b) => a.localeCompare(b));

    return options;
  }
);

// Edit items options

export const categoryOptionsSelector = createSelector(
  [subcategoriesSelector],
  (subcategories) => {
    return subcategories.map((subcategory) => subcategory.name);
  }
);

export const subcategoryOptionsSelector = createSelector(
  [subcategoriesSelector],
  (subcategories) => {
    const ret: { [category: string]: string[] } = {};
    subcategories.forEach((subcategory) => {
      if (subcategory.subList) ret[subcategory.name] = subcategory.subList;
    });
    return ret;
  }
);
