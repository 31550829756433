import './ImageCrop.scss';
import 'antd/dist/antd.css';
import React, { useState } from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { resizeImage } from './Resize';

type propTypes = {
  id?: string;
  onImageLoad: (url, blob) => void;
  backgroundImgSrc?: string;
  children?: any;
};

interface File {
  uid: string;
  name: string;
  status: 'error' | 'success' | 'done' | 'uploading' | 'removed' | undefined;
  url: string;
  size: number;
  type: string;
}

interface Image {
  name: string;
  blobUrl: string;
  blob: Blob;
  type: string;
}

export const ImageCrop = ({
  id = '',
  onImageLoad,
  backgroundImgSrc = '',
  children,
}: propTypes) => {
  const [img, setImg] = useState({} as Image);
  const [fileList, setFileList] = useState([
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url:
    //     'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
  ] as File[]);

  const onChange = ({ fileList: newFileList }) => {
    const imgFile = newFileList[newFileList.length - 1];
    const { name, thumbUrl, originFileObj, type } = imgFile;

    const done = imgFile.response?.status;

    setImg({ name, blobUrl: thumbUrl, blob: originFileObj, type });
    setFileList([imgFile]);
  };

  const onChange2 = ({ file }) => {
    const url = URL.createObjectURL(file.originFileObj);

    fetch(url)
      .then((r) => r.blob())
      .then((b) => {
        resizeImage(url, onResize, 512, 512);
        // onImageLoad(url, b);
      });
  };

  // const onPreview = async (file) => {
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   const imgWindow = window.open(src);
  //   if (!imgWindow) {
  //     console.error('Failed to open image window');
  //     return;
  //   }
  //   imgWindow.document.write(image.outerHTML);
  // };

  // const onLoad = () => {
  //   // onImageLoad(img);
  //   setFileList([]);
  //   resizeImage(img.blobUrl, onResize, 200, 200);
  // };

  const onResize = (blob: Blob, url: string) => {
    const resizedImage: Image = {
      name: img.name,
      blobUrl: url,
      blob: blob,
      type: img.type,
    };

    onImageLoad(url, blob);
  };

  const dummyRequest = ({ file, onSuccess, ...rest }) => {};

  return (
    <div id={id}>
      {/* <img
        src={img.blobUrl}
        width="120"
        height="120"
        onLoad={onLoad}
        style={{ display: img.blobUrl ? 'inline' : 'none' }}
      /> */}
      <ImgCrop rotate>
        <Upload
          action=""
          listType={undefined}
          fileList={fileList}
          customRequest={dummyRequest as any}
          onChange={onChange2}
          // onPreview={onPreview}
          // beforeUpload
        >
          <div
            className="img-upload-icon"
            style={{
              background: `url("${
                backgroundImgSrc?.length > 0 && backgroundImgSrc
              }")`,
            }}
          >
            <div>+</div>
          </div>
        </Upload>
      </ImgCrop>
    </div>
  );
};
