import Loader from 'common/components/loader/Loader';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';

export const LazyImage = ({ className = '', url, children }) => {
  const [img, setImg] = useState(new Image());
  const [src, setSrc] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (img && url) {
      setLoading(true);
      img.src = url;
      img.onload = () => {
        setSrc(url);
        setLoading(false);
      };
    }
  }, [img, url]);

  return loading ? (
    <Loader show={loading} spinnerSize={15}></Loader>
  ) : (
    <div className={className} style={{ background: `url(${src})` }}>
      {children}
    </div>
  );
};
