import './CategoryLibrary.scss';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  favoriteItemIdsSelector,
  favoriteItemsSelector,
  itemsSelector,
  itemsSizeSelector,
  libraryVersionSelector,
  purchasedInstantiatedItems,
  selectedSubcategoryIndexSelector,
  selectedSubcategorySelector,
  subcategoriesProgressSelector,
  subcategoriesSelector,
} from 'redux/slices/items/selectors';
import Library from '../library/Library';
import {
  itemsPendingSelector,
  subcategoriesPendingSelector,
} from 'redux/slices/loaders/loadersSelectors';
import {
  fetchAssets,
  fetchCustomCharacters,
  fetchFavoriteItems,
  fetchItems,
  fetchPurchasedItems,
  fetchSubcategories,
  resetStore,
  selectCategory,
  selectSubcategory,
} from 'redux/slices/items/actions';
import { useHistory, withRouter } from 'react-router-dom';
import { subCategory } from '../library/types';
import firebase from 'firebase-main';
import {
  selectedSceneIdSelector,
  selectedTeamIdSelector,
} from 'redux/slices/app/selectors';
import {
  userIdSelector,
  userNameSelector,
} from 'redux/slices/auth/authSelectors';
import { sendAmplitudeEvents } from 'redux/common/actions';

const itemTypeToName = {
  CameraItems: 'Cameras',
  LightItems: 'Lights',
  CharacterItems: 'Characters',
  PropItems: 'Props',
  AnimationItems: 'Animations',
  EnvironmentItems: 'Environments',
  SetConstruction: 'Set Construction',
};

const itemTypeToTag = {
  CameraItems: 'camera',
  LightItems: 'light',
  CharacterItems: 'character',
  PropItems: 'prop',
  EnvironmentItems: 'environment',
  Template: 'template',
};

const CHUNK_SIZE = 20;

const CategoryLibrary = ({ match }) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const userId = useSelector(userIdSelector);
  const username = useSelector(userNameSelector);
  const libraryVersion = useSelector(libraryVersionSelector);

  const libVersion = useSelector(libraryVersionSelector);

  const teamId = useSelector(selectedTeamIdSelector);
  const sceneId = useSelector(selectedSceneIdSelector);

  const { itemType = 'Store' } = match.params;
  const title = itemTypeToName[itemType] ?? itemType;

  const favoriteItems = useSelector(favoriteItemsSelector);
  const purchasedItems = useSelector(purchasedInstantiatedItems);

  const subcategories = useSelector(subcategoriesSelector);
  const sortedSubcategories = subcategories.sort((a, b) => {
    if (a.name === 'Featured') return -1;
    if (b.name === 'Featured') return 1;
    if (a.name === 'All') return -1;
    if (b.name === 'All') return 1;
    return a.name.localeCompare(b.name);
  });

  // const selectedSubcategoryIndex =
  //   useSelector(selectedSubcategoryIndexSelector) ?? 0;

  const selectedSubcategory = useSelector(selectedSubcategorySelector) ?? '';

  let selectedSidebarItem = selectedSubcategory ?? 'All';
  if (params.category) selectedSidebarItem = params.category as string;
  if (params.category && params.subcategory)
    selectedSidebarItem = `${params.category}_${params.subcategory}`;

  const favoriteItemIds = useSelector(favoriteItemIdsSelector);
  // const selectedSubcategory = sortedSubcategories[selectedSubcategoryIndex];

  const subcategoriesPending = useSelector(subcategoriesPendingSelector);
  const subcategoriesProgress = useSelector(subcategoriesProgressSelector);

  const items = useSelector(itemsSelector);
  const itemsPending = useSelector(itemsPendingSelector);
  const itemsSize = useSelector(itemsSizeSelector);

  const selectedSubcategoryProgress =
    subcategoriesProgress[selectedSubcategory]?.progress;

  const selectedSubcategoryProgressText =
    subcategoriesProgress[selectedSubcategory]?.progressText;

  useEffect(() => {
    firebase.analytics().logEvent('open_library', { itemType });
    dispatch(
      sendAmplitudeEvents([
        {
          event_type: 'open_library',
          event_properties: {
            itemType,
            library_version: libraryVersion ?? 'default',
          },
        },
      ])
    );
  }, [itemType, userId, libraryVersion]);

  useEffect(() => {
    dispatch(selectCategory(itemType));
  }, [itemType]);

  useEffect(() => {
    // Selects the first subcategory on load
    // The first category fetches all items
    if (itemType === 'SetConstruction') {
      dispatch(selectSubcategory('Environment Props'));
    } else if (itemType === 'CharacterItems') {
      dispatch(selectSubcategory('Character Templates'));
    } else {
      dispatch(selectSubcategory('All'));
    }

    return () => {
      dispatch(resetStore());
    };
  }, []);

  useEffect(() => {
    if (itemType === 'CharacterItems' && selectedSidebarItem === 'All') {
      history.push({ search: 'category=Character Templates' });
      dispatch(selectSubcategory('Character Templates'));
    } else {
      dispatch(selectSubcategory(selectedSidebarItem));
    }
    // if (selectedSidebarItem.includes('_')) {
    //   const [cat, subCat] = selectedSidebarItem.split('_');
    //   history.push({ search: `?category=${cat}&subcategory=${subCat}` });
    // } else {
    //   history.push({ search: `?category=${selectedSidebarItem}` });
    // }
  }, [selectedSidebarItem]);

  useEffect(() => {
    dispatch(fetchSubcategories(itemType, teamId));
  }, [dispatch, itemType, teamId]);

  useEffect(() => {
    if (selectedSidebarItem === 'myProps') {
      dispatch(fetchPurchasedItems(teamId, libVersion, itemType));
    }
    // dispatch(fetchPurchasedItems(teamId, libVersion, 'All'));
  }, [teamId, libVersion, selectedSidebarItem]);

  useEffect(() => {
    if (favoriteItemIds && Object.keys(favoriteItemIds).length > 0)
      dispatch(fetchFavoriteItems(Object.values(favoriteItemIds), teamId));
  }, [favoriteItemIds, teamId]);

  useEffect(() => {
    if (selectedSubcategory) {
      let action = fetchItems(
        teamId,
        itemType,
        selectedSubcategory,
        items.length,
        CHUNK_SIZE
      );
      if (itemType === 'Assets')
        action = fetchAssets(
          teamId,
          libVersion,
          selectedSubcategory,
          items.length,
          CHUNK_SIZE
        );
      else if (
        itemType === 'CharacterItems' &&
        selectedSubcategory === 'Custom Characters'
      )
        action = fetchCustomCharacters(sceneId, items.length, CHUNK_SIZE);
      dispatch(action);
    }
  }, [dispatch, selectedSubcategory]);

  const subMenus: subCategory[] = sortedSubcategories.map((subcategory) => {
    const { progress } = subcategoriesProgress[subcategory.name] ?? {};
    let subList: { id: string; name: string; isDownloaded }[] = [];
    (subcategory.subList ?? []).forEach((subItem) => {
      subList.push({
        id: subItem,
        name: subItem,
        isDownloaded: subcategoriesProgress[subItem],
      });
    });

    subList = subList.sort((a, b) => a.name.localeCompare(b.name));

    return {
      id: subcategory.name,
      text: subcategory.name,
      downloadable:
        subcategory.name !== 'All' && subcategory.name !== 'Featured',
      isDownloaded: progress === 100,
      subList: subList.length > 0 ? subList : undefined,
    };
  });

  const onSubcategorySelect = (selection: string = 'All') => {
    // dispatch(selectSubcategory(index));
    if (
      itemType === 'Assets' &&
      selection !== 'All' &&
      selection !== 'Featured'
    ) {
      const selectedPackId = subcategories.find(
        ({ name }) => name === selection
      )?.packId;
      history.push({ search: `?category=${selectedPackId}` });
    } else if (selection.includes('_')) {
      const [cat, subCat] = selection.split('_');
      history.push({ search: `?category=${cat}&subcategory=${subCat}` });
    } else {
      history.push({ search: `?category=${selection}` });
    }
  };

  const onItemClick = (item) => {
    if (
      itemType === 'CharacterItems' &&
      selectedSubcategory === 'Custom Characters'
    )
      (window as any).selectObject({ ...item, itemType: 'CustomCharacter' });
    else (window as any).selectObject({ ...item, itemType });
    (window as any).addInstantiatedItems(item.itemID);
  };

  const onItemPurchase = (itemId) => {
    if (selectedSubcategory) {
      dispatch(
        fetchItems(teamId, itemType, selectedSubcategory, 0, items.length)
      );
    }
  };

  const onSubcategoryDownload = (subcategory) => {
    (window as any).downloadSubcategory(itemType, subcategory);
  };

  const onSearch = () => {
    history.push(`/library/home/search?tags=${itemTypeToTag[itemType]}`);
  };

  const itemsPendingFlag =
    (itemsPending && items?.length === 0) || subcategoriesPending;

  const extraItemsPending = itemsPending && items?.length > 0;

  const showProgressBar =
    selectedSubcategoryProgress >= 0 && selectedSubcategoryProgress < 100;

  const showDownloadBar =
    !selectedSubcategoryProgress &&
    selectedSubcategory !== 'All' &&
    selectedSubcategory !== 'Featured';

  const showSidebar =
    itemType === 'PropItems' ||
    itemType === 'LightItems' ||
    itemType === 'CharacterItems' ||
    itemType === 'SetConstruction' ||
    itemType === 'Assets';

  const onPageReachBottom = () => {
    // console.log(selectedSubcategory, itemsPending, items.length, itemsSize);

    if (!itemsPending && !subcategoriesPending) {
      if (selectedSubcategory && items.length < itemsSize) {
        let action = fetchItems(
          teamId,
          itemType,
          selectedSubcategory,
          items.length,
          CHUNK_SIZE
        );

        if (itemType === 'Assets')
          action = fetchAssets(
            teamId,
            libVersion,
            selectedSubcategory,
            items.length,
            CHUNK_SIZE
          );
        else if (
          itemType === 'CharacterItems' &&
          selectedSubcategory === 'Custom Characters'
        )
          action = fetchCustomCharacters(sceneId, items.length, CHUNK_SIZE);
        dispatch(action);
      }
    }
  };

  const mainSidebarItems: subCategory[] = [
    { id: 'Featured', text: 'Featured' },
    {
      id: 'myProps',
      text: `${username}'s ${title}`,
    },
    { id: 'saved', text: 'Saved' },
  ];

  let displayItems = items;
  if (selectedSubcategory === 'myProps')
    displayItems = purchasedItems.filter(
      ({ itemType: type }) => type === itemType
    );
  else if (selectedSubcategory === 'saved')
    displayItems = favoriteItems.filter(
      ({ itemType: type }) => type === itemType
    );

  return (
    <Library
      title={title === 'Environments' ? 'Film Sets' : title}
      showSidebar={showSidebar}
      mainSubcategories={mainSidebarItems}
      sidebarItems={subMenus}
      sidebarItemsPending={subcategoriesPending}
      // selectedSidebarItem={selectedSubcategory}
      selectedSidebarItem={selectedSidebarItem}
      onSidebarItemClick={onSubcategorySelect}
      items={displayItems}
      itemsPending={itemsPendingFlag}
      extraItemsPending={extraItemsPending}
      showProgressBar={showProgressBar}
      progressBarValue={selectedSubcategoryProgress}
      progressBarText={selectedSubcategoryProgressText}
      showDownloadBar={showDownloadBar}
      defaultView={title === 'Environments' ? 'list' : 'large'}
      onItemClick={onItemClick}
      onPackPurchase={onItemPurchase}
      onSubcategoryDownload={onSubcategoryDownload}
      onScrollReachBottom={onPageReachBottom}
      onSearch={onSearch}
    />
  );
};

export default withRouter(CategoryLibrary);
