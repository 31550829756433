import './DeleteUserModal.scss';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';
import { Form, Button } from 'antd';
import firebase from 'firebase-main';
import { useDispatch, useSelector } from 'react-redux';
import { userEmailSelector } from 'redux/slices/auth/authSelectors';
import { toasterAction } from 'redux/common/actions';

export const DeleteUserModal = ({ onCancel }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');

  const email = useSelector(userEmailSelector);

  const onFinish = async () => {
    if (password) {
      setLoading(true);
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
        await firebase.auth().currentUser?.delete();
      } catch (err) {
        console.error(err);
        dispatch(toasterAction('error', 'Failed to delete user', err.message));
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="delete-user-modal"
      visible
      onCancel={onCancel}
      title="Delete Account"
      footer={null}
      maskClosable={false}
    >
      <p>
        This operation is irreversible, once your account gets deleted, all of
        your projects will no longer exist
      </p>
      <p>Please enter your password</p>
      <input
        type="password"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        autoComplete="off"
      />

      <Form.Item>
        <Button
          className="delete-btn"
          type="primary"
          htmlType="button"
          loading={loading}
          onClick={onFinish}
        >
          Delete
        </Button>
      </Form.Item>
    </Modal>
  );
};
