import { mainActionTypes } from 'redux/common/types';

const namespace = 'items';

type itemsActionTypes = mainActionTypes;
type itemsResourceTypes =
  | 'SUBCATEGORY'
  | 'SUBCATEGORY_PROGRESS'
  | 'LIBRARY_VERSION'
  | 'DOWNLOADED_ITEMS'
  | 'PENDING_ITEMS'
  | 'SEARCH_FILTERS'
  | 'SEARCH_OPTIONS'
  | 'INSTANTIATED_ITEMS'
  | 'PURCHASED_ITEMS'
  | 'AUTO_COMPLETE'
  | 'EDITOR_MODE';
type itemsStatusTypes = 'SUCCESS' | 'FAIL';

export const itemsAction = (
  actionType: itemsActionTypes,
  resource?: itemsResourceTypes,
  status?: itemsStatusTypes
) => {
  let action = `${namespace}/${actionType}`;
  if (resource) action += `_${resource}`;
  if (status) action += `_${status}`;
  return action;
};

export type searchFilters = {
  color?: string;
  materialType?: string;
  era?: string;
  isBuilding?: boolean;
  canCarry?: boolean;
  isEnvironmentalItem?: boolean;
  tags?: string;
  animation_favorites?: boolean;
  subcategory?: string;
};
