import './GalleryHeader.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectedTeamIdSelector } from 'redux/slices/app/selectors';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import { uploadImage } from 'redux/slices/itemGallery/actions';

const GalleryHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userId = useSelector(userIdSelector);
  const teamId = useSelector(selectedTeamIdSelector);

  const [focusedOut, setFocusedOut] = useState(false);

  const onUpload = (url, blob: Blob) => {
    const timestamp = new Date().getTime();
    dispatch(
      uploadImage(
        userId,
        teamId ?? '-MHQiYsmTQVYvT9nOgWf',
        timestamp + '',
        url,
        blob
      )
    );
  };

  useEffect(() => {
    document.body.onfocus = () => {
      setFocusedOut(false);
    };

    return () => {
      document.body.onfocus = () => {};
    };
  }, []);

  return (
    <>
      {/* {focusedOut && (
        <div className="gallery-overlay">
          <span>Please select an image from the system popup</span>
        </div>
      )} */}
      <div id="gallery-header">
        <div id="gallery-back">
          <p
            onClick={() => {
              history.push(`/library/home`);
            }}
          >
            {'< Library'}
          </p>
        </div>
        <div id="gallery-title">
          <p>2D Images</p>
        </div>
        <div id="upload">
          <label
            className="custom-file-upload"
            onClick={() => {
              (window as any).onImageUpload();
            }}
          >
            {/* <input
              type="file"
              accept=".png, .jpg"
              onClick={() => {
                setFocusedOut(true);
              }}
              onChange={async (e) => {
                try {
                  // const fileUploaded: File = e?.target?.files[0];
                  const fileUploaded = e?.target.files![0];
                  const url = URL.createObjectURL(fileUploaded);
                  const blob = await fetch(url).then((r) => r.blob());
                  console.log(url, blob);
                  onUpload(url, blob);
                  // if (onFileUpload) onFileUpload(fileUploaded, url, blob);
                } catch (err) {
                  console.error(err);
                }
              }}
            /> */}
            Upload
          </label>
        </div>
      </div>
    </>
  );
};

export default GalleryHeader;
