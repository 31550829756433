import './EditAnimationModal.scss';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase-main';
import Modal from 'antd/lib/modal/Modal';
import { Checkbox, InputNumber } from 'antd';
import { ImageCrop } from 'common/components/ImageCrop/ImageCrop';
import { useDispatch, useSelector } from 'react-redux';
import { libraryVersionSelector } from 'redux/slices/items/selectors';
import { toasterAction } from 'redux/common/actions';
import Loader from 'common/components/loader/Loader';

export const EditAnimationModal = ({
  onCancel = () => {},
  onUpdate = () => {},
  animation,
}) => {
  const dispatch = useDispatch();
  const libVersion = useSelector(libraryVersionSelector);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(animation.name ?? '');
  const [tags, setTags] = useState(animation.tags ?? '');
  const [featured, setFeatured] = useState(animation.featured ?? 0);
  const [isOnline, setIsOnline] = useState(!!animation.isOnline ?? true);

  useEffect(() => {
    setName(animation.name ?? '');
    setTags(animation.tags ?? '');
    setFeatured(animation.featured ?? 0);
    setIsOnline(animation.isOnline ?? true);
  }, [animation]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const params: any = {
      library_version: libVersion,
      itemID: animation.itemID,
      name,
      tags,
      featured,
      isOnline,
    };

    try {
      await firebase.functions().httpsCallable('updateItem')(params);
      onUpdate();
    } catch (err) {
      dispatch(toasterAction('error', 'Failed to update item', err));
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      className="edit-animation-modal"
      visible
      title={null}
      footer={null}
      closable={false}
      onCancel={onCancel}
    >
      <form onSubmit={onSubmit}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          placeholder="Item's name"
          value={name}
          onChange={(v) => {
            setName(v.target.value);
          }}
        />

        <label htmlFor="name">Tags</label>
        <input
          type="text"
          id="tags"
          placeholder="Item's tags"
          value={tags}
          onChange={(v) => {
            setTags(v.target.value);
          }}
        />

        <label htmlFor="featured">Featured</label>
        <InputNumber
          id="featured"
          value={featured}
          min={0}
          max={100000000}
          onChange={(v) => {
            setFeatured(v);
          }}
        />

        <Checkbox
          checked={isOnline}
          onChange={(v) => {
            setIsOnline(v.target.checked);
          }}
        >
          IsOnline
        </Checkbox>

        <Loader
          show={loading}
          spinnerSize={20}
          centerSpinner
          spinnerColor="#0081b4"
        >
          <input type="submit" value="Submit" />
        </Loader>
      </form>
    </Modal>
  );
};
