import './BacklotScenes.scss';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { subscribeProject, unsubscribeProject } from 'redux/slices/app/actions';
import {
  availableScenesSelector,
  scenesOrderingSelector,
  selectedProjectNameSelector,
  selectedTeamIdSelector,
} from 'redux/slices/app/selectors';
import firebase from 'firebase-main';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import { openScene } from 'redux/slices/project/actions';

const BacklotScenes = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const userId = useSelector(userIdSelector);
  const teamId = useSelector(selectedTeamIdSelector);
  const projectId = (match.params as any).projectId;

  const projectName = useSelector(selectedProjectNameSelector);
  const availableScenes = useSelector(availableScenesSelector) ?? [];
  const scenesOrdering = useSelector(scenesOrderingSelector) ?? {};

  let sortedScenes = [...availableScenes].sort(
    ({ id: id1, name: name1 }, { id: id2, name: name2 }) => {
      if (!scenesOrdering[id1] && !scenesOrdering[id2])
        return name1.localeCompare(name2);
      if (scenesOrdering[id1] === undefined) return 1;
      if (scenesOrdering[id2] === undefined) return -1;
      return scenesOrdering[id1] - scenesOrdering[id2];
    }
  );

  useEffect(() => {
    if (projectId) {
      dispatch(subscribeProject(projectId));
    }
    return () => {
      if (projectId) dispatch(unsubscribeProject(projectId));
    };
  }, [projectId]);

  // useEffect(() => {
  //   (scenes ?? []).forEach(({ id }) => {
  //     if ((scenesAccess ?? {})[id] === undefined)
  //       dispatch(checkSceneAccess(id));
  //   });
  // }, [dispatch, scenes]);

  // useEffect(() => {
  //   if (!scenesAccessPending) {
  //     availableScenes.forEach(({ id }) =>
  //       dispatch(fetchSceneThumbnail(teamId, id))
  //     );
  //   }
  //   // dispatch(fetchSceneThumbnail(teamId, scene.id));
  // }, [dispatch, teamId, availableScenes, scenesAccessPending]);

  const renderScenes = sortedScenes?.map(({ id, name, thumbnail }, index) => (
    <div
      className="scene"
      key={id}
      onClick={() => {
        // (window as any).selectScene(id, name, projectId);
        // dispatchEvents([{ event_type: 'open_scene', user_id: userId }]);
        // firebase.analytics().logEvent('open_scene', { id, name, projectId });
        dispatch(openScene(teamId, projectId, id, name));
      }}
    >
      <div className="content">
        <div className="thumbnail">
          <img src={thumbnail ?? '/empty-scene.jpg'} alt="thumbnail" />
        </div>
        <div className="name">
          <span className="index">{index + 1}.</span>
          {name}
        </div>
      </div>
    </div>
  ));

  return (
    <div className="scene-switcher">
      <div className="title">{projectName}</div>
      <div className="scenes scroll-bar">{renderScenes}</div>
    </div>
  );
};

export default BacklotScenes;
