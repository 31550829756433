import './PurchaseTemplateModal.scss';
import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import Modal from 'antd/lib/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  paymentInfoSelector,
  selectedTeamIdSelector,
} from 'redux/slices/app/selectors';
import firebase from 'firebase-main';
import { libraryVersionSelector } from 'redux/slices/items/selectors';
import Loader from 'common/components/loader/Loader';
import { toasterAction } from 'redux/common/actions';
import { fetchPaymentInfo } from 'redux/slices/app/actions';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

// This modal should have 2 versions, one for billing admins and another for other types of users

interface IPack {
  packID: string;
  packName: string;
  price: number;
  size: number;
  thumbnail?: string;
  items: { id: string; name: string; thumbnail: string }[];
}

export const PurchaseTemplateModal = ({
  onCancel = () => {},
  onOk = (packId: any) => {},
  item,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();

  const price = (item.price ?? 0) / 100;
  const itemId = item.itemID;

  const driveId = useSelector(selectedTeamIdSelector);
  const paymentInfo = useSelector(paymentInfoSelector)[driveId];
  const wallet = (paymentInfo?.wallet ?? 0) / 100;

  const { name, thumbnail } = item;
  const images = thumbnail.split(',');

  const teamId = useSelector(selectedTeamIdSelector);
  const libVersion = useSelector(libraryVersionSelector);

  // const [pack, setPack] = useState<IPack | null>(null);

  const [fetchPacksPending, setFetchPacksPending] = useState(false);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [carousalIndex, setCarousalIndex] = useState(0);

  const chargeCard = price - wallet > 0;
  const chargeWallet = !chargeCard;
  const free = price === 0;

  const onSubmit = async () => {
    let err: any = null;
    if (!teamId) err = 'No drive id detected';
    if (!libVersion) err = 'No library version detected';
    if (!paymentInfo && price > wallet) err = 'This drive has no payment info';

    if (err) {
      // dispatch(toasterAction('error', err));
      return;
    }

    if (price > wallet && !paymentInfo?.card) {
      setShowPaymentModal(true);
      return;
    }

    purchaseItem();
  };

  const purchaseItem = async () => {
    setFetchPacksPending(true);
    try {
      const res = (
        await firebase.functions().httpsCallable('buyItem')({
          library_version: libVersion,
          teamId,
          itemID: itemId,
        })
      ).data;

      if (
        res.message === 'requires_action' &&
        res.client_secret &&
        res.responseToken &&
        res.paymentMethodId
      ) {
        if (!paymentInfo && price > wallet) {
          const err = 'This drive has no payment info';
          dispatch(toasterAction('error', err));
          setFetchPacksPending(false);
          return;
        }
        await stripe?.confirmCardPayment(res.client_secret, {
          payment_method: res.paymentMethodId,
        });
        await firebase.functions().httpsCallable('buyItemConfirmationResponse')(
          {
            responseToken: res.responseToken,
          }
        );
      }

      setFetchPacksPending(false);
      dispatch(fetchPaymentInfo(teamId));
      // if (packId) (window as any).onPurchasePack(packId);
      onOk(itemId);
    } catch (err) {
      console.error(err);
      dispatch(toasterAction('error', 'Failed to purchase pack', err));
      setFetchPacksPending(false);
    }
  };

  const onPaymentMethodUpdate = () => {
    purchaseItem();
  };

  const renderThumbnail =
    !images || images.length <= 1 ? (
      <div
        className="img-container"
        style={{
          backgroundImage: `url(${thumbnail})`,
        }}
      />
    ) : (
      <div className="carousel-container">
        <Carousel
          indicators={false}
          activeIndex={carousalIndex}
          onSelect={setCarousalIndex}
          interval={null}
          prevIcon={
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (carousalIndex === 0) {
                  setCarousalIndex(images.length - 1);
                } else {
                  setCarousalIndex(carousalIndex - 1);
                }
              }}
              aria-hidden="true"
              className="carousel-control-prev-icon"
            />
          }
          nextIcon={
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (carousalIndex === images.length - 1) {
                  setCarousalIndex(0);
                } else {
                  setCarousalIndex(carousalIndex + 1);
                }
              }}
              aria-hidden="true"
              className="carousel-control-next-icon"
            />
          }
        >
          {images.map((src) => (
            <Carousel.Item key={src}>
              <img className="carousel-img" src={src} alt="environment" />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    );

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="purchase-template-modal"
      visible
      onCancel={() => {
        if (!fetchPacksPending) onCancel();
      }}
      footer={null}
      closable={false}
    >
      {showPaymentModal && (
        <PaymentMethodModal
          onCancel={() => setShowPaymentModal(false)}
          onSubmit={() => {
            onPaymentMethodUpdate();
            setShowPaymentModal(false);
          }}
        />
      )}
      <Loader show={fetchPacksPending} centerSpinner spinnerColor="#289bdd">
        <>
          <div className="item-desc">
            {renderThumbnail}
            <div className="desc">
              <div className="price">
                {price ?? 0 > 0 ? `$ ${price?.toFixed(2)}` : 'Free'}
              </div>
              <div className="name">{name}</div>
            </div>
          </div>

          {/* <div className="summary">
            {chargeWallet && (price ?? 0) > 0 && (
              <p>
                {`Your $ ${wallet.toFixed(2)} wallet balance will be applied
                toward this purchase`}
              </p>
            )}

            {chargeCard && (price ?? 0) > 0 && (
              <p>{`Your VISA ****${paymentInfo?.card?.last4} card will be charged for this purchase`}</p>
            )}
          </div> */}

          <div className="footer">
            <button className="cancel" type="button" onClick={onCancel}>
              Cancel
            </button>
            <button className="submit" type="button" onClick={onSubmit}>
              {price > 0 ? `Purchase` : 'Download'}
            </button>
          </div>
        </>
      </Loader>
    </Modal>
  );
};

const PaymentMethodModal = ({ onSubmit = () => {}, onCancel = () => {} }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const driveId = useSelector(selectedTeamIdSelector);

  const [loading, setLoading] = useState(false);

  const checkForm = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement as any,
    });

    if (error) {
      dispatch(
        toasterAction('error', 'Failed to validate card info', error.message)
      );
      setLoading(false);
      return;
    }
    try {
      await firebase.functions().httpsCallable('updatePaymentMethod')({
        teamId: driveId,
        paymentMethodId: paymentMethod?.id,
      });
      dispatch(fetchPaymentInfo(driveId));
      dispatch(toasterAction('success', 'Payment method updated successfully'));
      onSubmit();
    } catch (err) {
      dispatch(toasterAction('error', 'Failed to update payment method', err));
    }
    setLoading(false);
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      className="purchase-pack-payment-modal"
      visible
      footer={null}
      onCancel={() => {
        if (!loading) onCancel();
      }}
    >
      <form name="React_Stripe" onSubmit={checkForm}>
        <CardElement />
        {/* <input type="checkbox" value="lsRememberMe" id="rememberMe" /> */}
        {/* <label htmlFor="rememberMe">Save as default payment method</label> */}
        <div>
          <button type="submit" disabled={!stripe || loading}>
            Checkout
          </button>
        </div>
      </form>
    </Modal>
  );
};
