import { sendAmplitudeEvents } from 'redux/common/actions';
import firebase from 'firebase-main';
import { appAction } from './types';
import { Action, ThunkAction } from 'redux/common/types';
import { prepareTeams } from './preparers';
import _ from 'lodash';
import { libraryVersionSelector } from '../items/selectors';
import { convertItemsToObject, extractItemId } from '../items/preparers';
import { userIdSelector, userNameSelector } from '../auth/authSelectors';
import axios from 'axios';
import moment from 'moment';

export const appActionTypes = {
  SUBSCRIBE_SCENE_THUMBNAIL: 'SUBSCRIBE_SCENE_THUMBNAIL',
  SUBSCRIBE_SCENE_THUMBNAIL_FAIL: 'SUBSCRIBE_SCENE_THUMBNAIL_FAIL',
  UNSUBSCRIBE_SCENE_THUMBNAIL: 'UNSUBSCRIBE_SCENE_THUMBNAIL',
  SET_SCENE_THUMBNAIL: 'SET_SCENE_THUMBNAIL',

  SET_SELECTED_PROJECT_ID: 'SET_SELECTED_PROJECT_ID',
  SET_SELECTED_SCENE_ID: 'SET_SELECTED_SCENE_ID',
  SET_SELECTED_TEAM_ID: 'SET_SELECTED_TEAM_ID',

  SET_MODE: 'SET_MODE',
};

export const restartApp = (): Action => ({ type: appAction('CLEAR', 'APP') });

export const setUUID = (uuid): Action => ({
  type: appAction('SET', 'UUID'),
  payload: uuid,
});

export const selectPersonalDrive = (): Action => ({
  type: appAction('SET', 'SELECTED_PERSONAL_DRIVE'),
});

export const subscribeUserDrive = (driveId: string): Action => ({
  type: appAction('SUBSCRIBE', 'PERSONAL_DRIVE'),
  meta: {
    firebaseRealtimeApi: {
      req: 'SUBSCRIBE',
      id: 'personal_drive',
      url: `teams/${driveId}`,
      onSuccessDispatches: [setUserDrive(driveId)],
      onEmptyResponseDispatches: [setUserDrive(driveId)],
      onFailDispatches: [subscribeUserDriveFail],
    },
  },
});

export const unsubscribeUserDrive = (): Action => ({
  type: appAction('UNSUBSCRIBE', 'PERSONAL_DRIVE'),
  meta: {
    firebaseRealtimeApi: { req: 'UNSUBSCRIBE', id: 'personal_drive' },
  },
});

export const setUserDrive =
  (driveId) =>
  (drive): Action => ({
    type: appAction('SET', 'PERSONAL_DRIVE'),
    payload: { ...drive, id: driveId },
  });

export const subscribeUserDriveFail = (err): Action => {
  console.error(err);
  return {
    type: appAction('SUBSCRIBE', 'PERSONAL_DRIVE', 'FAIL'),
    payload: err,
  };
};

export const subscribeTeams = (userId): Action => {
  return {
    type: appAction('SUBSCRIBE', 'TEAMS'),
    meta: {
      firebaseRealtimeApi: {
        req: 'SUBSCRIBE',
        id: 'all_teams',
        url: `users/${userId}/teams`,
        onSuccessDispatches: [setTeams],
        onEmptyResponseDispatches: [setTeams],
        dataPrepareFunctions: [prepareTeams()],
      },
    },
  };
};

export const unsubscribeTeams = (): Action => {
  return {
    type: appAction('UNSUBSCRIBE', 'TEAMS'),
    meta: {
      firebaseRealtimeApi: {
        req: 'UNSUBSCRIBE',
        id: 'all_teams',
      },
    },
  };
};

export const setTeams = (teams): Action => {
  return {
    type: appAction('SET', 'TEAMS'),
    payload: teams,
  };
};

export const subscribeTeam = (teamId): Action => {
  return {
    type: appAction('SUBSCRIBE', 'TEAM'),
    meta: {
      firebaseRealtimeApi: {
        req: 'SUBSCRIBE',
        url: `teams/${teamId}`,
        id: 'selected_team',
        onSuccessDispatches: [setTeam],
        onEmptyResponseDispatches: [setTeam],
        onFailDispatches: [subscribeTeamFail],
      },
    },
  };
};

export const unsubscribeTeam = (): Action => {
  return {
    type: appAction('UNSUBSCRIBE', 'TEAM'),
    meta: {
      firebaseRealtimeApi: {
        req: 'UNSUBSCRIBE',
        id: 'selected_team',
      },
    },
  };
};

export const setTeam = (team): Action => {
  return { type: appAction('SET', 'TEAM'), payload: team };
};

export const subscribeTeamFail = (err): Action => {
  return { type: appAction('SUBSCRIBE', 'TEAM', 'FAIL'), payload: err };
};

//------------------------------Listener for user projects---------------------------------------------

export const addProject = (project): Action => {
  return {
    type: appAction('ADD', 'PROJECT'),
    payload: project,
  };
};

export const subscribeProject = (projectId: string): Action => {
  return {
    type: appAction('SUBSCRIBE', 'PROJECT'),
    meta: {
      firebaseRealtimeApi: {
        req: 'SUBSCRIBE',
        id: `project_${projectId}`,
        url: `projects/${projectId}`,
        dataPrepareFunctions: [
          (project = {}) => {
            return { ...project, id: projectId };
          },
        ],
        onSuccessDispatches: [setProject],
        onEmptyResponseDispatches: [setProject],
      },
    },
  };
};

// export const subscribeProjectStatus = (projectId): Action => {
//   return {
//     type: appAction('SUBSCRIBE', 'PROJECT_DATA'),
//     payload: projectId,
//     meta: {
//       firebaseRealtimeApi: {
//         req: 'SUBSCRIBE',
//         url: `projects/${projectId}`,
//         id: `project_info_${projectId}`,
//         onSuccessDispatches: [addProjectStatus],
//         dataPrepareFunctions: [
//           (project = {}) => {
//             return { ...project, id: projectId };
//           },
//         ],
//       },
//     },
//   };
// };

// export const unsubscribeProjectStatus = (projectId): Action => {
//   return {
//     type: appAction('UNSUBSCRIBE', 'PROJECT_DATA'),
//     payload: projectId,
//     meta: {
//       firebaseRealtimeApi: {
//         req: 'UNSUBSCRIBE',
//         id: `project_info_${projectId}`,
//       },
//     },
//   };
// };

export const unsubscribeProject = (projectId): Action => {
  return {
    type: appAction('UNSUBSCRIBE', 'PROJECT'),
    payload: projectId,
    meta: {
      firebaseRealtimeApi: { req: 'UNSUBSCRIBE', id: `project_${projectId}` },
    },
  };
};

export const clearProjects = (): Action => {
  return {
    type: appAction('CLEAR', 'PROJECTS'),
  };
};

export const addProjectStatus = (project): Action => {
  return {
    type: appAction('ADD', 'PROJECT_DATA'),
    payload: project,
  };
};

export const setProject = (project): Action => {
  return {
    type: appAction('SET', 'PROJECT'),
    payload: project,
  };
};

// export const fetchSceneThumbnail = (teamId, sceneId): Action => {
//   return {
//     type: appAction('FETCH', 'SCENE_THUMBNAIL'),
//     meta: {
//       firebaseStorageApi: {
//         req: 'GET_LINK',
//         url: `teams/${teamId}/scenes/${sceneId}/scene_thumbnail.png`,
//         onSuccessDispatches: [setScenesThumbnails(sceneId)],
//         onEmptyResponseDispatches: [setScenesThumbnails(sceneId)],
//         onFailDispatches: [subscribeSceneThumbnailFail],
//       },
//     },
//   };
// };

// export const subscribeSceneThumbnail = (sceneId) => {
//   return {
//     type: appActionTypes.SUBSCRIBE_SCENE_THUMBNAIL,
//     meta: {
//       firebaseRealtimeApi: {
//         id: `scene_thumb_${sceneId}`,
//         req: fireRequestTypes.SUBSCRIBE,
//         url: `scenes/${sceneId}/public/thumbnail`,
//         onSuccessDispatches: [setScenesThumbnails(sceneId)],
//         onEmptyResponseDispatches: [setScenesThumbnails(sceneId)],
//         onFailDispatches: [subscribeSceneThumbnailFail],
//       },
//     },
//   };
// };

// export const subscribeSceneThumbnailFail = (err) => {
//   return {
//     type: appActionTypes.SUBSCRIBE_SCENE_THUMBNAIL_FAIL,
//     payload: err,
//   };
// };

// export const setScenesThumbnails = (sceneId) => (res) => {
//   return {
//     type: appActionTypes.SET_SCENE_THUMBNAIL,
//     payload: { sceneId, thumbnail: res },
//   };
// };

// export const unsubscribeSceneThumbnail = (sceneId) => {
//   return {
//     type: appActionTypes.UNSUBSCRIBE_SCENE_THUMBNAIL,
//     payload: sceneId,
//     meta: {
//       firebaseRealtimeApi: {
//         id: `scene_thumb_${sceneId}`,
//         req: fireRequestTypes.UNSUBSCRIBE,
//       },
//     },
//   };
// };

//-------------------------------- Scenes -------------------------------------------------

export const subscribeScene = (sceneId): Action => {
  return {
    type: appAction('SUBSCRIBE', 'SCENE'),
    meta: {
      firebaseRealtimeApi: {
        req: 'SUBSCRIBE',
        id: `scene_${sceneId}`,
        url: `scenes/${sceneId}`,
        dataPrepareFunctions: [
          (scene = {}) => {
            return { ...scene, id: sceneId };
          },
        ],
        onSuccessDispatches: [setScene],
        onEmptyResponseDispatches: [setScene],
      },
    },
  };
};

export const unsubscribeScene = (sceneId): Action => {
  return {
    type: appAction('UNSUBSCRIBE', 'SCENE'),
    meta: {
      firebaseRealtimeApi: {
        req: 'UNSUBSCRIBE',
        id: `scene_${sceneId}`,
      },
    },
  };
};

export const setScene = (scene): Action => {
  return { type: appAction('SET', 'SCENE'), payload: scene };
};

export const subscribeSceneFail = (err): Action => {
  console.error('Failed to fetch scene', err);
  return {
    type: appAction('SUBSCRIBE', 'SCENE', 'FAIL'),
  };
};

export const setSelectedTeamId = (teamId) => {
  return {
    type: appActionTypes.SET_SELECTED_TEAM_ID,
    payload: teamId,
  };
};

export const setSelectedProjectId = (projectId) => {
  return {
    type: appActionTypes.SET_SELECTED_PROJECT_ID,
    payload: projectId,
  };
};

export const setSelectedSceneId = (sceneId) => {
  return {
    type: appActionTypes.SET_SELECTED_SCENE_ID,
    payload: sceneId,
  };
};

export const renameSharedScene = (
  sceneId: string,
  sceneName: string
): Action => ({
  type: appAction('UPDATE', 'SCENE_NAME'),
  payload: { [`scenes/${sceneId}/name`]: sceneName },
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: '/',
      onSuccessDispatches: [renameSceneSuccess],
      onFailDispatches: [renameSceneFail],
    },
  },
});

export const renameScene = (
  projectId: string,
  sceneId: string,
  sceneName: string
): Action => ({
  type: appAction('UPDATE', 'SCENE_NAME'),
  payload: {
    [`scenes/${sceneId}/name`]: sceneName,
    [`projects/${projectId}/scenes/${sceneId}/name`]: sceneName,
  },
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: '/',
      onSuccessDispatches: [renameSceneSuccess],
      onFailDispatches: [renameSceneFail],
    },
  },
});

export const renameSceneSuccess = (): Action => ({
  type: appAction('UPDATE', 'SCENE_NAME', 'SUCCESS'),
  // meta: { toaster: { type: 'success', message: 'Scene renamed successfully' } },
});

export const renameSceneFail = (err): Action => ({
  type: appAction('UPDATE', 'SCENE_NAME', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to rename scene',
      description: err,
    },
  },
});

// ------------------------------- Fetch members of either the scene or team -------------------------------

export const fetchSceneMembersData = (
  sceneId: string,
  userIds: string[]
): Action => {
  return {
    type: appAction('FETCH', 'SCENE_MEMBERS_PUBLIC'),
    meta: {
      cloudRequest: {
        name: 'fetchUsersPublic',
        body: { key: sceneId, userIds },
        onSuccessDispatches: [setSceneMembersData],
        onFailDispatches: [fetchSceneMembersDataFail],
      },
    },
  };
};

export const setSceneMembersData = (data): Action => {
  return {
    type: appAction('SET', 'SCENE_MEMBERS_PUBLIC'),
    payload: data,
  };
};

export const fetchSceneMembersDataFail = (err): Action => {
  console.error(err);
  return {
    type: appAction('FETCH', 'SCENE_MEMBERS_PUBLIC', 'FAIL'),
    payload: err,
  };
};

export const fetchTeamMembersData = (
  teamId: string,
  userIds: string[]
): Action => {
  return {
    type: appAction('FETCH', 'TEAM_MEMBERS_PUBLIC'),
    meta: {
      cloudRequest: {
        name: 'fetchUsersPublic',
        body: { key: teamId, userIds },
        onSuccessDispatches: [setTeamMembersData],
        onFailDispatches: [fetchTeamMembersDataFail],
      },
    },
  };
};

export const setTeamMembersData = (data): Action => {
  return {
    type: appAction('SET', 'TEAM_MEMBERS_PUBLIC'),
    payload: data,
  };
};

export const fetchTeamMembersDataFail = (err): Action => {
  console.error(err);
  return {
    type: appAction('FETCH', 'TEAM_MEMBERS_PUBLIC', 'FAIL'),
    payload: err,
  };
};

// --------------------------------- Recent Scenes ---------------------------------

export const fetchRecentScenes = (userId, teamId): Action => {
  return {
    type: appAction('FETCH', 'RECENT_SCENES'),
    meta: {
      firebaseRealtimeApi: {
        req: 'GET',
        url: `recent_scenes/${userId}/${teamId}`,
        onSuccessDispatches: [setRecentScenes],
        onEmptyResponseDispatches: [setRecentScenes],
      },
    },
  };
};

export const fetchRecentScenesFail = (err): Action => {
  console.error(err);
  return {
    type: appAction('FETCH', 'RECENT_SCENES', 'FAIL'),
    payload: err,
  };
};

export const setRecentScenes = (scenes): Action => {
  return {
    type: appAction('SET', 'RECENT_SCENES'),
    payload: scenes,
  };
};

/** Checks if the current user has access to the specified scene or not */
export const checkSceneAccess = (sceneId): Action => {
  return {
    type: appAction('CHECK', 'SCENE_ACCESS'),
    meta: {
      firebaseRealtimeApi: {
        req: 'GET',
        url: `scenes/${sceneId}/name`,
        onSuccessDispatches: [checkSceneAccessSuccess(sceneId)],
        onEmptyResponseDispatches: [checkSceneAccessSuccess(sceneId)],
        onFailDispatches: [checkSceneAccessFail(sceneId)],
      },
    },
  };
};

export const setScenesAccess = (scenesAccess): Action => {
  return {
    type: appAction('SET', 'SCENE_ACCESS'),
    payload: scenesAccess,
  };
};

export const checkSceneAccessSuccess = (sceneId) => (): Action => {
  return {
    type: appAction('CHECK', 'SCENE_ACCESS', 'SUCCESS'),
    payload: sceneId,
  };
};

export const checkSceneAccessFail = (sceneId) => (): Action => {
  return {
    type: appAction('CHECK', 'SCENE_ACCESS', 'FAIL'),
    payload: sceneId,
  };
};

// --------------------------------- Rename Project ---------------------------------
export const renameProject = (teamId, projectId, newProjectName): Action => ({
  type: appAction('UPDATE', 'PROJECT_NAME'),
  payload: {
    [`teams/${teamId}/projects/${projectId}/name`]: newProjectName,
    [`projects/${projectId}/name`]: newProjectName,
  },
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: '/',
      onFailDispatches: [renameProjectFail],
    },
  },
});

export const renameSharedProject = (projectId, newProjectName): Action => ({
  type: appAction('UPDATE', 'PROJECT_NAME'),
  payload: {
    [`projects/${projectId}/name`]: newProjectName,
  },
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: '/',
      onFailDispatches: [renameProjectFail],
    },
  },
});

export const renameProjectFail = (err): Action => {
  return {
    type: appAction('UPDATE', 'PROJECT_NAME'),
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to rename project',
        description: err,
      },
    },
  };
};

// --------------------------------- Remove Project ---------------------------------
export const removeProject =
  (teamId, projectId, projectName) => (dispatch, getState) => {
    dispatch(removeProjectFromTeam(teamId, projectId));
    dispatch(addProjectToRecycleBin(teamId, projectId, projectName));
  };

export const removeProjectFromTeam = (teamId, projectId): Action => {
  return {
    type: appAction('REMOVE', 'PROJECT'),
    meta: {
      firebaseRealtimeApi: {
        req: 'REMOVE',
        url: `teams/${teamId}/projects/${projectId}`,
        onFailDispatches: [removeProjectFail],
      },
    },
  };
};

export const addProjectToRecycleBin = (
  teamId,
  projectId,
  projectName
): Action => {
  return {
    type: appAction('REMOVE', 'PROJECT'),
    payload: { [projectId]: projectName },
    meta: {
      firebaseRealtimeApi: {
        req: 'UPDATE',
        url: `teams/${teamId}/deletedProjects`,
        onFailDispatches: [removeProjectFail],
      },
    },
  };
};

export const removeProjectFail = (err): Action => {
  return {
    type: appAction('REMOVE', 'PROJECT', 'FAIL'),
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to delete project',
        description: err,
      },
    },
  };
};

// --------------------------------- Reorder Scenes ---------------------------------
export const reorderScenes = (projectId, newOrdering): Action => {
  return {
    type: appAction('UPDATE', 'SCENES_ORDERING'),
    payload: newOrdering,
    meta: {
      firebaseRealtimeApi: {
        req: 'SET',
        url: `projects/${projectId}/scenesOrder`,
      },
    },
  };
};

export const reorderScenesFail = (err): Action => {
  return {
    type: appAction('UPDATE', 'SCENES_ORDERING', 'FAIL'),
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to reorder scenes',
        description: err,
      },
    },
  };
};

// --------------------------------- Edit Scene Members ---------------------------------
export const updateSceneMemberRole = (
  projectId,
  sceneId,
  userId,
  role: 'Editor' | 'Reviewer'
): Action => ({
  type: appAction('UPDATE', 'SCENE_MEMBERS_ROLE'),
  payload: { projectId, userId },
  meta: {
    cloudRequest: {
      name: 'changeShareRole',
      body: { type: 'scene', projectId, sceneId, userId, role },
      onSuccessDispatches: [updateSceneMemberRoleSuccess(userId)],
      onFailDispatches: [updateSceneMemberRoleFail(userId)],
    },
  },
});

export const updateSceneMemberRoleSuccess = (userId) => (): Action => ({
  type: appAction('UPDATE', 'SCENE_MEMBERS_ROLE', 'SUCCESS'),
  payload: { userId },
});

export const updateSceneMemberRoleFail =
  (userId) =>
  (err): Action => ({
    type: appAction('UPDATE', 'SCENE_MEMBERS_ROLE', 'FAIL'),
    payload: { userId },
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to update user role',
        description: err,
      },
    },
  });

export const addProjectMember = (
  teamId,
  projectId,
  userId,
  userName
): Action => ({
  type: appAction('ADD', 'PROJECT_MEMBER'),
  payload: {
    [`projects/${projectId}/members/${userId}`]: { name: userName, id: userId },
    [`teams/${teamId}/projects/${projectId}/members/${userId}`]: {
      name: userName,
      id: userId,
    },
  },
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: '/',
      onSuccessDispatches: [addProjectMemberSuccess],
      onEmptyResponseDispatches: [addProjectMemberSuccess],
      onFailDispatches: [addProjectMemberFail],
    },
  },
});

const addProjectMemberSuccess = (): Action => ({
  type: appAction('ADD', 'PROJECT_MEMBER', 'SUCCESS'),
  meta: { amplitude: { events: [{ event_type: 'share_project' }] } },
});

const addProjectMemberFail = (err): Action => ({
  type: appAction('ADD', 'PROJECT_MEMBER', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to add user to project',
      description: err,
    },
  },
});

export const removeProjectMember = (teamId, projectId, userId): Action => ({
  type: appAction('REMOVE', 'PROJECT_MEMBER'),
  payload: {
    [`projects/${projectId}/members/${userId}`]: null,
    [`teams/${teamId}/projects/${projectId}/members/${userId}`]: null,
  },
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: '/',
      onSuccessDispatches: [removeProjectMemberSuccess],
      onEmptyResponseDispatches: [removeProjectMemberSuccess],
      onFailDispatches: [removeProjectMemberFail],
    },
  },
});

const removeProjectMemberSuccess = (): Action => ({
  type: appAction('REMOVE', 'PROJECT_MEMBER', 'SUCCESS'),
  meta: {
    amplitude: { events: [{ event_type: 'unshare_project' }] },
  },
});

const removeProjectMemberFail = (err): Action => ({
  type: appAction('REMOVE', 'PROJECT_MEMBER', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to remove member',
      description: err,
    },
  },
});

export const transferProjectOwnership = (projectId, userId): Action => ({
  type: appAction('UPDATE', 'PROJECT_OWNERSHIP'),
  payload: userId,
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: `projects/${projectId}/owner_id`,
      onSuccessDispatches: [transferProjectOwnershipSuccess],
      onEmptyResponseDispatches: [transferProjectOwnershipSuccess],
      onFailDispatches: [transferProjectOwnershipFail],
    },
  },
});

const transferProjectOwnershipSuccess = (): Action => ({
  type: appAction('UPDATE', 'PROJECT_OWNERSHIP', 'SUCCESS'),
});

const transferProjectOwnershipFail = (err): Action => ({
  type: appAction('UPDATE', 'PROJECT_OWNERSHIP', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to transfer project ownership',
      description: err,
    },
  },
});

export const addSceneMember = (
  projectId,
  sceneId,
  userId,
  userName
): Action => ({
  type: appAction('ADD', 'SCENE_MEMBERS'),
  payload: {
    [`scenes/${sceneId}/members/${userId}`]: { userId, name: userName },
    [`projects/${projectId}/scenes/${sceneId}/members/${userId}`]: {
      userId,
      name: userName,
    },
  },
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: '/',
    },
    amplitude: { events: [{ event_type: 'share_scene' }] },
  },
});

export const removeSceneMember = (projectId, sceneId, userId): Action => ({
  type: appAction('REMOVE', 'SCENE_MEMBERS'),
  payload: {
    [`scenes/${sceneId}/members/${userId}`]: null,
    [`projects/${projectId}/scenes/${sceneId}/members/${userId}`]: null,
  },
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: '/',
    },
    amplitude: { events: [{ event_type: 'unshare_scene' }] },
  },
});

export const transferSceneOwnership = (sceneId, userId): Action => ({
  type: appAction('UPDATE', 'SCENE_OWNER'),
  payload: userId,
  meta: {
    firebaseRealtimeApi: { req: 'SET', url: `scenes/${sceneId}/owner_id` },
  },
});

export const updateSceneAccess = (
  userId,
  userName,
  projectId,
  sceneId,
  isPublic: boolean
): Action => ({
  type: appAction('UPDATE', 'SCENE_IS_PUBLIC'),
  payload: {
    [`scenes/${sceneId}/is_public`]: isPublic,
    [`scenes/${sceneId}/members/${userId}`]: { name: userName, userId: userId },
    [`projects/${projectId}/scenes/${sceneId}/is_public`]: isPublic,
    [`projects/${projectId}/scenes/${sceneId}/members/${userId}`]: {
      name: userName,
      id: userId,
    },
  },
  meta: {
    firebaseRealtimeApi: { req: 'UPDATE', url: `/` },
  },
});

export const unsharePersonalScene = (sceneId, userId): Action => ({
  type: appAction('UNSHARE', 'SCENE'),
  payload: { userId },
  meta: {
    cloudRequest: {
      name: 'unSharePersonalDrive',
      body: { sceneId, userId, type: 'scene' },
      onSuccessDispatches: [unsharePersonalSceneSuccess(userId)],
      onFailDispatches: [unsharePersonalSceneFail(userId)],
    },
  },
});

const unsharePersonalSceneSuccess = (userId) => (): Action => ({
  type: appAction('UNSHARE', 'SCENE', 'SUCCESS'),
  payload: { userId },
});

const unsharePersonalSceneFail =
  (userId) =>
  (err): Action => ({
    type: appAction('UNSHARE', 'SCENE', 'FAIL'),
    payload: { userId },
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to remove user',
        description: err,
      },
    },
  });

export const unsharePersonalProject = (projectId, userId): Action => ({
  type: appAction('UNSHARE', 'PROJECT'),
  payload: { userId },
  meta: {
    cloudRequest: {
      name: 'unSharePersonalDrive',
      body: { projectId, userId, type: 'project' },
      onSuccessDispatches: [unsharePersonalProjectSuccess(userId)],
      onFailDispatches: [unsharePersonalProjectFail(userId)],
    },
  },
});

const unsharePersonalProjectSuccess = (userId) => (): Action => ({
  type: appAction('UNSHARE', 'PROJECT', 'SUCCESS'),
  payload: { userId },
});

const unsharePersonalProjectFail =
  (userId) =>
  (err): Action => ({
    type: appAction('UNSHARE', 'PROJECT', 'FAIL'),
    payload: { userId },
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to remove user',
        description: err,
      },
    },
  });

export const deleteSceneInvitation = (sceneId, invitationId): Action => ({
  type: appAction('REMOVE', 'SCENE_INVITE'),
  meta: {
    firebaseRealtimeApi: {
      req: 'REMOVE',
      url: `scenes/${sceneId}/pending_invites/${invitationId}`,
      onFailDispatches: [deleteSceneInvitationFail],
    },
  },
});

const deleteSceneInvitationFail = (err): Action => ({
  type: appAction('REMOVE', 'SCENE_INVITE'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to delete this invite',
      description: err,
    },
  },
});

export const deleteProjectInvitation = (projectId, invitationId): Action => ({
  type: appAction('REMOVE', 'PROJECT_INVITE'),
  meta: {
    firebaseRealtimeApi: {
      req: 'REMOVE',
      url: `projects/${projectId}/pending_invites/${invitationId}`,
      onFailDispatches: [deleteProjectInvitationFail],
    },
  },
});

const deleteProjectInvitationFail = (err): Action => ({
  type: appAction('REMOVE', 'PROJECT_INVITE'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to delete this invite',
      description: err,
    },
  },
});
// --------------------------------- Edit Team Members ---------------------------------
export const makeAdmin = (teamId, userId): Action => ({
  type: appAction('UPDATE', 'TEAM_MEMBER_PRIVILEGE'),
  payload: { isAdmin: true },
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: `teams/${teamId}/members/${userId}`,
      onSuccessDispatches: [makeAdminSuccess],
      onFailDispatches: [makeAdminFail],
    },
  },
});

export const makeAdminSuccess = (): Action => ({
  type: appAction('UPDATE', 'TEAM_MEMBER_PRIVILEGE', 'SUCCESS'),
  meta: { toaster: { type: 'success', message: 'Update Successful!' } },
});

export const makeAdminFail = (err): Action => ({
  type: appAction('UPDATE', 'TEAM_MEMBER_PRIVILEGE', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to update privileges',
      description: err,
    },
  },
});

export const makeBillingAdmin = (teamId, userId): Action => ({
  type: appAction('UPDATE', 'TEAM_MEMBER_PRIVILEGE'),
  payload: { isBillingAdmin: true },
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: `teams/${teamId}/members/${userId}`,
      onSuccessDispatches: [makeBillingAdminSuccess],
      onFailDispatches: [makeBillingAdminFail],
    },
  },
});

export const makeBillingAdminSuccess = (): Action => ({
  type: appAction('UPDATE', 'TEAM_MEMBER_PRIVILEGE', 'SUCCESS'),
  meta: { toaster: { type: 'success', message: 'Update Successful!' } },
});

export const makeBillingAdminFail = (err): Action => ({
  type: appAction('UPDATE', 'TEAM_MEMBER_PRIVILEGE', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to update privileges',
      description: err,
    },
  },
});

export const updateTeamMemberRole =
  (userId, teamId, role) => async (dispatch, getState) => {
    dispatch({
      type: appAction('UPDATE', 'TEAM_MEMBER_PRIVILEGE'),
      payload: { isBillingAdmin: true },
      // meta: {
      //   cloudRequest: {
      //     name: 'changeMemberRole',
      //     body: { userId, teamId, role },
      //     onSuccessDispatches: [updateTeamMemberRoleSuccess],
      //     onFailDispatches: [updateTeamMemberRoleFail],
      //   },
      // },
    });

    try {
      await firebase.functions().httpsCallable('changeMemberRole')({
        userId,
        teamId,
        role,
      });
      dispatch(updateTeamMemberRoleSuccess());
      dispatch(fetchPaymentInfo(teamId));
    } catch (err) {
      dispatch(updateTeamMemberRoleFail(err));
    }
  };

export const updateTeamMemberRoleSuccess = (): Action => ({
  type: appAction('UPDATE', 'TEAM_MEMBER_PRIVILEGE', 'SUCCESS'),
  meta: { toaster: { type: 'success', message: 'Update Successful!' } },
});

export const updateTeamMemberRoleFail = (err): Action => ({
  type: appAction('UPDATE', 'TEAM_MEMBER_PRIVILEGE', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to update role',
      description: err,
    },
  },
});

// --------------------------------- Remove Scene ---------------------------------
export const removeScene = (projectId, sceneId) => (dispatch, getState) => {
  dispatch(removeSceneFromProject(projectId, sceneId));
  dispatch(deleteScene(sceneId));
};

export const removeSceneFromProject = (projectId, sceneId): Action => {
  return {
    type: appAction('REMOVE', 'SCENE'),
    meta: {
      firebaseRealtimeApi: {
        req: 'REMOVE',
        url: `projects/${projectId}/scenes/${sceneId}`,
        onSuccessDispatches: [removeSceneFromProjectSuccess],
        onEmptyResponseDispatches: [removeSceneFromProjectSuccess],
      },
    },
  };
};

export const removeSceneFromProjectSuccess = (): Action => {
  return {
    type: appAction('REMOVE', 'SCENE', 'SUCCESS'),
    meta: {
      amplitude: { events: [{ event_type: 'delete_scene' }] },
    },
  };
};

export const removeSceneFromProjectFail = (err): Action => {
  return {
    type: appAction('REMOVE', 'SCENE', 'FAIL'),
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to delete scene',
        description: err,
      },
    },
  };
};

export const deleteScene = (sceneId): Action => {
  return {
    type: appAction('REMOVE', 'SCENE'),
    meta: { firebaseRealtimeApi: { req: 'REMOVE', url: `scenes/${sceneId}` } },
  };
};

// --------------------------------- Remove Scene ---------------------------------

// export const subscribeSceneLastModified = (sceneId): Action => ({
//   type: appAction('SUBSCRIBE', 'SCENE_LAST_MODIFIED'),
//   meta: {
//     firebaseRealtimeApi: {
//       req: 'SUBSCRIBE',
//       url: `scenes/${sceneId}/last_modified`,
//       id: `${sceneId}_last_modified`,
//       onSuccessDispatches: [setSceneLastModified(sceneId)],
//       onEmptyResponseDispatches: [setSceneLastModified(sceneId)],
//       onFailDispatches: [subscribeSceneLastModifiedFail],
//     },
//   },
// });

// export const unsubscribeSceneLastModified = (sceneId): Action => ({
//   type: appAction('UNSUBSCRIBE', 'SCENE_LAST_MODIFIED'),
//   meta: {
//     firebaseRealtimeApi: { req: 'UNSUBSCRIBE', id: `${sceneId}_last_modified` },
//   },
// });

// export const setSceneLastModified = (sceneId) => (lastModified): Action => ({
//   type: appAction('SET', 'SCENE_LAST_MODIFIED'),
//   payload: { sceneId, lastModified },
// });

// export const subscribeSceneLastModifiedFail = (err): Action => {
//   console.error(err);
//   return {
//     type: appAction('SUBSCRIBE', 'SCENE_LAST_MODIFIED', 'FAIL'),
//     payload: err,
//   };
// };
// --------------------------------- Fetch Scene Templates ---------------------------------
// export const fetchSceneTemplates = () => async (dispatch, getState) => {
//   const templatesRefs = (await firebase.storage().ref('/FilmSets').listAll())
//     .prefixes;

//   const templates = {};
//   for (const templateRef of templatesRefs) {
//     try {
//       const templateName = templateRef.name;
//       const imgUrl = await templateRef
//         .child(`${templateName}.png`)
//         .getDownloadURL();
//       templates[templateName] = { name: templateName, src: imgUrl };
//       dispatch({
//         type: appAction('SET', 'SCENE_TEMPLATES'),
//         payload: _.cloneDeep(templates),
//       });
//     } catch (err) {
//       console.error(err);
//     }
//   }
// };

export const fetchSceneTemplates = (teamId) => async (dispatch, getState) => {
  const libVersion = libraryVersionSelector(getState());

  if (libVersion)
    firebase
      .functions()
      .httpsCallable('getAllItemsLibrary')({
        itemType: 'EnvironmentItems',
        filter: 'sampleScenes',
        library_version: libVersion,
        teamId,
      })
      .then((res) => {
        let data = convertItemsToObject()(res.data);
        data = extractItemId()(data);
        dispatch(setSceneTemplates(data));
      })
      .catch((err) => {
        dispatch(fetchSceneTemplatesFail(err));
      });
};

export const setSceneTemplates = (templates): Action => ({
  type: appAction('SET', 'SCENE_TEMPLATES'),
  payload: templates,
});

export const fetchSceneTemplatesFail = (err): Action => ({
  type: appAction('FETCH', 'SCENE_TEMPLATES', 'FAIL'),
  payload: err,
  // meta:{toaster:{type:'error',message:'Failed '}}
});

// --------------------------------- Create Scene ---------------------------------
export const createScene =
  (
    sceneName,
    sceneIsPublic,
    projectId,
    userId,
    orderInProject: number = 0,
    templateId?: string,
    thumbnail?: string,
    members?
  ): ThunkAction =>
  async (dispatch, getState) => {
    let newSceneRef;
    const userId = userIdSelector(getState());
    const userName = userNameSelector(getState());

    dispatch({
      type: appAction('CREATE', 'SCENE'),
      payload: {
        name: sceneName,
        order: orderInProject,
        isPublic: sceneIsPublic,
        projectId,
        userId,
        template: templateId,
        thumbnail,
      },
    });

    try {
      newSceneRef = await firebase.database().ref(`scenes/`).push();
    } catch (err) {
      console.error(err);
      dispatch(createSceneFail(err));
      dispatch(
        sendAmplitudeEvents([
          {
            event_type: 'error',
            event_properties: {
              action: 'create_scene',
              description: err,
              extra_details: 'Create a new ref in realtime db',
              timestamp: moment().format('DD/MM/YYYY hh:mm a'),
            },
          },
        ])
      );
      return;
    }

    const newSceneId: any = (newSceneRef as any).key;

    dispatch({
      type: appAction('UPDATE', 'PENDING_SCENE'),
      payload: { id: newSceneId },
    });

    const newScene: any = {
      name: sceneName,
      owner_id: userId,
      project_id: projectId,
      is_public: sceneIsPublic,
    };

    if (!sceneIsPublic && members) {
      newScene.members = members;
    }

    const projectSceneNode: any = {
      is_public: sceneIsPublic,
      name: sceneName,
      owner_id: userId,
      owner_name: userName,
    };
    if (members) projectSceneNode.members = members;

    try {
      await firebase
        .database()
        .ref('/')
        .update({
          [`scenes/${newSceneId}`]: newScene,
          [`projects/${projectId}/scenes/${newSceneId}`]: projectSceneNode,
          [`projects/${projectId}/scenesOrder/${newSceneId}`]: orderInProject,
        });
    } catch (err) {
      console.error(err);
      dispatch(createSceneFail(err));
      dispatch(removeScene(projectId, newSceneId));
      dispatch(
        sendAmplitudeEvents([
          {
            event_type: 'error',
            event_properties: {
              action: 'create_scene',
              description: err,
              extra_details: 'Create scene node in realtime db',
              timestamp: moment().format('DD/MM/YYYY hh:mm a'),
            },
          },
        ])
      );
      return;
    }

    try {
      if (templateId && templateId !== 'blank') {
        // await firebase.functions().httpsCallable('setNewSceneTemplate')({
        //   sceneId: newSceneId,
        //   filename: templateId,
        //   library_version: (window as any).libraryVersion,
        //   itemID:templateId ,
        //   // url: thumbnail,
        // });
        await axios.post(
          process.env.REACT_APP_NODE_SERVER_URL +
            'main-menu/setNewSceneTemplate',
          {
            data: {
              sceneId: newSceneId,
              filename: templateId,
              library_version: (window as any).libraryVersion,
              itemID: templateId,
              // url: thumbnail,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${await firebase
                .auth()
                .currentUser?.getIdToken()}`,
            },
          }
        );
      } else {
        // await firebase.functions().httpsCallable('setSceneThumbnail')({
        //   sceneId: newSceneId,
        // });
        await axios.post(
          process.env.REACT_APP_NODE_SERVER_URL + 'main-menu/setSceneThumbnail',
          { data: { sceneId: newSceneId } },
          {
            headers: {
              Authorization: `Bearer ${await firebase
                .auth()
                .currentUser?.getIdToken()}`,
            },
          }
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(createSceneFail(err));
      dispatch(removeScene(projectId, newSceneId));
      dispatch(
        sendAmplitudeEvents([
          {
            event_type: 'error',
            event_properties: {
              action: 'create_scene',
              description: err,
              extra_details: 'This error came from the cloud functions',
              timestamp: moment().format('DD/MM/YYYY hh:mm a'),
            },
          },
        ])
      );
      return;
    }

    dispatch(createSceneSuccess(newScene));
    // firebase.analytics().logEvent('create_scene', newScene);
    console.log(templateId);

    dispatch(
      sendAmplitudeEvents([
        {
          event_type: 'create_scene',
          event_properties: {
            name: sceneName,
            templateId: templateId ?? 'none',
          },
        },
      ])
    );

    if (!sceneIsPublic && members) {
      console.log(members);
      const newMembers = {};
      for (const key in members) {
        if (key !== userId) newMembers[key] = members[key].name;
      }
      if (newMembers)
        firebase
          .functions()
          .httpsCallable('permissionNotification')({
            id: newSceneId,
            type: 'scene',
            newMembers,
          })
          .catch((err) => {
            console.error(err);
          });
    }

    // if (!scene.isPublic) {
    //   dispatch(addMembersToScene(scene.members, newSceneId, projectMembers));
    // }
  };

export const createSceneSuccess = (scene): Action => ({
  type: appAction('CREATE', 'SCENE', 'SUCCESS'),
  // meta: { toaster: { type: 'success', message: 'Scene Created Successfully' } },
  meta: {
    // amplitude: {
    //   events: [
    //     {
    //       event_type: 'create_scene',
    //       access: scene.is_public ? 'public' : 'private',
    //     },
    //   ],
    // },
  },
});

export const createSceneFail = (description?): Action => ({
  type: appAction('CREATE', 'SCENE', 'FAIL'),
  meta: {
    toaster: { type: 'error', message: 'Failed to create scene', description },
  },
});

export const clearPendingScene = (): Action => ({
  type: appAction('CLEAR', 'PENDING_SCENE'),
});

export const createNewProject =
  (projectName, projectAccess, teamId, userId, members?): ThunkAction =>
  async (dispatch, getState) => {
    const newProjectRef = await firebase.database().ref(`projects/`).push();
    const newProjectId: any = newProjectRef.key;
    const newProject: any = {
      name: projectName,
      owner_id: userId,
      creator_id: userId,
      team_id: teamId,
      is_public: projectAccess,
    };

    if (members) newProject.members = members;

    dispatch({
      type: appAction('CREATE', 'PROJECT'),
      payload: {
        [`projects/${newProjectId}`]: newProject,
        [`teams/${teamId}/projects/${newProjectId}`]: newProject,
      },
      meta: {
        firebaseRealtimeApi: {
          req: 'UPDATE',
          url: '/',
          onSuccessDispatches: [
            createProjectSuccess({ ...newProject, id: newProjectId }),
            selectCreatedProject(newProjectId),
          ],
          onFailDispatches: [createProjectFail],
        },
      },
    });
  };

export const createPersonalProject =
  (projectName, teamId, userId): ThunkAction =>
  async (dispatch, getState) => {
    const newProjectRef = await firebase.database().ref(`projects/`).push();
    const newProjectId: any = newProjectRef.key;
    const newProject: any = {
      name: projectName,
      is_public: true,
      owner_id: userId,
      creator_id: userId,
      team_id: teamId,
    };

    dispatch({
      type: appAction('CREATE', 'PROJECT'),
      payload: {
        [`projects/${newProjectId}`]: newProject,
        [`teams/${teamId}/projects/${newProjectId}`]: newProject,
      },
      meta: {
        firebaseRealtimeApi: {
          req: 'UPDATE',
          url: '/',
          onSuccessDispatches: [
            createProjectSuccess(newProject),
            selectCreatedProject(newProjectId),
          ],
          onFailDispatches: [createProjectFail],
        },
      },
    });
  };

export const createProjectSuccess =
  (project) =>
  (res): Action => {
    firebase.analytics().logEvent('create_project', project);

    const newMembers = {};
    for (const key in project?.members) {
      if (key !== project.owner_id) newMembers[key] = project.members[key].name;
    }

    if (Object.keys(newMembers).length > 0)
      firebase
        .functions()
        .httpsCallable('permissionNotification')({
          id: project.id,
          type: 'project',
          newMembers,
        })
        .catch((err) => {
          console.error(err);
        });

    return {
      type: appAction('CREATE', 'PROJECT', 'SUCCESS'),
      meta: {
        // toaster: {
        //   type: 'success',
        //   message: 'New project created successfully!',
        // },
        amplitude: {
          events: [
            {
              event_type: 'create_project',
              event_properties: {
                access: project.is_public ? 'public' : 'private',
              },
            },
          ],
        },
      },
    };
  };

const selectCreatedProject = (id) => (): Action => ({
  type: appActionTypes.SET_SELECTED_PROJECT_ID,
  payload: id,
});

export const createProjectFail = (errorMessage): Action => {
  return {
    type: appAction('CREATE', 'PROJECT', 'FAIL'),
    payload: errorMessage,
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to create new project',
        description: errorMessage,
      },
    },
  };
};

// --------------------------------- Set the mode of the application ---------------------------------

export const subscribeSceneIsPublic = (sceneId): Action => ({
  type: appAction('FETCH', 'SCENE_IS_PUBLIC'),
  meta: {
    firebaseRealtimeApi: {
      req: 'SUBSCRIBE',
      url: `scenes/${sceneId}/is_public`,
      id: `scene_is_public_${sceneId}`,
      onSuccessDispatches: [addSceneIsPublic(sceneId)],
      onEmptyResponseDispatches: [addSceneIsPublic(sceneId)],
      onFailDispatches: [fetchSceneIsPublicFail],
    },
  },
});

export const addSceneIsPublic =
  (sceneId) =>
  (sceneIsPublic): Action => ({
    type: appAction('ADD', 'SCENE_IS_PUBLIC'),
    payload: { sceneId, sceneIsPublic },
  });

export const fetchSceneIsPublicFail = (err): Action => {
  console.error(err);
  return {
    type: appAction('FETCH', 'SCENE_IS_PUBLIC', 'FAIL'),
  };
};

export const updateProjectAccess = (
  userId,
  userName,
  teamId,
  projectId,
  isPublic
): Action => ({
  type: appAction('UPDATE', 'PROJECT_ACCESS'),
  payload: {
    [`projects/${projectId}/is_public`]: isPublic,
    [`projects/${projectId}/members/${userId}`]: { name: userName, id: userId },
    [`teams/${teamId}/projects/${projectId}/is_public`]: isPublic,
    [`teams/${teamId}/projects/${projectId}/members/${userId}`]: {
      name: userName,
      id: userId,
    },
  },
  meta: {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: '/',
      onSuccessDispatches: [updateProjectAccessSuccess],
      onEmptyResponseDispatches: [updateProjectAccessSuccess],
      onFailDispatches: [updateProjectAccessFail],
    },
  },
});

const updateProjectAccessSuccess = (): Action => ({
  type: appAction('UPDATE', 'PROJECT_ACCESS', 'SUCCESS'),
});

const updateProjectAccessFail = (err): Action => ({
  type: appAction('UPDATE', 'PROJECT_ACCESS', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to update project access',
      description: err,
    },
  },
});

// --------------------------------- Edit Project Members ---------------------------------
export const updateProjectMemberRole = (
  projectId,
  userId,
  role: 'Editor' | 'Reviewer'
): Action => ({
  type: appAction('UPDATE', 'PROJECT_MEMBER_ROLE'),
  payload: { projectId, userId },
  meta: {
    cloudRequest: {
      name: 'changeShareRole',
      body: { type: 'project', projectId, userId, role },
      onSuccessDispatches: [updateProjectMemberRoleSuccess],
      onFailDispatches: [updateProjectMemberRoleFail],
    },
  },
});

export const updateProjectMemberRoleSuccess = (): Action => ({
  type: appAction('UPDATE', 'PROJECT_MEMBER_ROLE', 'SUCCESS'),
});

export const updateProjectMemberRoleFail = (err): Action => ({
  type: appAction('UPDATE', 'PROJECT_MEMBER_ROLE', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to update user role',
      description: err,
    },
  },
});

export const subscribeSceneMembers = (sceneId): Action => {
  return {
    type: appAction('SUBSCRIBE', 'SCENE_MEMBERS'),
    payload: sceneId,
    meta: {
      firebaseRealtimeApi: {
        req: 'SUBSCRIBE',
        url: `scenes/${sceneId}/members`,
        id: `${sceneId}_scene_members`,
        onSuccessDispatches: [
          setSceneMembersSuccess(sceneId),
          setSceneMembers(sceneId),
        ],
        onEmptyResponseDispatches: [
          setSceneMembersSuccess(sceneId),
          setSceneMembers(sceneId),
        ],
        onFailDispatches: [subscribeSceneMembersFail(sceneId)],
      },
    },
  };
};

export const unsubscribeSceneMembers = (sceneId): Action => {
  return {
    type: appAction('UNSUBSCRIBE', 'SCENE_MEMBERS'),
    meta: {
      firebaseRealtimeApi: {
        req: 'UNSUBSCRIBE',
        id: `${sceneId}_scene_members`,
      },
    },
  };
};

export const subscribeScenePermissions = (sceneId): Action => {
  return {
    type: appAction('SUBSCRIBE', 'SCENE_PUBLIC'),
    payload: sceneId,
    meta: {
      firebaseRealtimeApi: {
        req: 'SUBSCRIBE',
        url: `scenes/${sceneId}/is_public`,
        id: `${sceneId}_scene_permission`,
        onSuccessDispatches: [
          subscribeSceneIsPublicSuccess(sceneId),
          setSceneIsPublic(sceneId),
        ],
        onEmptyResponseDispatches: [
          subscribeSceneIsPublicSuccess(sceneId),
          setSceneIsPublic(sceneId),
        ],
        onFailDispatches: [subscribeSceneIsPublicFail(sceneId)],
      },
    },
  };
};

export const unsubscribeScenePermissions = (sceneId): Action => {
  return {
    type: appAction('UNSUBSCRIBE', 'SCENE_PUBLIC'),
    meta: {
      firebaseRealtimeApi: {
        req: 'UNSUBSCRIBE',
        id: `${sceneId}_scene_permission`,
      },
    },
  };
};

export const setSceneMembersSuccess = (sceneId) => (): Action => {
  return {
    type: appAction('SUBSCRIBE', 'SCENE_MEMBERS', 'SUCCESS'),
    payload: sceneId,
  };
};

export const subscribeSceneMembersFail =
  (sceneId) =>
  (err): Action => {
    console.error(err);
    return {
      type: appAction('SUBSCRIBE', 'SCENE_MEMBERS', 'FAIL'),
      payload: sceneId,
    };
  };

export const setSceneIsPublic =
  (sceneId) =>
  (isPublic): Action => {
    return {
      type: appAction('SET', 'SCENE_PUBLIC'),
      payload: { sceneId, isPublic },
    };
  };

export const subscribeSceneIsPublicSuccess = (sceneId) => (): Action => {
  return {
    type: appAction('SUBSCRIBE', 'SCENE_PUBLIC', 'SUCCESS'),
    payload: sceneId,
  };
};

export const subscribeSceneIsPublicFail =
  (sceneId) =>
  (err): Action => {
    console.error(err);
    return {
      type: appAction('SUBSCRIBE', 'SCENE_PUBLIC', 'FAIL'),
      payload: sceneId,
    };
  };

export const setSceneMembers =
  (sceneId: string) =>
  (members): Action => {
    return {
      type: appAction('SET', 'SCENE_MEMBERS'),
      payload: { sceneId, members },
    };
  };

// --------------------------------- Edit Scene Members ---------------------------------

// export const addSceneMember = (sceneId, userId, userName): Action => ({
//   type: appAction('ADD', 'SCENE_MEMBERS'),
//   payload: { [userId]: { userId, name: userName } },
//   meta: {
//     firebaseRealtimeApi: {
//       req: 'UPDATE',
//       url: `scenes/${sceneId}/members`,
//     },
//   },
// });

// export const removeSceneMember = (sceneId, userId): Action => ({
//   type: appAction('REMOVE', 'SCENE_MEMBERS'),
//   meta: {
//     firebaseRealtimeApi: {
//       req: 'REMOVE',
//       url: `scenes/${sceneId}/members/${userId}`,
//     },
//   },
// });

// export const transferSceneOwnership = (sceneId, userId): Action => ({
//   type: appAction('UPDATE', 'SCENE_OWNER'),
//   payload: userId,
//   meta: {
//     firebaseRealtimeApi: { req: 'SET', url: `scenes/${sceneId}/owner_id` },
//   },
// });
// // --------------------------------- Scene Access ---------------------------------

// export const updateSceneAccess = (sceneId, isPublic: boolean): Action => ({
//   type: appAction('UPDATE', 'SCENE_IS_PUBLIC'),
//   payload: { is_public: isPublic },
//   meta: {
//     firebaseRealtimeApi: { req: 'UPDATE', url: `scenes/${sceneId}` },
//   },
// });

// --------------------------------- Duplicate scene ---------------------------------

export const duplicateScene = (sceneId, sceneName): Action => ({
  type: appAction('DUPLICATE', 'SCENE'),
  meta: {
    cloudRequest: {
      name: 'duplicateScene',
      body: { sceneId, name: sceneName },
      onSuccessDispatches: [duplicateSceneSuccess],
      onFailDispatches: [duplicateSceneFail],
    },
  },
});

export const duplicateSceneSuccess = (): Action => ({
  type: appAction('DUPLICATE', 'SCENE', 'SUCCESS'),
  meta: {
    toaster: {
      type: 'success',
      message: 'Scene duplicated successfully',
    },
  },
});

export const duplicateSceneFail = (err): Action => ({
  type: appAction('DUPLICATE', 'SCENE', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to duplicate scene',
      description: err,
    },
  },
});
// --------------------------------- Bug Report ---------------------------------
export const reportBug = (
  title,
  description,
  appVersion?,
  libraryVersion?,
  consoleLogs?,
  screenshots?,
  isUnityEditor?
): Action => ({
  type: appAction('REPORT', 'BUG'),
  meta: {
    cloudRequest: {
      name: 'pushbug',
      body: {
        title,
        description,
        appVersion,
        libraryVersion,
        consoleLogs,
        screenshots,
        isUnityEditor,
      },
      onSuccessDispatches: [reportBugSuccess],
      onFailDispatches: [reportBugFail],
    },
  },
});

export const reportBugSuccess = (): Action => ({
  type: appAction('REPORT', 'BUG', 'SUCCESS'),
  meta: { toaster: { type: 'success', message: 'Bug Reported' } },
});

export const reportBugFail = (err): Action => ({
  type: appAction('REPORT', 'BUG', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to report the issue',
      description: err,
    },
  },
});

// --------------------------------- Share project ---------------------------------

export const shareProject = (projectId, email, role): Action => ({
  type: appAction('SHARE', 'PROJECT'),
  payload: { projectId, email, role },
  meta: {
    cloudRequest: {
      name: 'shareProject',
      body: { projectId, email, role },
      onSuccessDispatches: [shareProjectSuccess],
      onFailDispatches: [shareProjectFail],
    },
  },
});

export const shareProjectSuccess = (): Action => ({
  type: appAction('SHARE', 'PROJECT', 'SUCCESS'),
});

export const shareProjectFail = (err): Action => {
  console.error(err);
  return {
    type: appAction('SHARE', 'PROJECT', 'FAIL'),
  };
};

// --------------------------------- Share scene ---------------------------------

export const sharePersonalScene = (sceneId, email, role): Action => ({
  type: appAction('SHARE', 'SCENE'),
  meta: {
    cloudRequest: {
      name: 'shareScene',
      body: { sceneId, email, role },
      onSuccessDispatches: [sharePersonalSceneSuccess],
      onFailDispatches: [sharePersonalSceneFail],
    },
  },
});

export const sharePersonalSceneSuccess = (): Action => ({
  type: appAction('SHARE', 'SCENE', 'SUCCESS'),
});

export const sharePersonalSceneFail = (err): Action => ({
  type: appAction('SHARE', 'SCENE', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to share scene',
      description: err,
    },
  },
});

// --------------------------------- Drive Subscription status ---------------------------------
export const subscribePersonalDriveStatus = (teamId): Action => ({
  type: appAction('SUBSCRIBE', 'PERSONAL_DRIVE_STATUS'),
  meta: {
    firebaseRealtimeApi: {
      req: 'SUBSCRIBE',
      id: 'personal_drive_status',
      url: `users_subscriptions/${teamId}/is_active`,
      onSuccessDispatches: [setPersonalDriveStatus],
      onEmptyResponseDispatches: [setPersonalDriveStatus],
      onFailDispatches: [subscribeDriveStatusFail],
    },
  },
});
export const subscribeTeamDriveStatus = (teamId): Action => ({
  type: appAction('SUBSCRIBE', 'DRIVE_STATUS'),
  meta: {
    firebaseRealtimeApi: {
      req: 'SUBSCRIBE',
      id: 'team_status',
      url: `subscriptions/${teamId}/is_active`,
      onSuccessDispatches: [setDriveStatus],
      onEmptyResponseDispatches: [setDriveStatus],
      onFailDispatches: [subscribeDriveStatusFail],
    },
  },
});

export const unsubscribeTeamDriveStatus = (teamId): Action => ({
  type: appAction('UNSUBSCRIBE', 'DRIVE_STATUS'),
  meta: {
    firebaseRealtimeApi: {
      req: 'UNSUBSCRIBE',
      id: 'team_status',
      url: `subscriptions/${teamId}/is_active`,
    },
  },
});

export const unsubscribeDriveStatus = (): Action => ({
  type: appAction('UNSUBSCRIBE', 'DRIVE_STATUS'),
  meta: {
    firebaseRealtimeApi: {
      req: 'UNSUBSCRIBE',
      id: 'team_status',
    },
  },
});

export const setDriveStatus = (isActive): Action => ({
  type: appAction('SET', 'DRIVE_STATUS'),
  payload: isActive,
});

export const subscribeDriveStatusFail = (err): Action => ({
  type: appAction('SUBSCRIBE', 'DRIVE_STATUS', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to check subscription status',
      description: err,
    },
  },
});
// --------------------------------- Project Subscription status ---------------------------------
export const fetchProjectStatus =
  (projectId): ThunkAction =>
  async (dispatch, state) => {
    dispatch({
      type: appAction('FETCH', 'PROJECT_STATUS'),
      payload: projectId,
    });
    try {
      const teamId = (
        await firebase
          .database()
          .ref(`projects/${projectId}/team_id`)
          .once('value')
      ).val();

      const status = (
        await firebase
          .database()
          .ref(`users_subscriptions/${teamId}/is_active`)
          .once('value')
      ).val();

      dispatch(setProjectStatus(status));
    } catch (err) {
      console.error(err);
      dispatch(fetchProjectStatusFail);
    }
  };

export const setProjectStatus = (status): Action => ({
  type: appAction('SET', 'PROJECT_STATUS'),
  payload: status,
});

const fetchProjectStatusFail = (err): Action => ({
  type: appAction('FETCH', 'PROJECT_STATUS', 'FAIL'),
});

// --------------------------------- Project Subscription status ---------------------------------
export const fetchSceneStatus =
  (sceneId): ThunkAction =>
  async (dispatch, state) => {
    dispatch({ type: appAction('FETCH', 'SCENE_STATUS'), payload: sceneId });
    try {
      const projectId = (
        await firebase
          .database()
          .ref(`scenes/${sceneId}/project_id`)
          .once('value')
      ).val();

      const teamId = (
        await firebase
          .database()
          .ref(`projects/${projectId}/team_id`)
          .once('value')
      ).val();

      const status = (
        await firebase
          .database()
          .ref(`users_subscriptions/${teamId}/is_active`)
          .once('value')
      ).val();

      dispatch(setSceneStatus(status));
    } catch (err) {
      console.error(err);
      dispatch(fetchSceneStatusFail);
    }
  };

export const setSceneStatus = (status): Action => ({
  type: appAction('SET', 'SCENE_STATUS'),
  payload: status,
});

const fetchSceneStatusFail = (err): Action => ({
  type: appAction('FETCH', 'SCENE_STATUS', 'FAIL'),
});

// --------------------------------- Payment Info ---------------------------------

export const fetchPaymentInfo = (teamId): Action => ({
  type: appAction('FETCH', 'PAYMENT_INFO'),
  meta: {
    cloudRequest: {
      name: 'billingInfo',
      body: { teamId },
      onSuccessDispatches: [setPaymentInfo(teamId)],
      onFailDispatches: [fetchPaymentInfoFail],
    },
  },
});

export const setPaymentInfo =
  (teamId) =>
  (data): Action => ({
    type: appAction('SET', 'PAYMENT_INFO'),
    payload: { teamId, data },
  });

export const fetchPaymentInfoFail = (err): Action => ({
  type: appAction('FETCH', 'PAYMENT_INFO', 'FAIL'),
  payload: err,
});

export const subscribePersonalDriveCustomerId = (id): Action => ({
  type: appAction('SUBSCRIBE', 'PERSONAL_DRIVE_CUSTOMER_ID'),
  meta: {
    firebaseRealtimeApi: {
      req: 'SUBSCRIBE',
      id: 'personal_customer_id',
      url: `users_subscriptions/${id}/customer_id`,
      onSuccessDispatches: [setPersonalDriveCustomerId],
      onEmptyResponseDispatches: [setPersonalDriveCustomerId],
      onFailDispatches: [
        unSubscribePersonalDriveCustomerId,
        subscribePersonalCustomerIdFail,
      ],
    },
  },
});

const subscribePersonalCustomerIdFail = (err): Action => ({
  type: appAction('SUBSCRIBE', 'PERSONAL_DRIVE_CUSTOMER_ID', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to check subscription status',
      description: err,
    },
  },
});

export const setPersonalDriveStatus = (isActive): Action => ({
  type: appAction('SET', 'PERSONAL_DRIVE_STATUS'),
  payload: isActive,
});

export const setPersonalIsCanceled = (isCanceled): Action => ({
  type: appAction('SET', 'PERSONAL_DRIVE_IS_CANCELED'),
  payload: isCanceled,
});

const setPersonalDriveCustomerId = (id): Action => ({
  type: appAction('SET', 'PERSONAL_DRIVE_CUSTOMER_ID'),
  payload: id,
});

export const unsubscribePersonalDriveStatus = (): Action => ({
  type: appAction('UNSUBSCRIBE', 'PERSONAL_DRIVE_STATUS'),
  meta: {
    firebaseRealtimeApi: {
      req: 'UNSUBSCRIBE',
      id: 'personal_drive_status',
    },
  },
});

export const unsubscribePersonalDriveIsCanceled = (): Action => ({
  type: appAction('UNSUBSCRIBE', 'PERSONAL_DRIVE_IS_CANCELED'),
  meta: {
    firebaseRealtimeApi: {
      req: 'UNSUBSCRIBE',
      id: 'personal_drive_is_canceled',
    },
  },
});

export const unSubscribePersonalDriveCustomerId = (): Action => ({
  type: appAction('UNSUBSCRIBE', 'PERSONAL_DRIVE_CUSTOMER_ID'),
  meta: {
    firebaseRealtimeApi: {
      req: 'UNSUBSCRIBE',
      id: 'personal_customer_id',
    },
  },
});

export const subscribePersonalDriveIsCanceled = (id): Action => ({
  type: appAction('SUBSCRIBE', 'PERSONAL_DRIVE_IS_CANCELED'),
  meta: {
    firebaseRealtimeApi: {
      req: 'SUBSCRIBE',
      id: 'personal_drive_is_canceled',
      url: `users_subscriptions/${id}/cancelled`,
      onSuccessDispatches: [setPersonalIsCanceled],
      onEmptyResponseDispatches: [setPersonalIsCanceled],
      onFailDispatches: [subscribeDriveStatusFail],
    },
  },
});
// --------------------------------- First Timer ---------------------------------
export const fetchFirstTimerFlag = (userId): Action => ({
  type: appAction('FETCH', 'FIRST_TIMER'),
  meta: {
    firebaseRealtimeApi: {
      req: 'GET',
      url: `users/${userId}/tutorials/mainMenu`,
      onSuccessDispatches: [setFirstTimerFlag],
      onEmptyResponseDispatches: [setFirstTimerFlag],
    },
  },
});

export const updateFirstTimerFlag = (userId, value): Action => ({
  type: appAction('UPDATE', 'FIRST_TIMER'),
  payload: value,
  meta: {
    firebaseRealtimeApi: {
      req: 'SET',
      url: `users/${userId}/tutorials/mainMenu`,
    },
  },
});

export const setFirstTimerFlag = (flag): Action => ({
  type: appAction('SET', 'FIRST_TIMER'),
  payload: flag,
});

// --------------------------------- Misc ---------------------------------
export const setSortableProject = (sortable): Action => ({
  type: appAction('SET', 'SORTABLE_PROJECT'),
  payload: sortable,
});

export const setTheme = (theme): Action => ({
  type: appAction('SET', 'THEME'),
  payload: theme,
});

export const fetchGeolocation = () => async (dispatch, getState) => {
  const location = (await axios.get('https://extreme-ip-lookup.com/json/'))
    .data;

  dispatch({ type: appAction('SET', 'GEO_LOCATION'), payload: location });
};

export const setSessionId = (id): Action => ({
  type: appAction('SET', 'SESSION_ID'),
  payload: id,
});

// --------------------------------- Set the mode of the application ---------------------------------
export const setMode = (mode) => {
  return {
    type: appActionTypes.SET_MODE,
    payload: mode,
  };
};

// --------------------------------- App loader ---------------------------------
export const setAppLoader = (loading: boolean): Action => ({
  type: appAction('SET', 'APP_LOADING'),
  payload: loading,
});
