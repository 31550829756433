import { Action } from 'redux/common/types';
import axios from 'axios';
import {
  userEmailSelector,
  userIdSelector,
  userIndustrySelector,
  userNameSelector,
  userRoleSelector,
} from 'redux/slices/auth/authSelectors';
import {
  geolocationSelector,
  sessionIdSelector,
  uuidSelector,
} from 'redux/slices/app/selectors';

export const amplitudeMiddleware = (store) => (next) => (action: Action) => {
  next(action);
  if (action.meta?.amplitude) {
    let { events } = action.meta.amplitude;

    const state = store.getState();

    const userId = userIdSelector(state);
    const email = userEmailSelector(state);
    const name = userNameSelector(state);
    const job = userRoleSelector(state);
    const industry = userIndustrySelector(state);

    const language = navigator.language;

    const { country, region, city, lon, lat } =
      geolocationSelector(state) ?? {};

    const session_id = sessionIdSelector(state);
    const uuid = uuidSelector(state);

    // if (userId) {
    //   events.forEach((event) => {
    //     if (!event.user_id) event.user_id = userId;
    //   });
    // }

    events = events.map((event) => {
      if (!event.user_id) event.user_id = userId;

      // User properties
      const userProperties = { email, name, job, industry };
      event.user_properties = userProperties;

      // Browser properties
      const browserProperties = {
        language,
        country,
        region,
        city,
        location_lat: lat,
        location_lng: lon,
      };

      event = { ...event, ...browserProperties, device_id: uuid, session_id };
      return event;
    });

    axios
      .post('https://api2.amplitude.com/2/httpapi', {
        api_key: process.env.REACT_APP_AMPLITUDE_API_KEY,
        events,
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

const getGeoLocation = () => {};

// export const dispatchEvents = (
//   events: {
//     event_type: string;
//     user_id?: string;
//     event_properties?: { [id: string]: any };
//     [id: string]: any;
//   }[]
// ) => {
//   const industry = userIndustrySelector(store.getState());
//   console.log(industry);

//   axios
//     .post('https://api2.amplitude.com/2/httpapi', {
//       api_key: process.env.REACT_APP_AMPLITUDE_API_KEY,
//       events,
//     })
//     .catch((err) => {
//       console.error(err);
//     });
// };
