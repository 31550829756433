import './EditItemModal.scss';
import firebase from 'firebase-main';
import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { AutoComplete, Checkbox, InputNumber } from 'antd';
import { ImageCrop } from 'common/components/ImageCrop/ImageCrop';
import { useDispatch, useSelector } from 'react-redux';
import {
  categoryOptionsSelector,
  libraryVersionSelector,
  subcategoryOptionsSelector,
} from 'redux/slices/items/selectors';
import { toasterAction } from 'redux/common/actions';
import Loader from 'common/components/loader/Loader';

export const EditItemModal = ({
  item = {} as any,
  onCancel = () => {},
  onUpdate = () => {},
}) => {
  const dispatch = useDispatch();
  const libVersion = useSelector(libraryVersionSelector);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(item.name ?? '');
  const [tags, setTags] = useState(item.tags ?? '');
  const [category, setCategory] = useState(item.category ?? '');
  const [subCategory, setSubCategory] = useState(
    item.subcategoryDisplayName ?? ''
  );
  const [featured, setFeatured] = useState(item.featured ?? 0);
  const [price, setPrice] = useState((item.price ?? 0) / 100);
  const [isOnline, setIsOnline] = useState(!!item.isOnline ?? true);
  const [thumbnail, setThumbnail] = useState(
    // item.thumbnail ? item.thumbnail + '&ti=' + Date.now() : ''
    item.thumbnail ? item.thumbnail : ''
  );

  const categoryOptions = useSelector(categoryOptionsSelector);
  const subCategoryOptions = useSelector(subcategoryOptionsSelector);

  const [imgBlob, setImgBlob] = useState<null | Blob>();
  const [imgUrl, setImgUrl] = useState<string>('');

  useEffect(() => {
    setName(item.name ?? '');
    setTags(item.tags ?? '');
    setCategory(item.category ?? '');
    setSubCategory(item.subcategoryDisplayName ?? '');
    setFeatured(item.featured ?? 0);
    setPrice((item.price ?? 0) / 100);
    setIsOnline(item.isOnline ?? true);
    // setThumbnail(item.thumbnail ? item.thumbnail + '&ti=' + Date.now() : '');
    setThumbnail(item.thumbnail ? item.thumbnail : '');
  }, [item]);

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const params: any = {
      library_version: libVersion,
      itemID: item.itemID,
      name,
      tags,
      category,
      subCategory,
      featured,
      price,
      isOnline,
    };

    try {
      if (imgBlob) {
        const path = `ObjectLibrary/${libVersion}/StandaloneWindows64/Thumbnails/${item.itemID}.png`;
        await firebase.storage().ref(path).put(imgBlob);

        params.thumbnail = path;
      }

      await firebase.functions().httpsCallable('updateItem')(params);

      onUpdate();
    } catch (err) {
      dispatch(toasterAction('error', 'Failed to update item', err));
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      className="edit-item-modal"
      visible
      onCancel={onCancel}
      closable={false}
      maskClosable={!loading}
      footer={null}
      title="Edit Item"
    >
      <form onSubmit={onSubmit}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          placeholder="Item's name"
          value={name}
          onChange={(v) => {
            setName(v.target.value);
          }}
        />

        <label htmlFor="name">Tags</label>
        <input
          type="text"
          id="tags"
          placeholder="Item's tags"
          value={tags}
          onChange={(v) => {
            setTags(v.target.value);
          }}
        />

        <label htmlFor="category">Category</label>
        <AutoComplete
          value={category}
          onChange={(v) => setCategory(v)}
          options={categoryOptions?.map((cat) => ({ label: cat, value: cat }))}
          filterOption={(inputValue, option) =>
            option!.value.toUpperCase().startsWith(inputValue.toUpperCase())
          }
        />

        <label htmlFor="subcategory">Sub-Category</label>
        <AutoComplete
          value={subCategory}
          onChange={(v) => setSubCategory(v)}
          options={subCategoryOptions[category]?.map((cat) => ({
            label: cat,
            value: cat,
          }))}
          filterOption={(inputValue, option) =>
            option!.value.toUpperCase().startsWith(inputValue.toUpperCase())
          }
        />

        <label htmlFor="featured">Featured</label>
        <InputNumber
          id="featured"
          value={featured}
          min={0}
          max={100000000}
          onChange={(v) => {
            setFeatured(v);
          }}
        />

        <label htmlFor="price">Price</label>
        <InputNumber
          id="price"
          value={price}
          min={0}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => +(value ?? '0').replace(/\$\s?|(,*)/g, '')}
          onChange={(v) => {
            setPrice(v as any);
          }}
        />

        <label htmlFor="thumbnail">Thumbnail</label>
        <input
          type="file"
          id="thumbnail"
          name="thumbnail"
          accept="image/*"
          onChange={async (e) => {
            if (e.target.files && e.target.files[0]) {
              const file = e.target?.files[0];
              const url = URL.createObjectURL(file);
              const blob = await fetch(url).then((r) => r.blob());
              setImgUrl(url);
              setImgBlob(blob);
            }
          }}
        />
        <div
          className="img-upload-icon"
          style={{
            background: `url("${imgUrl.length > 0 ? imgUrl : thumbnail}")`,
          }}
        ></div>
        {/* <input
          id="thumbnail"
          onImageLoad={(url, blob) => {
            setImgBlob(blob);
            setImgUrl(url);
          }}
          backgroundImgSrc={imgUrl.length > 0 ? imgUrl : thumbnail}
        /> */}

        <Checkbox
          checked={isOnline}
          onChange={(v) => {
            setIsOnline(v.target.checked);
          }}
        >
          IsOnline
        </Checkbox>

        <Loader
          show={loading}
          spinnerSize={20}
          centerSpinner
          spinnerColor="#0081b4"
        >
          <input type="submit" value="Submit" />
        </Loader>
      </form>
    </Modal>
  );
};
