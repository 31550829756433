import { itemsAction, searchFilters } from './types';
import { Action, ThunkAction } from 'redux/common/types';
import axios from 'axios';
import _ from 'lodash';
import { action } from 'redux/common/actions';
import firebase from 'firebase';
import { fireRequestTypes } from 'redux/middlewares/firebaseRealtimeApiMiddleware/firebaseRequestTypes';
import {
  addCategory,
  addDefaultSubcategory,
  addSubcategory,
  arrayToObject,
  convertItemsToObject,
  convertSubcategoriesToObject,
  extractItemId,
} from './preparers';
import { itemsProgressSelector } from './selectors';
// ----------------------------------------- Action Constants -----------------------------------------
export const itemsNameSpace = 'store';

export const itemNames = {
  ANIMATION_ITEMS: 'AnimationItems',
  CAMERA_LENSES: 'CameraLenses',
  CAMERA_ITEMS: 'CameraItems',
  CAMERA_SENSORS: 'CameraSensors',
  CHARACTER_ITEMS: 'CharacterItems',
  LIGHT_ITEMS: 'LightItems',
  LIGHT_TYPES: 'LightTypes',
  MATERIAL_ITEMS: 'MaterialItems',
  PROP_ITEMS: 'PropItems',
  TEMPLATES: 'Templates',
  ENVIRONMENTS: 'EnvironmentItems',
  ASSETS: 'Assets',
  SET_CONSTRUCTION: 'SetConstruction',
};

export const itemsActionTypes = {
  RESET: `${itemsNameSpace}/RESET`,

  SELECT_CATEGORY: `${itemsNameSpace}/SELECT_CATEGORY`,
  SELECT_SUBCATEGORY: `${itemsNameSpace}/SELECT_SUBCATEGORY`,

  SET_ITEM_PROGRESS: `${itemsNameSpace}/SET_ITEM_PROGRESS`,

  SET_ITEM_DOWNLOADED_LOCALLY: `${itemsNameSpace}/SET_ITEM_DOWNLOADED_LOCALLY`,

  ADD_ITEMS: `${itemsNameSpace}/ADD_ITEMS`,
  SET_ITEMS: `${itemsNameSpace}/SET_ITEMS`,
  FETCH_ITEMS: `${itemsNameSpace}/FETCH_ITEMS`,
  FETCH_ITEMS_SUCCESS: `${itemsNameSpace}/FETCH_ITEMS_SUCCESS`,
  FETCH_ITEMS_EMPTY: `${itemsNameSpace}/FETCH_ITEMS_EMPTY`,
  FETCH_ITEMS_FAIL: `${itemsNameSpace}/FETCH_ITEMS_FAIL`,

  LIKE_ITEM: `${itemsNameSpace}/LIKE_ITEM`,
  LIKE_ITEM_SUCCESS: `${itemsNameSpace}/LIKE_ITEM_SUCCESS`,
  LIKE_ITEM_FAIL: `${itemsNameSpace}/LIKE_ITEM_FAIL`,

  UNLIKE_ITEM: `${itemsNameSpace}/UNLIKE_ITEM`,
  UNLIKE_ITEM_SUCCESS: `${itemsNameSpace}/UNLIKE_ITEM_SUCCESS`,
  UNLIKE_ITEM_FAIL: `${itemsNameSpace}/UNLIKE_ITEM_FAIL`,

  SUBSCRIBE_LIKED_ITEMS_IDS: `${itemsNameSpace}/SUBSCRIBE_LIKED_ITEMS_IDS`,
  UNSUBSCRIBE_LIKED_ITEMS_IDS: `${itemsNameSpace}/UNSUBSCRIBE_LIKED_ITEMS_IDS`,
  SET_LIKED_ITEMS_IDS: `${itemsNameSpace}/SET_LIKED_ITEMS_IDS`,
  SUBSCRIBE_LIKED_ITEMS_IDS_SUCCESS: `${itemsNameSpace}/SUBSCRIBE_LIKED_ITEMS_IDS_SUCCESS`,
  SUBSCRIBE_LIKED_ITEMS_IDS_EMPTY: `${itemsNameSpace}/SUBSCRIBE_LIKED_ITEMS_IDS_EMPTY`,
  SUBSCRIBE_LIKED_ITEMS_IDS_FAIL: `${itemsNameSpace}/SUBSCRIBE_LIKED_ITEMS_IDS_FAIL`,

  FETCH_LIKED_ITEMS: `${itemsNameSpace}/FETCH_LIKED_ITEMS`,
  SET_LIKED_ITEMS: `${itemsNameSpace}/SET_LIKED_ITEMS`,
  FETCH_LIKED_ITEMS_SUCCESS: `${itemsNameSpace}/FETCH_LIKED_ITEMS_SUCCESS`,
  FETCH_LIKED_ITEMS_FAIL: `${itemsNameSpace}/FETCH_LIKED_ITEMS_FAIL`,
  FETCH_LIKED_ITEMS_EMPTY: `${itemsNameSpace}/FETCH_LIKED_ITEMS_EMPTY`,

  SET_SEARCH_TERM: `${itemsNameSpace}/SET_SEARCH_TERM`,
  SET_SEARCH_TAGS: `${itemsNameSpace}/SET_SEARCH_TAGS`,
  ADD_SEARCH_ITEMS: `${itemsNameSpace}/ADD_SEARCH_ITEMS`,
  SET_SEARCH_ITEMS: `${itemsNameSpace}/SET_SEARCH_ITEMS`,
  CLEAR_SEARCH_ITEMS: `${itemsNameSpace}/CLEAR_SEARCH_ITEMS`,
  SEARCH_ITEMS: `${itemsNameSpace}/SEARCH_ITEMS`,
  SEARCH_ITEMS_SUCCESS: `${itemsNameSpace}/SEARCH_ITEMS_SUCCESS`,
  SEARCH_ITEMS_EMPTY: `${itemsNameSpace}/SEARCH_ITEMS_EMPTY`,
  SEARCH_ITEMS_FAIL: `${itemsNameSpace}/SEARCH_ITEMS_FAIL`,

  SET_SUB_CATEGORIES: `${itemsNameSpace}/SET_SUB_CATEGORIES`,
  FETCH_SUB_CATEGORIES: `${itemsNameSpace}/FETCH_SUB_CATEGORIES`,
  FETCH_SUB_CATEGORIES_SUCCESS: `${itemsNameSpace}/FETCH_SUB_CATEGORIES_SUCCESS`,
  FETCH_SUB_CATEGORIES_EMPTY: `${itemsNameSpace}/FETCH_SUB_CATEGORIES_EMPTY`,
  FETCH_SUB_CATEGORIES_FAIL: `${itemsNameSpace}/FETCH_SUB_CATEGORIES_FAIL`,
  SET_PROP_ANIMATIONS: `${itemsNameSpace}/SET_PROP_ANIMATIONS`,
  SET_PROP_SEARCH_ANIMATIONS: `${itemsNameSpace}/SET_PROP_SEARCH_ANIMATIONS`,
};

export const setLibraryVersion = (version: string): Action => ({
  type: itemsAction('SET', 'LIBRARY_VERSION'),
  payload: version,
});

export const resetStore = () => {
  return {
    type: itemsActionTypes.RESET,
  };
};

export const selectCategory = (category) => {
  return {
    type: itemsActionTypes.SELECT_CATEGORY,
    payload: category,
  };
};

export const selectSubcategory = (subcategory: string) => {
  return {
    type: itemsActionTypes.SELECT_SUBCATEGORY,
    payload: subcategory,
  };
};

export const setItemProgress = (itemId, progress) => {
  return {
    type: itemsActionTypes.SET_ITEM_PROGRESS,
    payload: { itemId, progress },
  };
};

export const fetchPendingItems =
  (teamId, libVersion, ids, skip?, limit?) => async (dispatch, getState) => {
    const params: any = {
      teamId,
      library_version: libVersion,
      items: ids,
    };
    if (skip >= 0) params.offset = skip + '';
    if (limit >= 0) params.limit = limit + '';

    dispatch({ type: itemsAction('FETCH', 'PENDING_ITEMS'), payload: params });

    try {
      const items = (
        await firebase.functions().httpsCallable('getItemsLibrary')(params)
      ).data;

      dispatch({ type: itemsAction('ADD', 'PENDING_ITEMS'), payload: items });
    } catch (e) {
      console.error(e);
      dispatch({
        type: itemsAction('ADD', 'PENDING_ITEMS', 'FAIL'),
        payload: e,
      });
    }
  };

export const setItemDownloadedLocally = (items) => {
  return {
    type: itemsActionTypes.SET_ITEM_DOWNLOADED_LOCALLY,
    payload: { items },
  };
};

export const fetchDownloadedItems = (
  items: string[],
  teamId,
  skip?,
  limit?
): Action => {
  const params: any = {
    items: items.map((id) => ({ itemID: id })),
    library_version: (window as any).libraryVersion,
    teamId,
  };
  if (skip >= 0) params.offset = skip + '';
  if (limit >= 0) params.limit = limit + '';
  return {
    type: itemsAction('FETCH', 'DOWNLOADED_ITEMS'),
    meta: {
      cloudRequest: {
        name: 'getItemsLibrary',
        body: params,
        onSuccessDispatches: [addDownloadedItems],
        onFailDispatches: [fetchDownloadedItemsFail],
      },
    },
  };
};

export const addDownloadedItems = (items): Action => ({
  type: itemsAction('ADD', 'DOWNLOADED_ITEMS'),
  payload: items,
});

export const fetchDownloadedItemsFail = (err): Action => ({
  type: itemsAction('FETCH', 'DOWNLOADED_ITEMS', 'FAIL'),
  payload: err,
});

export const setSubcategoryDownloadedLocally = (
  subcategory: string,
  progress: string,
  progressText: string
): Action => {
  return {
    type: itemsAction('SET', 'SUBCATEGORY_PROGRESS'),
    payload: { subcategory, progress, progressText },
  };
};

// ---------------------------------------------------------------- Purchased Items
export const fetchPurchasedItems = (teamId, libVersion, itemType): Action => ({
  type: itemsAction('FETCH', 'PURCHASED_ITEMS'),
  payload: teamId,
  meta: {
    cloudRequest: {
      name: 'getAssets',
      body: { teamId, library_version: libVersion, itemType },
      onSuccessDispatches: [addPurchasedItems],
      onFailDispatches: [fetchPurchasedItemsFail],
    },
  },
});

export const addPurchasedItems = (items): Action => {
  if (typeof items?.items === 'object')
    items.items = items?.items?.map((item) => ({
      ...item,
      isPurchased: true,
    }));
  return {
    type: itemsAction('ADD', 'PURCHASED_ITEMS'),
    payload: items,
  };
};

export const fetchPurchasedItemsFail = (err): Action => ({
  type: itemsAction('FETCH', 'PURCHASED_ITEMS', 'FAIL'),
  payload: err,
});
// ---------------------------------------------------------------- Instantiated Items
export const addInstantiatedItems =
  (ids, teamId) => async (dispatch, getState) => {
    if (!ids || ids.length === 0) return;
    let items: any = [];
    try {
      items = (
        await firebase.functions().httpsCallable('getItemsLibrary')({
          items: ids.map((itemID) => ({ itemID })),
          library_version: (window as any).libraryVersion,
          teamId,
        })
      ).data;
    } catch (e) {
      console.error(e);
      dispatch({
        type: itemsAction('ADD', 'INSTANTIATED_ITEMS', 'FAIL'),
        payload: e,
      });
      return;
    }
    dispatch({
      type: itemsAction('ADD', 'INSTANTIATED_ITEMS'),
      payload: items,
    });
  };
// ---------------------------------------------------------------- Items

export const setItems = (items) => {
  return {
    type: itemsActionTypes.SET_ITEMS,
    payload: items,
  };
};

export const addItems = (subcategory) => (data) => {
  let payload = convertItemsToObject()(data);
  payload = extractItemId()(payload);
  payload = addSubcategory(subcategory)(payload);
  //     convertItemsToObject(),
  //     extractItemId(),
  //     addSubcategory(subcategory),
  return {
    type: itemsActionTypes.ADD_ITEMS,
    payload: { data: payload.data, subcategory },
    // payload: { data, subcategory },
  };
};

export const fetchAssets = (
  teamId,
  libVersion,
  packId,
  skip,
  limit
): Action => {
  const params: any = {
    itemType: 'Assets',
    teamId,
    library_version: libVersion,
  };
  if (packId !== 'All' && packId !== 'Featured') params.category = packId;
  if (skip >= 0) params.offset = skip + '';
  if (limit) params.limit = limit + '';

  return {
    type: itemsActionTypes.FETCH_ITEMS,
    meta: {
      cloudRequest: {
        name: 'getAllItemsLibrary',
        body: params,
        onSuccessDispatches: [fetchItemsSuccess, addItems(packId)],
        onFailDispatches: [fetchItemsFail],
      },
    },
  };
};

export const fetchCustomCharacters = (sceneId, skip, limit): Action => {
  const params: any = { sceneId, skip, limit };
  if (skip >= 0) params.offset = skip + '';
  if (limit) params.limit = limit + '';

  return {
    type: itemsActionTypes.FETCH_ITEMS,
    meta: {
      cloudRequest: {
        name: 'getCharacters',
        body: { ...params },
        onSuccessDispatches: [fetchItemsSuccess, addItems('Custom Characters')],
        onFailDispatches: [fetchItemsFail],
      },
    },
  };
};

export const fetchItems = (
  teamId,
  itemType,
  subcategory: string,
  skip,
  limit
): Action => {
  const params: any = { itemType, teamId };
  if (subcategory && subcategory !== 'All' && subcategory !== 'Featured')
    params.category = subcategory;
  if (itemType === 'CharacterItems' && subcategory === 'Character Templates')
    delete params.category;
  if (skip >= 0) params.offset = skip + '';
  if (limit) params.limit = limit + '';
  if (subcategory === 'Featured') params.featured = true;
  if (subcategory.includes('_')) {
    const [category, subCat] = subcategory.split('_');
    params.category = category;
    params.subcategory = subCat;
    if (category === 'Environment Props') {
      params.environmentProps = true;
    }
  }
  if (subcategory === 'Environment Props') {
    params.environmentProps = true;
  }
  if (itemType === 'SetConstruction') {
    params.itemType = 'PropItems';
    params.category = 'Environment Props';
    params.subcategory = subcategory;
    params.environmentProps = true;
  }

  params.library_version = (window as any).libraryVersion;

  return {
    type: itemsActionTypes.FETCH_ITEMS,
    meta: {
      cloudRequest: {
        name: 'getAllItemsLibrary',
        body: { ...params },
        onSuccessDispatches: [fetchItemsSuccess, addItems(subcategory)],
        onFailDispatches: [fetchItemsFail],
      },
      // libraryDb: {
      //   requestType: libraryDbRequestTypes.POST,
      //   url: '/getAllItemsLibrary',
      //   params,
      //   dataPreparers: [
      //     convertItemsToObject(),
      //     extractItemId(),
      //     addSubcategory(subcategory),
      //   ],
      //   onSuccessDispatches: [fetchItemsSuccess, addItems],
      //   onEmptyDispatches: [fetchItemsEmpty],
      //   onFailDispatches: [fetchItemsFail],
      // },
    },
  };
};

export const fetchItemsSuccess = (items) => {
  return {
    type: itemsActionTypes.FETCH_ITEMS_SUCCESS,
    payload: items,
  };
};

export const fetchItemsEmpty = () => {
  return { type: itemsActionTypes.FETCH_ITEMS_EMPTY };
};

export const fetchItemsFail = (error): Action => {
  return {
    type: itemsActionTypes.FETCH_ITEMS_FAIL,
    payload: error,
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to fetch Items',
        description: error,
      },
      // ...toaster(toasterType.error, 'Failed to fetch Items', error),
    },
  };
};

export const setSearchItems = (items) => {
  return {
    type: itemsActionTypes.SET_SEARCH_ITEMS,
    payload: items,
  };
};

export const addSearchItems = ({ hits, totalHitsSize }) => {
  return {
    type: itemsActionTypes.ADD_SEARCH_ITEMS,
    payload: {
      items: hits,
      totalHitsSize,
    },
  };
};

export const firebaseAddSearchItems = ({
  items,
  autoComplete = [],
  totalSize,
  searchTerm,
  tags,
  filters,
}): Action => {
  return {
    type: itemsActionTypes.ADD_SEARCH_ITEMS,
    payload: {
      items,
      autoComplete,
      totalSize,
      searchTerm,
      tags,
      filters,
    },
  };
};

export const fetchAutoCompleteOptions = (
  query = '',
  library_version = '',
  type: 'ObjectLibrary' | 'Animations'
): Action => {
  return {
    type: itemsAction('FETCH', 'AUTO_COMPLETE'),
    meta: {
      cloudRequest: {
        name: 'searchAutoComplete',
        body: { searchTerm: query, library_version, type },
        onSuccessDispatches: [setAutoCompleteOptions],
        onFailDispatches: [],
      },
    },
  };
};

export const setAutoCompleteOptions = (options): Action => ({
  type: itemsAction('SET', 'AUTO_COMPLETE'),
  payload: options,
});

export const fetchAutoCompleteOptionsFail = (err): Action => ({
  type: itemsAction('FETCH', 'AUTO_COMPLETE', 'FAIL'),
  payload: err,
});

export const searchItems = (query = '', options = {}) => {
  return {
    type: itemsActionTypes.SEARCH_ITEMS,
    payload: { query, options },
    meta: {
      algoliaItemSearch: {
        query,
        options,
        onSuccessDispatches: [searchItemsSuccess, addSearchItems],
        onEmptyDispatches: [searchItemsEmpty],
        onFailDispatches: [searchItemsFail],
      },
    },
  };
};

export const setSearchTerm = (query) =>
  action(itemsActionTypes.SET_SEARCH_TERM, query);

export const setSearchTags = (tags: string): Action => {
  return { type: itemsActionTypes.SET_SEARCH_TAGS, payload: tags };
};

export const setSearchFilters = (filters: searchFilters): Action => ({
  type: itemsAction('SET', 'SEARCH_FILTERS'),
  payload: filters,
});

export const firebaseSearchItems2 =
  (
    teamId = '',
    query = '',
    skip = 0,
    limit = 10,
    tags = '',
    filters?: searchFilters
  ) =>
  async (dispatch, getState) => {
    const body = {
      teamId,
      searchTerm: query,
      limit,
      offset: skip,
      itemTypes: tags,
      library_version: (window as any).libraryVersion,
      filters,
    };
    // dispatch({
    //   type: itemsActionTypes.SEARCH_ITEMS,
    //   payload: body,
    // });

    // try {
    //   const items: any = await axios.post(
    //     process.env.REACT_APP_NODE_SERVER_URL + 'items-library/search',
    //     { data: body },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${await firebase
    //           .auth()
    //           .currentUser?.getIdToken()}`,
    //       },
    //     }
    //   );
    //   dispatch(firebaseAddSearchItems(items.data));
    // } catch (e) {
    //   console.error(e);
    //   dispatch(searchItemsFail(e));
    // }

    dispatch({
      type: itemsActionTypes.SEARCH_ITEMS,
      meta: {
        cloudRequest: {
          name: 'searchItemLibrary',
          body,
          onSuccessDispatches: [firebaseAddSearchItems],
          onFailDispatches: [searchItemsFail],
        },
      },
    });
  };

// TODO Remove
export const firebaseSearchItems =
  (query = '', skip = 0, limit = 10) =>
  async (dispatch, getState) => {
    if (query.length === 0) {
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${
          getState().firebase.auth.stsTokenManager.accessToken
        }`,
      },
    };

    const body = {
      searchTerm: query,
      offset: skip,
      limit,
      library_version: (window as any).libraryVersion,
    };

    const api = process.env.REACT_APP_REALTIME_URL;

    dispatch(setSearchTerm(query));

    const res = await axios
      .post(`${api}/searchItemLibrary`, body, config)
      .then((res) => {
        const { items, totalSize, searchTerm } = res.data;
        // dispatch(firebaseAddSearchItems(items, totalSize, searchTerm));
      })
      .catch((err) => {
        console.log(err);
        dispatch(searchItemsFail(err));
      });
  };

export const clearSearchItems = () => {
  return { type: itemsActionTypes.CLEAR_SEARCH_ITEMS };
};

export const searchItemsSuccess = () => {
  return {
    type: itemsActionTypes.SEARCH_ITEMS_SUCCESS,
  };
};

export const searchItemsEmpty = () => {
  return {
    type: itemsActionTypes.SEARCH_ITEMS_EMPTY,
  };
};

export const searchItemsFail = (error): Action => {
  return {
    type: itemsActionTypes.SEARCH_ITEMS_FAIL,
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to search Items',
        description: error,
      },
    },
  };
};

// ---------------------------------------------------------------- Favorite Items

// ---- Subscribe ItemIds

// TODO remove the default project id
export const subscribeFavoriteItemIds = (projectId = 'temp') => {
  return {
    type: itemsActionTypes.SUBSCRIBE_LIKED_ITEMS_IDS,
    meta: {
      firebaseRealtimeApi: {
        url: `favorites/${projectId}`,
        req: fireRequestTypes.SUBSCRIBE,
        onSuccessDispatches: [
          subscribeFavoriteItemIdsSuccess,
          setFavoriteItemIds,
        ],
        onEmptyResponseDispatches: [
          subscribeFavoriteItemIdsSuccess,
          subscribeFavoriteItemIdsEmpty,
          setFavoriteItemIds,
        ],
        onFailDispatches: [subscribeFavoriteItemIdsFail],
        id: 'itemIds',
      },
    },
  };
};

export const unsubscribeFavoriteItemIds = (projectId = 'temp') => {
  return {
    type: itemsActionTypes.UNSUBSCRIBE_LIKED_ITEMS_IDS,
    meta: {
      firebaseRealtimeApi: {
        url: `projects/${projectId}/favorites`,
        req: fireRequestTypes.UNSUBSCRIBE,
        id: 'itemIds',
      },
    },
  };
};

export const setFavoriteItemIds = (itemIds) => {
  return {
    type: itemsActionTypes.SET_LIKED_ITEMS_IDS,
    payload: itemIds ?? {},
  };
};

export const subscribeFavoriteItemIdsSuccess = () => {
  return {
    type: itemsActionTypes.SUBSCRIBE_LIKED_ITEMS_IDS_SUCCESS,
  };
};

export const subscribeFavoriteItemIdsEmpty = () => {
  return {
    type: itemsActionTypes.SUBSCRIBE_LIKED_ITEMS_IDS_EMPTY,
  };
};

export const subscribeFavoriteItemIdsFail = () => {
  return {
    type: itemsActionTypes.SUBSCRIBE_LIKED_ITEMS_IDS_FAIL,
  };
};

// ---- Favorite Items
// export const fetchFavoriteItems = (itemIds) => {
//   return {
//     type: itemsActionTypes.FETCH_LIKED_ITEMS,
//     meta: {
//       algoliaItemSearch: {
//         itemIds: itemIds ?? {},
//         onSuccessDispatches: [setFavoriteItems],
//         onFailDispatches: [fetchFavoriteItemsFail],
//       },
//     },
//   };
// };

export const fetchFavoriteItems = (itemIds: string[], teamId): Action => {
  const itemIdsObj = itemIds.map((itemID) => {
    return { itemID };
  });
  return {
    type: itemsActionTypes.FETCH_LIKED_ITEMS,
    meta: {
      cloudRequest: {
        name: 'getItemsLibrary',
        body: {
          items: itemIdsObj,
          library_version: (window as any).libraryVersion,
          teamId,
        },
        onSuccessDispatches: [setFavoriteItems],
        onFailDispatches: [fetchFavoriteItemsFail],
      },
      // algoliaItemSearch: {
      //   itemIds: itemIds ?? {},
      //   onSuccessDispatches: [setFavoriteItems],
      //   onFailDispatches: [fetchFavoriteItemsFail],
      // },
    },
  };
};

export const setFavoriteItems = (items): Action => {
  return {
    type: itemsActionTypes.SET_LIKED_ITEMS,
    payload: items ?? {},
  };
};

export const fetchFavoriteItemsFail = (err): Action => {
  console.error(err);

  return {
    type: itemsActionTypes.FETCH_LIKED_ITEMS_FAIL,
  };
};

// ---- Like Item

export const likeAnimation =
  (itemID, teamId): ThunkAction =>
  (dispatch, getState) => {
    dispatch({ type: itemsActionTypes.LIKE_ITEM, payload: itemID });
    firebase
      .functions()
      .httpsCallable('favoriteAnimation')({
        teamId,
        itemID,
        operation: 'ADD',
      })
      .then(() => {
        dispatch(likeItemSuccess(itemID));
      })
      .catch((err) => {
        console.error(err);
        dispatch(likeItemsFail(err, itemID));
      });
  };

export const unlikeAnimation =
  (itemID, teamId): ThunkAction =>
  (dispatch, getState) => {
    dispatch({ type: itemsActionTypes.UNLIKE_ITEM, payload: itemID });
    firebase
      .functions()
      .httpsCallable('favoriteAnimation')({
        teamId,
        itemID,
        operation: 'REMOVE',
      })
      .then(() => {
        dispatch(unlikeItemSuccess(itemID));
      })
      .catch((err) => {
        console.error(err);
        dispatch(unlikeItemsFail(err, itemID));
      });
  };

// TODO remove the default project id
export const likeItem = (itemId, projectId = 'temp') => {
  return {
    type: itemsActionTypes.LIKE_ITEM,
    payload: itemId,
    meta: {
      firebaseRealtimeApi: {
        url: `favorites/${projectId}/${itemId}`,
        req: fireRequestTypes.SET,
        onSuccessDispatches: [likeItemSuccess],
        onFailDispatches: [likeItemsFail],
      },
    },
  };
};

export const likeItemSuccess = (payload) => {
  return {
    type: itemsActionTypes.LIKE_ITEM_SUCCESS,
    payload,
  };
};

export const likeItemsFail = (err, payload) => {
  return {
    type: itemsActionTypes.LIKE_ITEM_FAIL,
    payload,
  };
};

// ---- Unlike Item
export const unlikeItem = (itemId, projectId = 'temp'): Action => {
  return {
    type: itemsActionTypes.UNLIKE_ITEM,
    payload: itemId,
    meta: {
      firebaseRealtimeApi: {
        url: `favorites/${projectId}/${itemId}`,
        req: 'REMOVE',
        onSuccessDispatches: [unlikeItemSuccess],
        onEmptyResponseDispatches: [unlikeItemSuccess],
        onFailDispatches: [unlikeItemsFail],
      },
    },
  };
};

export const unlikeItemSuccess = (payload) => {
  return {
    type: itemsActionTypes.UNLIKE_ITEM_SUCCESS,
    payload,
  };
};

export const unlikeItemsFail = (err, payload) => {
  return {
    type: itemsActionTypes.UNLIKE_ITEM_FAIL,
    payload,
  };
};
// -------------------------------- Subcategories

export const setSubcategories = (itemType) => (subcategories) => {
  let payload: any = addDefaultSubcategory()(subcategories);
  payload = convertSubcategoriesToObject()(payload);
  payload = addCategory(itemType)(payload);
  return {
    type: itemsActionTypes.SET_SUB_CATEGORIES,
    payload,
  };
};

export const fetchSubcategories = (itemType, teamId?): Action => {
  const params: any = { itemType };
  params.library_version = (window as any).libraryVersion;
  if (itemType === 'SetConstruction') params.itemType = 'PropItems';
  return {
    type: itemsActionTypes.FETCH_SUB_CATEGORIES,
    meta: {
      cloudRequest: {
        name: 'getSubcategoryLibrary',
        body: { ...params, teamId },
        onSuccessDispatches: [
          fetchSubcategoriesSuccess,
          setSubcategories(itemType),
        ],
        onFailDispatches: [fetchSubcategoriesFail],
      },
      // libraryDb: {
      //   requestType: libraryDbRequestTypes.POST,
      //   url: '/getSubcategoryLibrary',
      //   params,
      //   dataPreparers: [
      //     addDefaultSubcategory(),
      //     convertSubcategoriesToObject(),
      //     addCategory(itemType),
      //   ],
      //   onSuccessDispatches: [fetchSubcategoriesSuccess, setSubcategories],
      //   onEmptyDispatches: [fetchSubcategoriesEmpty],
      //   onFailDispatches: [fetchSubcategoriesFail],
      // },
    },
  };
};
export const fetchSubcategoriesSuccess = (subcategories) => {
  return {
    type: itemsActionTypes.FETCH_SUB_CATEGORIES_SUCCESS,
    payload: subcategories,
  };
};

export const fetchSubcategoriesEmpty = () => {
  return { type: itemsActionTypes.FETCH_SUB_CATEGORIES_EMPTY };
};

export const fetchSubcategoriesFail = (error): Action => {
  return {
    type: itemsActionTypes.FETCH_SUB_CATEGORIES_FAIL,
    payload: error,
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to fetch Subcategories',
        description: error,
      },
    },
  };
};

export const fetchSearchFilters = (libVersion): Action => ({
  type: itemsAction('FETCH', 'SEARCH_OPTIONS'),
  meta: {
    cloudRequest: {
      name: 'getSearchFilters',
      body: { library_version: libVersion },
      onSuccessDispatches: [setSearchOptions],
    },
  },
});

export const setSearchOptions = (filters): Action => ({
  type: itemsAction('SET', 'SEARCH_OPTIONS'),
  payload: filters,
});

// -------------------------------- Animations

export const setPropAnimations = (items) => {
  return {
    type: itemsActionTypes.SET_PROP_ANIMATIONS,
    payload: items,
  };
};

export const setPropSearchAnimations = (items) => {
  return {
    type: itemsActionTypes.SET_PROP_SEARCH_ANIMATIONS,
    payload: items,
  };
};

// -------------------------------- Animations

export const setEditorMode = (flag) => ({
  type: itemsAction('SET', 'EDITOR_MODE'),
  payload: flag,
});
