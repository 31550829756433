import Modal from 'antd/lib/modal/Modal';
import firebase from 'firebase-main';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toasterAction } from 'redux/common/actions';

export const CancelSubModal = ({ teamId, onClose = () => {} }) => {
  const dispatch = useDispatch();
  const [cancelSubLoading, setCancelSubLoading] = useState(false);

  const cancelSubscription = async () => {
    setCancelSubLoading(true);
    try {
      await firebase.functions().httpsCallable('cancelSubscription')({
        teamId,
      });
      setCancelSubLoading(false);
      onClose();
    } catch (err) {
      console.error(err);
      dispatch(toasterAction('error', 'Failed to cancel subscription', err));
      setCancelSubLoading(false);
    }
  };

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      visible
      title="Cancel Subscription"
      onCancel={onClose}
      onOk={cancelSubscription}
      confirmLoading={cancelSubLoading}
      maskClosable={false}
    >
      Are you sure you want to cancel subscription for this drive?
    </Modal>
  );
};
