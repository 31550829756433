import { toasterTypes } from './../middlewares/toasterMiddleware/types';
import { Action } from 'redux/common/types';

export const action = (actionType: string, payload?: any): Action => {
  return { type: actionType, payload };
};

export const toasterAction = (
  toasterType: toasterTypes,
  message: string,
  description?: string
): Action => {
  return {
    type: 'TOASTER',
    meta: {
      toaster: { type: toasterType, message, description },
    },
  };
};

export const sendAmplitudeEvents = (
  events: {
    event_type: string;
    user_id?: string;
    event_properties?: { [id: string]: any };
    [id: string]: any;
  }[]
): Action => ({
  type: 'DISPATCH_AMPLITUDE_EVENT',
  meta: { amplitude: { events } },
});
