import firebase from 'firebase-main';
import { action } from './../../common/actions';
import { fireRealtimeDBType } from './../../middlewares/firebaseRealtimeApiMiddleware/types';
import { galleryAction } from './types';
import { Action } from 'redux/common/types';

export const uploadImage =
  (userId: string, teamId: string, timestamp: string, url, blob: Blob) =>
  async (dispatch) => {
    console.log(url);

    dispatch({ type: galleryAction('UPLOAD', 'IMAGE'), payload: url });

    firebase
      .storage()
      .ref(`custom_images/${teamId}/${timestamp}.jpg`)
      .put(blob)
      .then(async (res) => {
        const url = await res.ref.getDownloadURL();
        console.log(url);
        dispatch(setImage(userId, teamId, timestamp, url));
      })
      .catch((err) => {
        console.error(err);
        if (err.code_ === 'storage/unauthorized')
          dispatch(setImageFail('You are not authorized to do this action'));
        else dispatch(setImageFail(err));
      });
  };

export const setImage = (
  userId: string,
  teamId: string,
  timestamp: string,
  imageUrl: string
) => {
  const request: fireRealtimeDBType = {
    firebaseRealtimeApi: {
      req: 'UPDATE',
      url: `customImages/${teamId}/${timestamp}`,
      onSuccessDispatches: [setImageSuccess],
      onEmptyResponseDispatches: [setImageSuccess],
      onFailDispatches: [setImageFail],
    },
  };
  return {
    type: galleryAction('ADD', 'IMAGE'),
    payload: { url: imageUrl, userId, timestamp },
    meta: request,
  };
};

export const setImageSuccess = () =>
  action(galleryAction('SET', 'IMAGE', 'SUCCESS'));

export const setImageFail = (err): Action => ({
  type: galleryAction('SET', 'IMAGE', 'FAIL'),
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to upload image',
      description: err,
    },
  },
});

export const deleteImage = (teamId, timestamp): Action => ({
  type: galleryAction('REMOVE', 'IMAGE'),
  payload: { teamId, timestamp },
  meta: {
    firebaseRealtimeApi: {
      req: 'REMOVE',
      url: `customImages/${teamId}/${timestamp}`,
    },
  },
});

export const deleteImageFail = (err): Action => ({
  type: galleryAction('REMOVE', 'IMAGE', 'FAIL'),
  payload: err,
  meta: {
    toaster: {
      type: 'error',
      message: 'Failed to delete image',
      description: err,
    },
  },
});

export const subscribeImages = (teamId: string): Action => {
  const subscribeRequest: fireRealtimeDBType = {
    firebaseRealtimeApi: {
      id: 'galleryImages',
      req: 'SUBSCRIBE',
      url: `customImages/${teamId}`,
      onSuccessDispatches: [subscribeImagesSuccess, setGalleryImages],
      onEmptyResponseDispatches: [subscribeImagesSuccess, setGalleryImages],
    },
  };

  return { type: galleryAction('SUBSCRIBE', 'IMAGE'), meta: subscribeRequest };
};

export const setGalleryImages = (images) =>
  action(galleryAction('SET', 'IMAGE'), images);

export const subscribeImagesSuccess = () =>
  action(galleryAction('SUBSCRIBE', 'IMAGE', 'SUCCESS'));

export const unSubscribeImages = () => {
  const unsubscribeReq: fireRealtimeDBType = {
    firebaseRealtimeApi: {
      id: 'galleryImages',
      req: 'UNSUBSCRIBE',
    },
  };

  return {
    type: galleryAction('UNSUBSCRIBE', 'IMAGE'),
    meta: unsubscribeReq,
  };
};
