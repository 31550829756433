import './SaveCharacterModal.scss';
import Modal from 'antd/lib/modal/Modal';
import React, { useState } from 'react';

export const SaveCharacterModal = ({ close }) => {
  const [name, setName] = useState('');

  return (
    <Modal
      transitionName=""
      maskTransitionName=""
      visible
      footer={null}
      title="Save Character"
      onCancel={close}
    >
      <form className="save-character-modal">
        <div>
          <label>Character Name</label>
          <input
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="button-container">
          <button
            className="btn btn-primary"
            onClick={() => {
              if (name) {
                (window as any).onSaveCharacterAsCopy(name);
                close();
              }
            }}
          >
            Save as a copy
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              if (name) {
                (window as any).onSaveCharacter(name);
                close();
              }
            }}
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};
