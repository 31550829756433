import './BacklotBugReport.scss';
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Upload } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import { reportBug } from 'redux/slices/app/actions';
import { bugReportPendingSelector } from 'redux/slices/loaders/loadersSelectors';
import { toasterAction } from 'redux/common/actions';

const BacklotBugReport = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const userId = useSelector(userIdSelector);
  const [screenshotLink, setScreenshotLink] = useState(null);
  const [logsLink, setLogsLink] = useState(null);
  const [appVer, setAppVer] = useState(null);
  const [libVer, setLibVer] = useState(null);
  const [isUnityEditor, setIsUnityEditor] = useState(null);

  const bugReportPending = useSelector(bugReportPendingSelector);

  const onScreenShotChange = ({ params }) => {
    setScreenshotLink(params.link);
  };

  const onLogsChange = ({ params }) => {
    setLogsLink(params.link);
  };

  const onAppVerChange = ({ params }) => {
    setAppVer(params.version);
  };

  const onLibVerChange = ({ params }) => {
    setLibVer(params.version);
  };

  const onIsUnityChange = ({ params }) => {
    setIsUnityEditor(params.value);
  };

  useEffect(() => {
    window.addEventListener('setScreenshotLink', onScreenShotChange as any);
    window.addEventListener('setLogsLink', onLogsChange as any);
    window.addEventListener('setAppVersion', onAppVerChange as any);
    window.addEventListener('setLibraryVersion', onLibVerChange as any);
    window.addEventListener('setIsUnityEditor', onIsUnityChange as any);

    return () => {
      window.removeEventListener(
        'setScreenshotLink',
        onScreenShotChange as any
      );

      window.removeEventListener('setLogsLink', onLogsChange as any);
      window.removeEventListener('setAppVersion', onAppVerChange as any);
      window.removeEventListener('setLibraryVersion', onLibVerChange as any);
      window.removeEventListener('setIsUnityEditor', onIsUnityChange as any);
    };
  }, []);

  const onFinish = (values: any) => {
    if (!values.title.trim() || !values.description.trim()) {
      dispatch(toasterAction('info', 'Title & description must be provided'));
      return;
    }
    console.log(
      values,
      screenshotLink,
      logsLink,
      appVer,
      libVer,
      isUnityEditor
    );
    dispatch(
      reportBug(
        values.title,
        values.description,
        appVer,
        libVer,
        logsLink,
        screenshotLink,
        isUnityEditor
      )
    );
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="bug-report">
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={bugReportPending}>
            Submit
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BacklotBugReport;
