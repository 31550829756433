import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import axios from 'axios';
import firebase from './firebase-main';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { isLoaded } from 'react-redux-firebase';
import { useWindowListeners } from 'common/hooks/useWindowListeners';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  userIdSelector,
  userNameSelector,
  userImageSelector,
} from 'redux/slices/auth/authSelectors';
import { logout } from 'redux/slices/auth/actions/logout';
import {
  sceneSelector,
  selectedProjectIdSelector,
  selectedTeamIdSelector,
  uuidSelector,
} from 'redux/slices/app/selectors';
import { toasterAction } from 'redux/common/actions';

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? '');

const rrfConfig = {
  userProfile: 'users',
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  //createFirestoreInstance, // <- needed if using firestore
};
// const rrfProps = {
//   firebase,
//   config: rrfConfig,
//   dispatch: store.dispatch,
//   //createFirestoreInstance, // <- needed if using firestore
// };

const WindowListeners = ({ children }) => {
  useWindowListeners();
  return children;
};

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state: any) => state.firebase.auth);
  if (!isLoaded(auth)) return <div />;
  return children;
};

const SessionManager = ({ children }) => {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const uuid = useSelector(uuidSelector);
  useEffect(() => {
    if (userId && uuid) {
      try {
        firebase
          .database()
          .ref(`users/${userId}/modules_last_uuid`)
          .set(uuid)
          .then(() => {
            firebase
              .database()
              .ref(`users/${userId}/modules_last_uuid`)
              .on('value', (res) => {
                const last_uuid = res.val();
                if (last_uuid !== uuid) {
                  dispatch(
                    toasterAction(
                      'warning',
                      'Your account has been accessed from another device'
                    )
                  );
                  dispatch(logout());
                  (window as any).onNewLogin();
                }
              });
          })
          .catch(() => {});
      } catch (err) {
        console.error(err);
      }
    }

    return () => {
      firebase.database().ref(`users/${userId}/modules_last_uuid`).off();
    };
  }, [userId, uuid]);

  return children;
};

const FirebasePresence = ({ children }) => {
  const userId = useSelector(userIdSelector);
  const username = useSelector(userNameSelector);
  const thumbnail = useSelector(userImageSelector);
  const selectedProjectId = useSelector(selectedProjectIdSelector);
  const sharedProjectId = useSelector(sceneSelector)?.project_id;

  let projectId = selectedProjectId;
  if (!projectId || projectId === 'shared') {
    projectId = sharedProjectId;
  }

  useEffect(() => {
    if (projectId && userId) {
      firebase
        .database()
        .ref(`online/${projectId}/${userId}/is_online`)
        .on('value', () => {
          const ref = firebase.database().ref(`online/${projectId}/${userId}`);

          const user: any = { is_online: true, name: username };
          if (thumbnail) user.thumbnail = thumbnail;

          ref.update(user).catch((err) => {
            console.error(err);
          });

          ref
            .onDisconnect()
            .update({ is_online: false })
            .catch((err) => {
              console.error(err);
            });
        });

      return () => {
        if (projectId && userId) {
          firebase
            .database()
            .ref(`online/${projectId}/${userId}/is_online`)
            .off();

          firebase
            .database()
            .ref(`online/${projectId}/${userId}`)
            .update({ is_online: false })
            .catch((err) => {
              console.error(err);
            });
        }
      };
    }
  }, [projectId, username, thumbnail, userId]);
  return children;
};

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <BrowserRouter>
        <WindowListeners>
          {/* <FirebaseProvider store={store}> */}
          <AuthIsLoaded>
            <SessionManager>
              <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                <FirebasePresence>
                  <App />
                </FirebasePresence>
              </Elements>
            </SessionManager>
          </AuthIsLoaded>
          {/* </FirebaseProvider> */}
        </WindowListeners>
      </BrowserRouter>
    </ReactReduxFirebaseProvider>
  </Provider>,
  // </React.StrictMode>
  document.getElementById('root')
);
