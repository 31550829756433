import './AutoCompleteWithImage.scss';
import React, { forwardRef, useState } from 'react';
import { AutoComplete } from 'antd';
import 'antd/es/input/style/index.css';

type propTypes = {
  className?: string;
  style?: any;
  src?: string;
  alt?: string;
  options?: { value: string; label: string }[];
  type?:
    | 'text'
    | 'password'
    | 'date'
    | 'datetime-local'
    | 'file'
    | 'number'
    | 'search'
    | 'tel'
    | 'time';
  placeholder?: string;
  value?: string;
  autofocus?: boolean;
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: string) => void;
  onEnterClick?: () => void;
  onFocusIn?: (e?: React.FocusEvent<HTMLElement>) => void;
  onFocusOut?: (e: React.FocusEvent<HTMLElement>) => void;
};

/**
 * An input field with an image at the left side
 */
const AutoCompleteWithImage = (
  {
    className = '',
    style = {},
    src = '',
    alt = '',
    options = [],
    type = 'text',
    placeholder = '',
    value,
    autofocus = true,
    disabled = false,
    required = false,
    onChange = () => {},
    onEnterClick = () => {},
    onFocusIn = () => {},
    onFocusOut = () => {},
  }: propTypes,
  ref
) => {
  const [focus, setFocus] = useState(false);

  const onKeyPress = (e) => {
    if (e.charCode === 13) {
      onEnterClick();
    }
  };

  return (
    <div
      className={
        `input-with-image-library pl-4 pr-2 ${focus ? 'focus' : ''} ` +
        className
      }
      style={{ ...style, width: '100%' }}
    >
      <span>
        <img src={src} alt={alt} />
      </span>
      <div style={{ width: '100%' }}>
        <AutoComplete
          ref={ref}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          autoFocus={autofocus}
          // allowClear
          options={options}
          // options={[
          //   { label: 'aaa', value: 'aaa' },
          //   { label: 'bbb', value: 'bbb' },
          // ]}
          onChange={(e) => {
            onChange(e);
          }}
          onSelect={(e) => {
            setFocus(true);
            onChange(e);
            onFocusIn();
          }}
          onFocus={(e) => {
            onFocusIn(e);
          }}
          onBlur={(e) => {
            setFocus(false);
            onFocusOut(e);
          }}
          filterOption={(inputValue, option) =>
            option!.value.toUpperCase().startsWith(inputValue.toUpperCase())
          }
          // onKeyPress={onKeyPress}
          // required={required}
        />
      </div>
    </div>
  );
};

export default forwardRef(AutoCompleteWithImage);
