import './LibraryHeader.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faTh, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IoMdPersonAdd } from 'react-icons/io';
import { itemsProgressSelector } from 'redux/slices/items/selectors';

type viewTypes = 'medium' | 'large' | 'list';

type propTypes = {
  title: string;
  view: viewTypes;
  setView: (view: viewTypes) => void;
  onSearch?: () => void;
};

const LibraryHeader = ({
  title,
  view,
  setView,
  onSearch = () => {},
}: propTypes) => {
  const history = useHistory();

  const itemsProgress = useSelector(itemsProgressSelector);
  const pendingItemsCount = Object.values(itemsProgress).filter(
    (progress) => progress < 100
  ).length;
  return (
    <div id="lib-header">
      <div id="lib-back">
        <p
          onClick={() => {
            history.push(`/library/home`);
          }}
        >
          {'< Library'}
        </p>
      </div>
      <div id="lib-title">
        <p>{title}</p>
      </div>

      <div
        className={`cloud-icon ${pendingItemsCount > 0 ? 'active' : ''}`}
        onClick={() => {
          history.push('/library/team-library?category=downloads');
        }}
      >
        <img
          src={`/backlot-library/library/cloud-download.svg`}
          alt="downloads"
        />
        <div className="badge">{pendingItemsCount}</div>
      </div>

      <div id="lib-search">
        <img
          src="/backlot-library/home/search.svg"
          alt="search"
          onClick={onSearch}
        />
      </div>
      <div id="view-options">
        {title === 'Characters' && (
          <div
            className={`view-icon`}
            id="character-view"
            onClick={() => {
              (window as any).onCharacterCreationOpen();
            }}
          >
            <IoMdPersonAdd size={24} />
            <div className="view-name" style={{ fontSize: '2vw' }}>
              Create
            </div>
            <div className="view-name" style={{ fontSize: '2vw' }}>
              Character
            </div>
          </div>
        )}
        <div
          className={`view-icon ${view === 'medium' ? 'selected' : ''}`}
          id="normal-view"
          onClick={() => {
            setView('medium');
          }}
        >
          <FontAwesomeIcon icon={faTh} size="1x" />
          <div className="view-name">Medium</div>
        </div>
        <div
          className={`view-icon ${view === 'large' ? 'selected' : ''}`}
          id="large-view"
          onClick={() => {
            setView('large');
          }}
        >
          <FontAwesomeIcon icon={faThLarge} size="1x" />
          <div className="view-name">Large</div>
        </div>
        <div
          className={`view-icon ${view === 'list' ? 'selected' : ''}`}
          id="list-view"
          onClick={() => {
            setView('list');
          }}
        >
          <FontAwesomeIcon icon={faListUl} size="1x" />
          <div className="view-name">List</div>
        </div>
      </div>
    </div>
  );
};

export default LibraryHeader;
