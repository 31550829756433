import './GalleryBody.scss';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  galleryImagesSelector,
  pendingImageSelector,
} from 'redux/slices/itemGallery/selectors';
import Loader from 'common/components/loader/Loader';
import { FaTrash } from 'react-icons/fa';
import { Popconfirm, message } from 'antd';
import { deleteImage } from 'redux/slices/itemGallery/actions';
import { selectedTeamIdSelector } from 'redux/slices/app/selectors';

const GalleryBody = () => {
  const dispatch = useDispatch();
  const teamId = useSelector(selectedTeamIdSelector);
  const images = useSelector(galleryImagesSelector);
  const pendingImg = useSelector(pendingImageSelector);

  const renderImages = images.map(({ url, timestamp }) => (
    <div key={timestamp} className="image-card">
      <Popconfirm
        title="Are you sure to delete this image?"
        onConfirm={() => {
          dispatch(deleteImage(teamId, timestamp));
        }}
        okText="Yes"
        cancelText="No"
      >
        <FaTrash className="trash-icon" color="red" size={12} />
      </Popconfirm>
      <div
        className="img"
        style={{ backgroundImage: `url('${url}')` }}
        onClick={() => {
          (window as any).selectImage(url);
        }}
      ></div>
    </div>
  ));

  const renderPendingImg = pendingImg && (
    <div className="image-card">
      <div className="img" style={{ backgroundImage: `url('${pendingImg}')` }}>
        <Loader show centerSpinner></Loader>
      </div>
    </div>
  );

  return (
    <div id="gallery-body">
      {renderImages}
      {renderPendingImg}
    </div>
  );
};

export default GalleryBody;
