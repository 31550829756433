import { authAction } from './../types';
import { Action } from './../../../common/types';
import firebase from 'firebase-main';
import { setPaymentAction } from 'redux/slices/stripe/actions';

export const setRegistrationPending = (value: boolean): Action => ({
  type: authAction('SET', 'REGISTRATION_PENDING'),
  payload: value,
});

export const setRegistrationError = (err): Action => ({
  type: authAction('SET', 'REGISTRATION_ERROR'),
  payload: err,
});

export const registerUser = async (name, email, title = '', password) => {
  await firebase.auth().signOut();

  await firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    // TODO Remove this catch to disallow registered users from registering again
    .catch(function (error) {
      // Handle Errors here.
      console.error(errorMessage);
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode == 'auth/weak-password') {
        alert('The password is too weak.');
      } else {
        alert(errorMessage);
      }
      console.log(error);
    });

  const userCredentials = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);

  await firebase
    .database()
    .ref(`users/${userCredentials?.user?.uid}/public`)
    .update({ email, name, role: title })
    .catch((err) => {
      console.error(err);
    });

  return userCredentials.user?.uid;
};

export const registerUser2 = async (
  firstName,
  lastName,
  email,
  title = '',
  password
) => {
  await firebase.auth().signOut();

  await firebase.auth().createUserWithEmailAndPassword(email, password);

  const userCredentials = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);

  await firebase
    .database()
    .ref(`users/${userCredentials?.user?.uid}/public`)
    .update({ email, name: firstName, last_name: lastName, role: title });

  return userCredentials.user?.uid;
};

export const registerUserDrive =
  (
    name,
    email,
    title = '',
    password,
    stripeId,
    type: 'annual' | 'monthly',
    promoCode?,
    token?
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch(setRegistrationPending(true));
      await registerUser(name, email, title, password);

      const params: any = {
        paymentMethodId: stripeId,
        type,
        // TODO un-comment
        // deleteUserOnFail: true,
      };

      if (promoCode) {
        params.promoCode = promoCode;
      }

      if (token) {
        params.invitationToken = token;
      }

      await firebase
        .functions()
        .httpsCallable('createUserDrive')(params)
        .then((res) => {
          console.log(res.data);
          if (res.data.message === 'requires_action') {
            dispatch(setPaymentAction(res.data));
          } else if (res.data.message === 'success') {
            dispatch(setRegistrationPending(false));
            dispatch(setRegistrationError(null));
          } else {
            dispatch(setRegistrationPending(false));
            dispatch(setRegistrationError(null));
          }
        });
    } catch (err) {
      console.error(err);

      dispatch(setRegistrationError(err ?? 'error'));
      dispatch(setRegistrationPending(false));
      dispatch({
        type: 'TOASTER',
        meta: {
          toaster: {
            type: 'error',
            message: 'Failed to register',
            description: err,
          },
        },
      });
    }
  };

export const registerTeamDrive =
  (name, email, title = '', teamName = 'My Team', password, stripeId) =>
  async (dispatch, getState) => {
    try {
      dispatch(setRegistrationPending(true));
      await registerUser(name, email, title, password);

      await firebase
        .functions()
        .httpsCallable('createTeamDrive')({
          paymentMethodId: stripeId,
          teamName,
          // TODO un-comment
          // deleteUserOnFail: true,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.message === 'requires_action') {
            dispatch(setPaymentAction(res.data));
          } else {
            dispatch(setRegistrationPending(false));
          }
        });
    } catch (err) {
      console.error(err);

      dispatch(setRegistrationError(err ?? 'error'));
      dispatch(setRegistrationPending(false));
      dispatch({
        type: 'TOASTER',
        meta: {
          toaster: {
            type: 'error',
            message: 'Failed to register',
            description: err,
          },
        },
      });
    }
  };

export const registerTeamInvitation =
  (name, email, title = '', password, invitationToken) =>
  async (dispatch, getState) => {
    try {
      dispatch(setRegistrationPending(true));
      await registerUser(name, email, title, password);

      await firebase
        .functions()
        .httpsCallable('addUserByToken')({
          invitationToken,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.message === 'requires_action') {
            dispatch(setPaymentAction(res.data));
          } else {
            dispatch(setRegistrationPending(false));
          }
        });
    } catch (err) {
      console.error(err);

      dispatch(setRegistrationError(err ?? 'error'));
      dispatch(setRegistrationPending(false));
      dispatch({
        type: 'TOASTER',
        meta: {
          toaster: {
            type: 'error',
            message: 'Failed to register',
            description: err,
          },
        },
      });
    }
  };

export const registerPersonalInvitation =
  (name, email, title = '', password, invitationToken) =>
  async (dispatch, getState) => {
    try {
      dispatch(setRegistrationPending(true));
      await registerUser(name, email, title, password);

      await firebase
        .functions()
        .httpsCallable('addUserToPersonalDriveByToken')({
          invitationToken,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.message === 'requires_action') {
            dispatch(setPaymentAction(res.data));
          } else {
            dispatch(setRegistrationPending(false));
          }
        });
    } catch (err) {
      console.error(err);

      dispatch(setRegistrationError(err ?? 'error'));
      dispatch(setRegistrationPending(false));
      dispatch({
        type: 'TOASTER',
        meta: {
          toaster: {
            type: 'error',
            message: 'Failed to register',
            description: err,
          },
        },
      });
    }
  };
