import firebase from 'firebase-main';
import { teamAction } from './types';
import { Action } from 'redux/common/types';
import { toaster } from 'redux/middlewares/toasterMiddleware/toasterFactory';
import { toasterType } from 'redux/middlewares/toasterMiddleware/toasterTypes';
import { mapRolesToInt } from './util';
import axios from 'axios';
import { newNumOfLicensesSelector } from './selectors';

export const teamActionTypes = {
  SUBSCRIBE_TEAM_IMAGE: 'SUBSCRIBE_TEAM_IMAGE',
  UNSUBSCRIBE_TEAM_IMAGE: 'UNSUBSCRIBE_TEAM_IMAGE',
  SET_TEAM_IMAGE: 'SET_TEAM_IMAGE',

  UPDATE_TEAM_INFO: 'UPDATE_TEAM_INFO',
  UPDATE_TEAM_INFO_SUCCESS: 'UPDATE_TEAM_INFO_SUCCESS',
  UPDATE_TEAM_INFO_FAIL: 'UPDATE_TEAM_INFO_FAIL',

  UPDATE_TEAM_NAME: 'UPDATE_TEAM_NAME',

  FETCH_TEAM_MEMBERS: 'FETCH_TEAM_MEMBERS',
  FETCH_TEAM_MEMBERS_SUCCESS: 'FETCH_TEAM_MEMBERS_SUCCESS',
  FETCH_TEAM_MEMBERS_FAIL: 'FETCH_TEAM_MEMBERS_FAIL',

  FETCH_PENDING_INVITES: 'FETCH_PENDING_INVITES',
  FETCH_PENDING_INVITES_SUCCESS: 'FETCH_PENDING_INVITES_SUCCESS',
  FETCH_PENDING_INVITES_FAIL: 'FETCH_PENDING_INVITES_FAIL',

  FETCH_NON_TEAM_MEMBERS: 'FETCH_NON_TEAM_MEMBERS',
  FETCH_NON_TEAM_MEMBERS_SUCCESS: 'FETCH_NON_TEAM_MEMBERS_SUCCESS',
  FETCH_NON_TEAM_MEMBERS_FAIL: 'FETCH_NON_TEAM_MEMBERS_FAIL',
  EMPTY_NON_TEAM_MEMBERS: 'EMPTY_NON_TEAM_MEMBERS',

  ADD_TEAM_MEMBER: 'ADD_TEAM_MEMBER',
  ADD_TEAM_MEMBER_SUCCESS: 'ADD_TEAM_MEMBER_SUCCESS',
  ADD_TEAM_MEMBER_FAIL: 'ADD_TEAM_MEMBER_FAIL',

  REMOVE_TEAM_MEMBER: 'REMOVE_TEAM_MEMBER',
  REMOVE_TEAM_MEMBER_SUCCESS: 'REMOVE_TEAM_MEMBER_SUCCESS',
  REMOVE_TEAM_MEMBER_FAIL: 'REMOVE_TEAM_MEMBER_FAIL',

  EDIT_USER_ROLE_IN_TEAM: 'EDIT_USER_ROLE_IN_TEAM',
  EDIT_USER_ROLE_IN_TEAM_SUCCESS: 'EDIT_USER_ROLE_IN_TEAM_SUCCESS',
  EDIT_USER_ROLE_IN_TEAM_FAIL: 'EDIT_USER_ROLE_IN_TEAM_FAIL',

  EDIT_LICENSES_NUMBER: 'EDIT_LICENSES_NUMBER',
  EDIT_LICENSES_NUMBER_SUCCESS: 'EDIT_LICENSES_NUMBER_SUCCESS',
  EDIT_LICENSES_NUMBER_FAIL: 'EDIT_LICENSES_NUMBER_FAIL',

  CHECK_USER_EXISTANCE: 'CHECK_USER_EXISTANCE',
  USER_EXISTED: 'USER_EXISTED',
  USER_NOT_EXISTED: 'USER_NOT_EXISTED',

  INVITE_USER: 'INVITE_USER',
  INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
  INVITE_USER_FAIL: 'INVITE_USER_FAIL',

  REMOVE_INVITATION: 'REMOVE_INVITATION',
  REMOVE_INVITATION_SUCCESS: 'REMOVE_INVITATION_SUCCESS',
  REMOVE_INVITATION_FAIL: 'REMOVE_INVITATION_FAIL',

  CREATE_TEAM_INVITATION: 'CREATE_TEAM_INVITATION',
  CREATE_TEAM_INVITATION_SUCCESS: 'CREATE_TEAM_INVITATION_SUCCESS',
  CREATE_TEAM_INVITATION_FAIL: 'CREATE_TEAM_INVITATION_FAIL',

  SET_REQUIRES_ACTION_DATA: 'SET_REQUIRES_ACTION_DATA',

  GET_PAYMENT_METHOD_ID: 'GET_PAYMENT_METHOD_ID',
  SET_PAYMENT_METHOD_ID: 'SET_PAYMENT_METHOD_ID',

  GET_PAYMENT_INFO: 'GET_PAYMENT_INFO',
  SET_PAYMENT_INFO_DATA: 'SET_PAYMENT_INFO_DATA',

  GET_NEW_SUBSCRIPTION_COST: 'GET_NEW_SUBSCRIPTION_COST',
  GET_NEW_SUBSCRIPTION_COST_SUCCESS: 'GET_NEW_SUBSCRIPTION_COST_SUCCESS',
  GET_NEW_SUBSCRIPTION_COST_FAIL: 'GET_NEW_SUBSCRIPTION_COST_FAIL',

  UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
  UPDATE_PAYMENT_METHOD_SUCCESS: 'UPDATE_PAYMENT_METHOD_SUCCESS',
  UPDATE_PAYMENT_METHOD_FAIL: 'UPDATE_PAYMENT_METHOD_FAIL',

  CREATE_PAYMENT_CONFIRMATION: 'CREATE_PAYMENT_CONFIRMATION',
  UPDATE_PAYMENT_CONFIRMATION: 'UPDATE_PAYMENT_CONFIRMATION',

  INVITE_USER_TO_TEAM_SUCCESS: 'INVITE_USER_TO_TEAM_SUCCESS',
  INVITE_USER_TO_TEAM_FAIL: 'INVITE_USER_TO_TEAM_FAIL',
  INVITE_USER_TO_TEAM: 'INVITE_USER_TO_TEAM',
};

export const subscribeTeamImage = (teamId): Action => {
  return {
    type: teamActionTypes.SUBSCRIBE_TEAM_IMAGE,
    meta: {
      firebaseRealtimeApi: {
        req: 'SUBSCRIBE',
        id: 'team_image',
        url: `teams/${teamId}/public/thumbnail`,
        onSuccessDispatches: [setTeamImage],
      },
    },
  };
};

export const unsubscribeTeamImage = (): Action => {
  return {
    type: teamActionTypes.UNSUBSCRIBE_TEAM_IMAGE,
    meta: {
      firebaseRealtimeApi: {
        req: 'UNSUBSCRIBE',
        id: 'team_image',
      },
    },
  };
};

export const setTeamImage = (url): Action => {
  return { type: teamActionTypes.SET_TEAM_IMAGE, payload: url };
};

export const updateTeamInfo2 =
  (teamId: string, teamName: string, imgBlob?: Blob) =>
  async (dispatch: (Action) => any) => {
    dispatch({ type: teamActionTypes.UPDATE_TEAM_INFO });
    let imgUrl = null;
    if (imgBlob) {
      try {
        imgUrl = await (
          await firebase
            .storage()
            .ref(`teams/${teamId}/${teamId}.jpg`)
            .put(imgBlob)
        ).ref.getDownloadURL();
      } catch (err) {
        console.error(err);
        dispatch(updateTeamInfoFail('Failed to upload image', err));
        return;
      }
    }

    try {
      await firebase
        .database()
        .ref(`teams/${teamId}`)
        .update({ name: teamName });
    } catch (err) {
      console.error(err);
      dispatch(updateTeamInfoFail('Failed to update team name', err));
      return;
    }

    try {
      await firebase
        .database()
        .ref(`teams/${teamId}/public`)
        .update({ thumbnail: imgUrl });
    } catch (err) {
      console.error(err);
      dispatch(updateTeamInfoFail('Failed to update team image', err));
      return;
    }

    dispatch(updateTeamSuccess());
  };

export const updateTeamSuccess = (): Action => {
  return {
    type: teamActionTypes.UPDATE_TEAM_INFO_SUCCESS,
    meta: {
      toaster: { type: 'success', message: 'Info updated successfully' },
    },
  };
};

export const updateTeamInfoFail = (message, description): Action => {
  return {
    type: teamActionTypes.UPDATE_TEAM_INFO_FAIL,
    meta: { toaster: { type: 'error', message, description } },
  };
};

export const updateTeamInfo = (teamId: string, teamName: string): Action => {
  return {
    type: teamActionTypes.UPDATE_TEAM_NAME,
    payload: { name: teamName },
    meta: { firebaseRealtimeApi: { req: 'UPDATE', url: `teams/${teamId}` } },
  };
};

export const updateTeamThumbnail = (
  teamId: string,
  imageUrl: string
): Action => {
  return {
    type: teamActionTypes.UPDATE_TEAM_NAME,
    payload: { thumbnail: imageUrl },
    meta: {
      firebaseRealtimeApi: { req: 'UPDATE', url: `teams/${teamId}/public` },
    },
  };
};

export const uploadTeamImage =
  (teamId: string, imgBlob: Blob) => (dispatch) => {
    firebase
      .storage()
      .ref(`teams/${teamId}/${teamId}.jpg`)
      .put(imgBlob)
      .then(async (res) => {
        const url = await res.ref.getDownloadURL();
        dispatch(updateTeamThumbnail(teamId, url));
      })
      .catch((err) => {
        console.error(err);
      });
  };

//------------------------------ Fetch team members ----------------------------------------
export const fetchTeamMembers = (teamId) => async (dispatch, getState) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        getState().firebase.auth.stsTokenManager.accessToken
      }`,
    },
  };

  const body = {
    objectType: 'teams',
    objectID: teamId,
  };

  const api = process.env.REACT_APP_CLOUD_FN_URL;
  axios.post(`${api}/fetchMembersPublic`, body, config).then((res) => {
    const usersFirebaseObj = res.data.output;
    const users: any = [];
    for (const id in usersFirebaseObj) {
      users.push({
        id,
        ...usersFirebaseObj[id],
        display: usersFirebaseObj[id].name,
        avatar: usersFirebaseObj[id].avatar
          ? usersFirebaseObj[id].avatar
          : '/assets/images/user.png',
      });
    }

    dispatch(fetchTeamMembersSuccess(users));
  });
  // .catch((e) => {
  //     dispatch(fetchTeamMembersFail(e))
  // });
};

export const fetchTeamMembersSuccess = (teamMembers) => {
  return {
    type: teamActionTypes.FETCH_TEAM_MEMBERS_SUCCESS,
    payload: teamMembers,
  };
};

export const fetchTeamMembersFail = (errorMessage) => {
  return {
    type: teamActionTypes.FETCH_TEAM_MEMBERS_FAIL,
    payload: errorMessage,
    meta: {
      ...toaster(
        toasterType.error,
        'Failed to fetch team members',
        errorMessage
      ),
    },
  };
};

//-------------------------------------fetch pending accounts---------------------------------------
export const subscribePendingInvites = (teamId): Action => {
  return {
    type: teamActionTypes.FETCH_PENDING_INVITES,
    meta: {
      firebaseRealtimeApi: {
        url: `teams/${teamId}/pending_invites`,
        req: 'SUBSCRIBE',
        id: 'pendingInvites',
        onSuccessDispatches: [fetchPendingInvitesSuccess],
        onEmptyResponseDispatches: [fetchPendingInvitesSuccess],
      },
    },
  };
};

export const unsubscribePendingInvites = (): Action => {
  return {
    type: teamAction('UNSUBSCRIBE', 'PENDING_INVITES'),
    meta: { firebaseRealtimeApi: { req: 'UNSUBSCRIBE', id: 'pendingInvites' } },
  };
};

export const fetchPendingInvitesSuccess = (res) => {
  return {
    type: teamActionTypes.FETCH_PENDING_INVITES_SUCCESS,
    payload: res,
  };
};

//-------------------------------------Remove team member---------------------------------------------
export const removeTeamMember = (userId, teamId): Action => {
  return {
    type: teamActionTypes.REMOVE_TEAM_MEMBER,
    payload: {
      [userId]: null,
    },
    meta: {
      firebaseRealtimeApi: {
        url: `teams/${teamId}/members/`,
        req: 'UPDATE',
        onSuccessDispatches: [
          () => removeTeamMemberSuccess(userId),
          // () => getPaymentInfo(teamId),
        ],
        onFailDispatches: [
          removeTeamMemberFail as any,
          // () => getPaymentInfo(teamId) as any,
        ],
      },
    },
  };
};

export const removeTeamMemberSuccess = (userId) => {
  return {
    type: teamActionTypes.REMOVE_TEAM_MEMBER_SUCCESS,
    payload: userId,
  };
};

export const removeTeamMemberFail = (errorMessage) => {
  return {
    type: teamActionTypes.REMOVE_TEAM_MEMBER_FAIL,
    meta: {
      ...toaster(toasterType.error, 'Failed to add member. ', errorMessage),
    },
  };
};

//-------------------------------------Edit team member role -----------------------------------------
// export const editUserRoleInTeam = (userId, teamId, role) => {
//   return {
//     type: teamActionTypes.EDIT_USER_ROLE_IN_TEAM,
//     payload: { role: mapRolesToInt(role) },
//     meta: {
//       firebaseRealtimeApi: {
//         url: `teams/${teamId}/members/${userId}`,
//         req: fireRequestTypes.UPDATE,
//         onSuccessDispatches: [() => editUserRoleInTeamSuccess(userId, role)],
//         onFailDispatches: [editUserRoleInTeamSuccessFail],
//       },
//     },
//   };
// };

export const editUserRoleInTeam =
  (userId, teamId, role) => async (dispatch, getState) => {
    dispatch({
      type: teamActionTypes.EDIT_USER_ROLE_IN_TEAM,
      payload: { role: mapRolesToInt(role) },
    });

    const config = {
      headers: {
        Authorization: `Bearer ${
          getState().firebase.auth.stsTokenManager.accessToken
        }`,
      },
    };

    const body = {
      userId,
      teamId,
      role,
    };

    const api = process.env.REACT_APP_CLOUD_FN_URL;
    axios
      .post(`${api}/changeUserRole`, body, config)
      .then((res) => {
        dispatch(editUserRoleInTeamSuccess(userId, role));
        // dispatch(getPaymentInfo(teamId));
      })
      .catch((error) => {
        dispatch(editUserRoleInTeamSuccessFail(error?.response?.data?.message));
        // dispatch(getPaymentInfo(teamId));
      });
  };

export const editUserRoleInTeamSuccess = (userId, roleInTeam) => {
  return {
    type: teamActionTypes.EDIT_USER_ROLE_IN_TEAM_SUCCESS,
    payload: {
      userId,
      roleInTeam,
    },
    meta: {
      ...toaster(toasterType.success, "Successfully edited member's role."),
    },
  };
};

export const editUserRoleInTeamSuccessFail = (errorMessage) => {
  return {
    type: teamActionTypes.EDIT_USER_ROLE_IN_TEAM_FAIL,
    meta: {
      ...toaster(
        toasterType.error,
        'Failed to edit member role. ',
        errorMessage
      ),
    },
  };
};

//-------------------------------------Edit licenses number---------------------------------------------
export const editLicensesNumber =
  (teamId, number_of_licenses) => async (dispatch, getState) => {
    dispatch({
      type: teamActionTypes.EDIT_LICENSES_NUMBER,
      payload: number_of_licenses,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${
          getState().firebase.auth.stsTokenManager.accessToken
        }`,
      },
    };

    const body = {
      teamId,
      licenses: number_of_licenses,
    };

    const api = process.env.REACT_APP_CLOUD_FN_URL;
    axios
      .post(`${api}/updatePayment`, body, config)
      .then((res) => {
        if (res.data.message === 'requires_action') {
          dispatch(setRequiresActionData(res.data));
        } else {
          dispatch(editLicensesNumberSuccess(number_of_licenses));
        }
      })
      .catch((error) => {
        dispatch(editLicensesNumberFail(error));
      });
  };

export const editLicensesNumberSuccess = (numOfLicenses) => {
  return {
    type: teamActionTypes.EDIT_LICENSES_NUMBER_SUCCESS,
    payload: numOfLicenses,
    meta: {
      ...toaster(
        toasterType.success,
        'Number of licenses is updated successfully.'
      ),
    },
  };
};

export const editLicensesNumberFail = (errorMessage) => {
  return {
    type: teamActionTypes.EDIT_LICENSES_NUMBER_FAIL,
    meta: {
      ...toaster(
        toasterType.error,
        'Failed to edit the number of licenses',
        errorMessage
      ),
    },
  };
};

export const inviteUserToTeam =
  (teamId, email, role, done = () => {}) =>
  async (dispatch, getState) => {
    dispatch({ type: teamActionTypes.INVITE_USER_TO_TEAM });
    const config = {
      headers: {
        Authorization: `Bearer ${
          getState().firebase.auth.stsTokenManager.accessToken
        }`,
      },
    };

    const body = {
      teamId,
      email,
      role,
    };

    const api = process.env.REACT_APP_CLOUD_FN_URL;
    axios
      .post(`${api}/inviteUserToTeamWithRole`, body, config)
      .then((res) => {
        dispatch(inviteUserToTeamSuccess(res.data.message));
        done();
      })
      .catch((error) => {
        console.error(error?.response?.data?.message);
        dispatch(inviteUserToTeamFail(error?.response?.data?.message, email));
        done();
      });
  };

export const inviteUserToTeamSuccess = (message) => {
  return {
    type: teamActionTypes.INVITE_USER_TO_TEAM_SUCCESS,
    meta: {
      ...toaster(toasterType.success, message),
    },
  };
};
export const inviteUserToTeamFail = (errorMessage, email) => {
  return {
    type: teamActionTypes.INVITE_USER_TO_TEAM_FAIL,
    meta: {
      ...toaster(toasterType.error, `Failed to invite ${email}`, errorMessage),
    },
  };
};
//---------------------------------Check user existance------------------------------------------------

export const inviteUserSuccess = () => {
  return {
    type: teamActionTypes.INVITE_USER_SUCCESS,
    meta: {
      ...toaster(toasterType.success, 'Invitation sent successfully. '),
    },
  };
};

export const inviteUserFail = (errorMessage) => {
  return {
    type: teamActionTypes.INVITE_USER_FAIL,
    meta: {
      ...toaster(
        toasterType.error,
        'Invitation sent failed.',
        errorMessage.message
      ),
    },
  };
};

//----------------------------------Remove invitation-------------------------------------------------
export const removeInvitation = (teamId, invitationId): Action => {
  return {
    type: teamActionTypes.REMOVE_INVITATION,
    payload: { [invitationId]: null },
    meta: {
      firebaseRealtimeApi: {
        url: `teams/${teamId}/pending_invites/`,
        req: 'UPDATE',
        onSuccessDispatches: [
          removeInvitationSuccess,
          () => subscribePendingInvites(teamId),
        ],
        onFailDispatches: [removeInvitationFail],
      },
    },
  };
};

export const removeInvitationSuccess = () => {
  return {
    type: teamActionTypes.REMOVE_INVITATION_SUCCESS,
  };
};

export const removeInvitationFail = (errorMessage): Action => {
  return {
    type: teamActionTypes.REMOVE_INVITATION_FAIL,
    meta: {
      toaster: {
        type: 'error',
        message: 'Invitation remove failed.',
        description: errorMessage.message,
      },
    },
  };
};

//------------------------------Create team from invitation---------------------------------------------
export const createTeamInvitation =
  (body, done = (teamId = '') => {}) =>
  async (dispatch, getState) => {
    dispatch({ type: teamActionTypes.CREATE_TEAM_INVITATION });
    const config = {
      headers: {
        Authorization: `Bearer ${
          getState().firebase.auth.stsTokenManager.accessToken
        }`,
      },
    };

    const api = process.env.REACT_APP_CLOUD_FN_URL;
    axios
      .post(`${api}/createTeam`, body, config)
      .then((res) => {
        if (res.data.message === 'success') {
          dispatch(createTeamInvitationSuccess());
          done(res.data.teamId);
        } else if (res.data.message === 'requires_action') {
          dispatch(
            setRequiresActionData({
              client_secret: res.data.client_secret,
              paymentMethodId: res.data.paymentMethodId,
              responseToken: res.data.responseToken,
            })
          );
        } else if (res.data.message === 'requires_payment_method') {
          dispatch(createTeamInvitationFail('Please re-enter card data.'));
        }
      })
      .catch((error) => {
        console.error(error);

        dispatch(createTeamInvitationFail(error?.response?.data?.message));
      });
  };

export const createTeamInvitationSuccess = () => {
  return {
    type: teamActionTypes.CREATE_TEAM_INVITATION_SUCCESS,
    meta: {
      ...toaster(toasterType.success, 'Team created successfully.'),
    },
  };
};

export const createTeamInvitationFail = (errorMessage) => {
  return {
    type: teamActionTypes.CREATE_TEAM_INVITATION_FAIL,
    meta: {
      ...toaster(
        toasterType.error,
        'Error while creating the team.',
        errorMessage
      ),
    },
  };
};

// Method for handling "requires action" payment
export const setRequiresActionData = (data) => {
  return {
    type: teamActionTypes.SET_REQUIRES_ACTION_DATA,
    payload:
      data !== null
        ? {
            client_secret: data.client_secret,
            paymentMethodId: data.paymentMethodId,
            responseToken: data.responseToken,
          }
        : null,
  };
};
//----------------------------- Get payment info---------------------------------------------
export const getPaymentInfo = (teamId) => async (dispatch, getState) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        getState().firebase.auth.stsTokenManager.accessToken
      }`,
    },
  };

  const body = {
    teamId,
  };

  const api = process.env.REACT_APP_CLOUD_FN_URL;
  axios
    .post(`${api}/getPaymentMethodInfo`, body, config)
    .then((res) => {
      dispatch(setPaymentInfoData(res.data));
    })
    .catch((error) => {
      console.error(error);
    });
};

export const setPaymentInfoData = (data) => {
  return {
    type: teamActionTypes.SET_PAYMENT_INFO_DATA,
    payload: data,
  };
};

//------------------------------------- Get new subscription cost-----------------------------------

export const getNewSubscriptionCost =
  (teamId, numOfLicenses) => async (dispatch, getState) => {
    dispatch({
      type: teamActionTypes.GET_NEW_SUBSCRIPTION_COST,
      payload: numOfLicenses,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${
          getState().firebase.auth.stsTokenManager.accessToken
        }`,
      },
    };

    const body = {
      teamId,
      licenses: numOfLicenses,
    };

    const api = process.env.REACT_APP_CLOUD_FN_URL;
    axios
      .post(`${api}/getnewSubscriptionCost`, body, config)
      .then((res) => {
        dispatch(getNewSubscriptionCostSuccess(res.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(getNewSubscriptionCostFail(error));
      });
  };

export const getNewSubscriptionCostSuccess = (data) => {
  return {
    type: teamActionTypes.GET_NEW_SUBSCRIPTION_COST_SUCCESS,
    payload: data,
  };
};

export const getNewSubscriptionCostFail = (error) => {
  return {
    type: teamActionTypes.GET_NEW_SUBSCRIPTION_COST_FAIL,
  };
};

export const createPaymentConfirmationResponse =
  (responseToken, done) => async (dispatch, getState) => {
    dispatch({ type: teamActionTypes.CREATE_PAYMENT_CONFIRMATION });

    const config = {
      headers: {
        Authorization: `Bearer ${
          getState().firebase.auth.stsTokenManager.accessToken
        }`,
      },
    };

    const body = {
      responseToken,
    };

    const api = process.env.REACT_APP_CLOUD_FN_URL;
    axios
      .post(`${api}/createPaymentConfirmationResponse`, body, config)
      .then((res) => {
        done(res.data.teamId);
      })
      .catch((error) => {
        console.error(error);
      });
  };

export const updatePaymentConfirmation = () => async (dispatch, getState) => {
  const teamId = getState().app.selectedTeamId;
  const newNumOfLicenses = newNumOfLicensesSelector(getState());
  dispatch({ type: teamActionTypes.CREATE_PAYMENT_CONFIRMATION });

  const config = {
    headers: {
      Authorization: `Bearer ${
        getState().firebase.auth.stsTokenManager.accessToken
      }`,
    },
  };

  const body = {
    teamId,
  };

  const api = process.env.REACT_APP_CLOUD_FN_URL;
  axios
    .post(`${api}/upgradePaymentConfirmationResponse`, body, config)
    .then((res) => {
      dispatch(editLicensesNumberSuccess(newNumOfLicenses));
    })
    .catch((error) => {
      console.error(error);
      dispatch(editLicensesNumberFail(error));
    });
};
//----------------------------------Get payment method id--------------------------------------------
export const getPaymentMethodId = (teamId) => async (dispatch, getState) => {
  const config = {
    headers: {
      Authorization: `Bearer ${
        getState().firebase.auth.stsTokenManager.accessToken
      }`,
    },
  };

  const body = {
    teamId,
  };

  const api = process.env.REACT_APP_CLOUD_FN_URL;
  axios.post(`${api}/getPaymentMethodId`, body, config).then((res) => {
    dispatch({
      type: teamActionTypes.SET_PAYMENT_METHOD_ID,
      payload: res.data.paymentMethodId,
    });
  });
};

export const fetchInvoices = (teamId: string): Action => {
  return {
    type: teamAction('GET', 'INVOICES'),
    payload: { teamId },
    meta: {
      cloudRequest: {
        name: 'getInvoices',
        body: { teamId, library_version: (window as any).libraryVersion },
        onSuccessDispatches: [setInvoices],
        onFailDispatches: [fetchInvoicesFail],
      },
    },
  };
};

export const setInvoices = (invoices): Action => {
  return {
    type: teamAction('SET', 'INVOICES'),
    payload: invoices,
  };
};

export const fetchInvoicesFail = (data): Action => {
  return {
    type: teamAction('GET', 'INVOICES', 'FAIL'),
  };
};
