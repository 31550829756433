import './ItemsGrid.scss';
import _ from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react';
import Loader from 'common/components/loader/Loader';
import useScrollReachBottom from 'common/hooks/useScrollReachBottom';
import ItemCard from 'backlot-library/components/item/item-card/ItemCard';
import { calculateRowSize } from './util';
import { ProgressBar } from 'react-bootstrap';

type propTypes = {
  className?: string;
  items?: any[];
  viewType?: 'medium' | 'large' | 'list';
  itemsPending?: boolean;
  extraItemsPending?: boolean;
  showDownloadBar?: boolean;
  showProgressBar?: boolean;
  progressBarText?: string;
  progressBarValue?: number;
  showSubcategory?: boolean;
  onPageReachBottom?: () => void;
  onPageReachBottomDependencies?: any[];
  onItemClick?: (item: any) => void;
  onPackPurchase?: (packId: any) => void;
  onDownload?: () => void;
  isFocused?: boolean;
};

const ItemsGrid = (
  {
    className = '',
    items = [],
    viewType = 'large',
    itemsPending = false,
    extraItemsPending = false,
    showDownloadBar = false,
    showProgressBar = false,
    progressBarText = '',
    progressBarValue,
    showSubcategory = false,
    onPageReachBottom = () => {},
    onPageReachBottomDependencies,
    onItemClick = () => {},
    onPackPurchase = () => {},
    onDownload = () => {},
    isFocused: isContainerFocused = false,
  }: propTypes,
  containerRef
) => {
  const [refsArray, setRefsArray] = useState([] as any[]);
  const [focusedItem, setFocusedItem] = useState(0);

  useScrollReachBottom(
    containerRef,
    onPageReachBottom,
    onPageReachBottomDependencies
  );

  useEffect(() => {
    if (items) setRefsArray(items.map(() => React.createRef()));
  }, [items]);

  // Focuses on the current focusedItem
  // useEffect(() => {
  //   if (isContainerFocused && refsArray[focusedItem]?.current) {
  //     refsArray[focusedItem].current.focus();
  //   }
  // }, [refsArray, focusedItem, isContainerFocused]);

  useEffect(() => {
    if (isContainerFocused) {
      setFocusedItem(0);
    }
  }, [isContainerFocused]);

  const renderDownloadBar = (
    <div className="download-bar">
      <p>Download prop pack</p>
      <button onClick={() => onDownload()}>Download</button>
    </div>
  );

  const renderProgressBar = (
    <div className="items-grid-progress-bar">
      <div>
        <p>{progressBarText}</p>
        <ProgressBar now={progressBarValue} />
      </div>
    </div>
  );

  const renderItems = items?.map((item, index) => {
    const itemFocused = focusedItem === index && isContainerFocused;
    return (
      <ItemCard
        className={`${itemFocused ? 'focused' : ''} ${
          showDownloadBar ? 'transparent' : ''
        }`}
        key={item.itemID}
        showSubcategory={showSubcategory}
        item={item}
        tabIndex="0"
        ref={refsArray[index]}
        onItemClick={onItemClick}
        onPackPurchase={onPackPurchase}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && itemFocused) {
            onItemClick(item);
          }
        }}
      />
    );
  });

  const scrollToItem = (itemRef) => {
    // if (containerRef?.current && itemRef?.current) {
    //   const container = containerRef.current;
    //   const item = itemRef.current;
    //   container.scrollTo(0, item.offsetTop - container.clientHeight / 2);
    // }
  };

  // Allows navigation using up/down/left/right arrow keys
  // const onKeyDown = (e) => {
  //   if (!isContainerFocused) return;
  //   let focusChanged = false;
  //   let nextItem = focusedItem;

  //   if (e.key === 'ArrowLeft') {
  //     focusChanged = true;
  //     nextItem--;
  //   } else if (e.key === 'ArrowRight') {
  //     focusChanged = true;
  //     nextItem++;
  //   } else if (e.key === 'ArrowUp') {
  //     focusChanged = true;
  //     const rowSize = calculateRowSize(viewType);
  //     nextItem -= rowSize;
  //   } else if (e.key === 'ArrowDown') {
  //     focusChanged = true;
  //     const rowSize = calculateRowSize(viewType);
  //     nextItem += rowSize;
  //   }

  //   if (nextItem < 0) nextItem = items.length - 1;
  //   if (nextItem >= items.length) nextItem = 0;

  //   if (focusChanged) {
  //     setFocusedItem(nextItem);
  //     e.preventDefault();
  //     scrollToItem(refsArray[nextItem]);
  //   }
  // };

  const emptyPage = <div id="empty-items-grid">No items Available</div>;

  const renderView = _.isEmpty(renderItems) ? emptyPage : renderItems;

  return (
    <>
      <div
        id="items-grid"
        className={`${className} ${isContainerFocused ? 'focused' : ''} ${
          showProgressBar ? 'downloading' : ''
        }`}
        ref={containerRef}
        tabIndex={0}
        // onKeyDown={onKeyDown}
      >
        {showProgressBar && renderProgressBar}

        {showDownloadBar && renderDownloadBar}

        <Loader
          show={itemsPending}
          centerSpinner
          backgroundStyle={{
            height: '100%',
            width: '100%',
            gridColumn: '1/-1',
          }}
        >
          {renderView}
          <Loader
            show={extraItemsPending}
            centerSpinner
            spinnerSize={25}
            backgroundStyle={{ width: '100%', marginTop: '1rem' }}
          />
        </Loader>
      </div>
    </>
  );
};

export default forwardRef(ItemsGrid);
