import './BlueprintInfo.scss';
import React, { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Tabs } from 'antd';
import ElementComments from './elementComments/ElementComments';
import { useDispatch, useSelector } from 'react-redux';
import {
  blueprintEnvironmentSettingsSelector,
  selectedObjectTabSelector,
} from 'redux/slices/blueprints/selectors';
import { setSelectedOBjectTab } from 'redux/slices/blueprints/actions';
import ClipModal from './clipModal/ClipModal';
import { openCommentsModal } from 'redux/slices/comments/actions';
import { IComment } from 'redux/slices/comments/types';
import { ICameraEquipment } from 'redux/slices/blueprints/types';
import { FaCloud } from 'react-icons/fa';
import { IoMdCloud, IoMdCloudOutline } from 'react-icons/io';
import { svgTypes } from 'common/config/blueprints';
import CommentCard from 'backlot-comments/components/comments/Comment/Comment';

const { TabPane } = Tabs;

type propTypes = {
  id: any;
  clipUrl?: string;
  // info,
  title: string;
  type?: string;
  body?: any[];
  thumbnail?: string;
  showVideo?: boolean;
  comments: IComment[];
  equipment?: ICameraEquipment;
};

const BlueprintInfo = ({
  id,
  clipUrl, // Url for the corresponding video clip
  title,
  // info,
  type = '',
  body = [],
  thumbnail,
  showVideo,
  comments,
  equipment,
}: propTypes) => {
  const dispatch = useDispatch();
  const playerRef = useRef(null);
  const cardTitleRef = useRef(null);
  const selectedObjectTab = useSelector(selectedObjectTabSelector);

  const environmentSettings =
    useSelector(blueprintEnvironmentSettingsSelector) ?? {};

  const cardTitle = title ? title : 'Nothing selected';
  const cardTitleIcon =
    type === svgTypes.CAMERA ? (
      <span className="title-icon">
        <img src="/blueprints/camera-info.svg" alt="camera" />
      </span>
    ) : null;

  const renderTime = () => {
    if (environmentSettings['Time of Day']) {
      let hr = Math.floor(environmentSettings['Time of Day']);
      const m = Math.round(
        (environmentSettings['Time of Day'] -
          Math.floor(environmentSettings['Time of Day'])) *
          60
      );

      const d = hr >= 12 ? 'PM' : 'AM';

      if (hr > 12) hr -= 12;

      return `${hr} : ${m} ${d}`;
    } else return 'unset';
  };

  const renderEnvironmentSettings = (
    <div className="env-settings">
      <div className="title">Scene Settings</div>
      <div className="detail">
        <img src="/blueprints/calendar.svg" alt="calendar" />
        <span> {renderTime()}</span>
      </div>
      <div className="detail">
        <img src="/blueprints/pin.svg" alt="calendar" />
        <span> {environmentSettings['Location'] ?? 'Unset'}</span>
      </div>
      <div className="detail">
        <IoMdCloudOutline size={16} />
        <span> {environmentSettings['Weather'] ?? 'Unset'}</span>
      </div>
    </div>
  );

  const renderEquipment = equipment && (
    <div className="cam-equipment">
      {Object.keys(equipment).map(
        (equipmentType) =>
          equipment[equipmentType] && (
            <div key={equipmentType}>{equipmentType}</div>
          )
      )}
    </div>
  );

  let player: any;
  if (showVideo) {
    player = !clipUrl ? null : (
      <div className="blueprint-info-player">
        <video
          ref={playerRef}
          width="100%"
          height="250px"
          // url={clipUrl}
          src={clipUrl}
          controls={true}
        />
      </div>
    );
  }

  const transformPropsValues = (key, value) => {
    try {
      switch (key) {
        case 'Aperture':
          return 'f/' + (value as number).toFixed(1);
        case 'Camera Height':
          return `${(value as number).toFixed(2)}m`;
        case 'Shutter Speed':
          return '1/' + value;
        case 'Focal Length':
          return `${(value as number).toFixed(2)}mm`;
        case 'Focus Distance':
          return `${(value as number).toFixed(2)}m`;
        case 'Color Temperature':
          return `${value}K`;
        case 'Light Gel':
          return (
            <div className="color-pad" style={{ backgroundColor: value }} />
          );
        default:
          return value;
      }
    } catch (err) {
      return '-';
    }
  };

  const cardBody = body.map(([key, value]) => (
    <Row key={key} className="card-row">
      <Col xs={6} lg={5}>
        <span className="info-key">{key}</span>
      </Col>
      <Col xs={6} lg={7}>
        <span className="info-value">
          <b>{transformPropsValues(key, value)}</b>
        </span>
      </Col>
    </Row>
  ));

  const latestComment =
    comments.length > 0 ? comments[comments.length - 1] : null;
  const renderLatestComment = latestComment ? (
    <div className="latest-comment">
      <div className="title" style={{ display: 'flex' }}>
        <div>Latest Comment</div>
        <div className="ml-auto">
          <a onClick={() => dispatch(openCommentsModal(clipUrl ?? ''))}>
            View More
          </a>
        </div>
      </div>
      <div>
        <CommentCard
          key={latestComment.id}
          commentId={latestComment.id ?? ''}
          commentType="OBJECT_COMMENT"
          containerRefId={id}
          commentorId={latestComment.commentorId}
          commentorName={latestComment.commentorName}
          role={latestComment.role}
          timeStamp={latestComment.date}
          text={latestComment.text}
          likes={latestComment.likes}
          // shotID={latestComment.shotID}
          // myComment={latestComment.myComment}
          // videoPath={latestComment.shotID}
          player={playerRef}
          tags={latestComment.tags}
          replies={latestComment.replies}
          // parentId={latestComment.parentId}
        />
      </div>
    </div>
  ) : null;

  const objectThumbnail = thumbnail ? (
    <div className="object-thumbnail">
      <img className="align-self-center my-2" src={thumbnail} alt="thumbnail" />
    </div>
  ) : null;

  const elementInfo = (
    <div className="d-flex flex-column">
      {renderEquipment}
      {objectThumbnail}
      {player}
      {cardBody}
      {clipUrl ? renderLatestComment : null}
    </div>
  );

  const renderCard = (
    <div className="mt-4">
      <div className={!title ? 'info-empty' : ''}>
        <h5 ref={cardTitleRef} className="card-title">
          {cardTitleIcon}
          <b>{cardTitle}</b>
        </h5>

        {title ? (
          <Tabs
            defaultActiveKey="info"
            onChange={(key) => dispatch(setSelectedOBjectTab(key))}
            activeKey={selectedObjectTab}
            style={{ color: 'white' }}
          >
            <TabPane tab="Info" key="info">
              {elementInfo}
            </TabPane>

            <TabPane tab="Comments" key="comments">
              <ElementComments
                commentType="OBJECT_COMMENT"
                objectId={id}
                // type={type}
                cardTitleRef={cardTitleRef}
              />
            </TabPane>
          </Tabs>
        ) : null}
      </div>
    </div>
  );
  return (
    <React.Fragment>
      {showVideo ? <ClipModal clipUrl={clipUrl} comments={comments} /> : null}

      {title ? <div>{renderCard}</div> : <div>{renderEnvironmentSettings}</div>}
    </React.Fragment>
  );
};

export default BlueprintInfo;
