import { isEmpty } from 'react-redux-firebase';
import { createSelector } from 'reselect';

export const isLoggedInSelector = createSelector(
  (state) => state.firebase.auth,
  (auth) => !isEmpty(auth)
);
export const userLastNameSelector = (state) =>
  state.firebase.profile?.public?.last_name;

export const userEmailSelector = (state) =>
  state.firebase?.profile.public?.email;

export const userLocationSelector = (state) =>
  state.firebase.profile.public?.location;

export const userProjectsSelector = (state) => state.firebase.profile?.projects;
export const userScenesSelector = (state) => state.firebase.profile?.scenes;

export const userNameSelector = (state) => state.firebase.profile.public?.name;
export const userImageSelector = (state) =>
  state.firebase.profile.public?.thumbnail;
export const userIdSelector = (state) => state.firebase.auth.uid;
export const userRoleSelector = (state) => state.firebase.profile.public?.role;

export const userIndustrySelector = (state) =>
  state.firebase.profile.public?.industry;

// export const userRecentScenesSelector = createSelector(
//   (state) => state.firebase.profile?.recent_scenes,
//   (state) => state.app.selectedTeamId,
//   (recentScenes = {}, teamId = null) => (teamId ? recentScenes[teamId] : null)
// );

// export const userRecentScenesArraySelector = createSelector(
//   userRecentScenesSelector,
//   (recentScenes = {}) => {
//     let result = [];
//     for (const id in recentScenes) {
//       result.push({
//         id,
//         ...recentScenes[id],
//       });
//     }
//     result.sort((a, b) => new Date(b.date * 1000) - new Date(a.date * 1000));
//     return result;
//   }
// );

export const registrationPendingSelector = (state) =>
  state.auth.registrationPending;

export const registrationErrorSelector = (state) =>
  state.auth.registrationError;
