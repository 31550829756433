import './TutorialsModal.scss';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';

export const TutorialsModal = ({ onCancel }) => {
  return (
    <Modal
      className="tutorials-modal"
      visible={true}
      onCancel={onCancel}
      title={null}
      footer={null}
      closable={false}
    >
      <h1>Welcome to Backlot</h1>
      <h4>Check out some tutorials to get you started.</h4>
      <div className="close-btn" onClick={onCancel}>
        X
      </div>
      <iframe src="https://backlot.studio/backlot-tutorials-yt"></iframe>
    </Modal>
  );
};
