import './Library.scss';
import './styleSheets/medium-icons.scss';
import './styleSheets/large-icons.scss';
import './styleSheets/list-icons.scss';
import './styleSheets/list-icons-panorama.scss';
import React, { useEffect, useRef, useState } from 'react';
import LibrarySidebar from './library-sidebar/LibrarySidebar';
import LibraryHeader from './library-header/LibraryHeader';
import ItemsGrid from '../item/items-grid/ItemsGrid';
import { subCategory } from './types';
import useScrollReachBottom from 'common/hooks/useScrollReachBottom';

type viewTypes = 'medium' | 'large' | 'list';

type propTypes = {
  title: string;
  showSidebar?: boolean;
  sidebarItems: subCategory[];
  mainSubcategories?: subCategory[];
  sidebarItemsPending?: boolean;
  selectedSidebarItem: string;
  items: any[];
  itemsPending?: boolean;
  extraItemsPending?: boolean;
  showDownloadBar?: boolean;
  showProgressBar?: boolean;
  progressBarText?: string;
  progressBarValue?: number;
  defaultView?: viewTypes;
  onSidebarItemClick?: (subcategory: string) => void;
  onSubcategoryDownload?: (subcategory: string) => void;
  onItemClick?: (item: any) => void;
  onPackPurchase?: (packId: any) => void;
  onSearch?: () => void;
  onScrollReachBottom?: () => void;
  onPageReachBottomDependencies?: any[];
};

const Library = ({
  title = 'Library',
  showSidebar = true,
  mainSubcategories = [],
  sidebarItems = [],
  sidebarItemsPending = false,
  selectedSidebarItem = '',
  onSidebarItemClick = () => {},
  defaultView = 'medium',

  items = [],
  itemsPending = false,
  extraItemsPending = false,
  showDownloadBar = false,
  showProgressBar = false,
  progressBarValue,
  progressBarText = '',

  onItemClick = () => {},
  onPackPurchase = () => {},
  onSubcategoryDownload = () => {},
  onSearch = () => {},

  onScrollReachBottom = () => {},
  onPageReachBottomDependencies,
}: propTypes) => {
  const sidebarRef = useRef(null);
  const storeRef = useRef(null);

  const itemsContainerRef = useRef<any>();

  const [view, setView] = useState<viewTypes>(defaultView);
  const [focusView, setFocusView] = useState(showSidebar ? 0 : 1);

  useScrollReachBottom(
    itemsContainerRef,
    onScrollReachBottom,
    onPageReachBottomDependencies
  );

  const showSubcategory =
    title === 'Props' &&
    (selectedSidebarItem === 'All' || selectedSidebarItem === 'Featured');
  // Handles focus on views
  useEffect(() => {
    if (focusView === 0 && sidebarRef.current) {
      (sidebarRef.current as any).focus();
    } else if (focusView === 1 && storeRef.current) {
      (storeRef.current as any).focus();
    }
  }, [focusView, sidebarRef, storeRef]);

  // const onKeyDown = (e) => {
  //   if (e.key === 'a' || e.key === 'd') {
  //     if (showSidebar) setFocusView(1 - focusView);
  //     else setFocusView(1);
  //     e.preventDefault();
  //   }
  // };

  let filteredItems = items;
  if (
    ['Props', 'Characters', 'Set Construction'].includes(title) &&
    ['medium', 'large'].includes(view)
  ) {
    filteredItems = items.map((item) => ({
      ...item,
      hideMeta: true,
    }));
  }

  return (
    // <div id="library" tabIndex={0} onKeyDown={onKeyDown}>
    <div id="library" tabIndex={0}>
      <LibraryHeader
        title={title}
        view={view}
        setView={setView}
        onSearch={onSearch}
      />

      <div
        id="lib-main"
        className={`${view}-view ${title === 'Film Sets' ? 'panorama' : ''}`}
      >
        {showSidebar && (
          <LibrarySidebar
            ref={sidebarRef}
            mainSubcategories={mainSubcategories}
            subCategories={sidebarItems}
            subCategoriesPending={sidebarItemsPending}
            isFocused={focusView === 0}
            selectedSubCategory={selectedSidebarItem}
            onSubMenuClick={onSidebarItemClick}
            onSubMenuDownload={onSubcategoryDownload}
          />
        )}

        <div className="items-container" ref={itemsContainerRef}>
          <ItemsGrid
            ref={storeRef}
            className={showSidebar ? 'has-sidebar' : ''}
            viewType={view}
            items={filteredItems}
            itemsPending={itemsPending}
            extraItemsPending={extraItemsPending}
            isFocused={focusView === 1}
            showSubcategory={showSubcategory}
            // showDownloadBar={showDownloadBar}
            // showProgressBar={showProgressBar}
            // progressBarText={progressBarText}
            // progressBarValue={progressBarValue}
            onPageReachBottom={onScrollReachBottom}
            onPageReachBottomDependencies={onPageReachBottomDependencies}
            onItemClick={onItemClick}
            onPackPurchase={onPackPurchase}
            onDownload={() => onSubcategoryDownload(selectedSidebarItem)}
          />
        </div>
      </div>
    </div>
  );
};

export default Library;
