import './Sky.scss';
import React, { useEffect, useState } from 'react';
import ImageWithText from '../common/image-with-text/ImageWithText';
import { Select, Slider } from 'antd';
import CheckMark from '../common/check-mark/CheckMark';
import useDebounce from 'common/hooks/useDebounce';
import { usePrevious } from 'common/hooks/usePrevious';
import firebase from 'firebase-main';
import { ColorPicker } from 'backlot-character-wizard/color-picker/ColorPicker';

type item = {
  id: number;
  src: string;
  alt: string;
  text: string;
  defaultValue: number;
};

const Sky = () => {
  const items: item[] = [
    {
      id: 0,
      src: 'cloud',
      alt: 'cloud',
      text: 'Cloud Coverage',
      defaultValue: 0.64,
    },
    // {
    //   id: 1,
    //   src: 'wind',
    //   alt: 'wind',
    //   text: 'Wind Intensity',
    //   defaultValue: 0.2,
    // },
    {
      id: 1,
      src: 'sun',
      alt: 'sun',
      text: 'Sun Light Intensity',
      defaultValue: 0.5,
    },
    // {
    //   id: 3,
    //   src: 'moon',
    //   alt: 'moon',
    //   text: 'Moon Light Intensity',
    //   defaultValue: 0.5,
    // },
    { id: 2, src: 'fog', alt: 'fog', text: 'Fog Intensity', defaultValue: 0.4 },
    {
      id: 3,
      src: 'fogHeight',
      alt: 'fog',
      text: 'Ambient Light Probe Dimmer',
      defaultValue: 0.4,
    },
  ];

  const [sliderValues, setSliderValues] = useState<number[]>(
    items.map(({ defaultValue }) => defaultValue)
  );

  const [slidersDebounced] = useDebounce<number[]>(sliderValues, 1500);
  const [editingSliders, setEditingSliders] = useState<{
    [id: string]: boolean;
  }>({});
  // const prevSliderValues = usePrevious(slidersDebounced) as number[];

  const [fogScale, setFogScale] = useState(0);
  // const [fogColor, setFogColor] = useState({ r: 0, g: 0, b: 0, a: 1 });
  const [fogColor, setFogColor] = useState(null);
  const [fogColorDebounced] = useDebounce<any>(fogColor, 1500);
  const [editingFogColor, setEditingFogColor] = useState(false);

  const [fogEnabled, setFogEnabled] = useState(true);
  const [groundEnabled, setGroundEnabled] = useState(false);
  const [groundVisible, setGroundVisible] = useState(false);

  const updateSlider = (index: number, newValue) =>
    setSliderValues(
      sliderValues.map((oldValue, i) => (i === index ? +newValue : oldValue))
    );

  useEffect(() => {
    for (let i = 0; i < (slidersDebounced as number[]).length; i++) {
      if (editingSliders[items[i].id]) {
        (window as any).onSliderDebounced(items[i].text, slidersDebounced[i]);
        // firebase.analytics().logEvent('scene_settings', {
        //   [items[i].text]: slidersDebounced[i],
        // });
      }
    }
    if (Object.keys(editingSliders).length > 0) setEditingSliders({});
  }, [slidersDebounced]);

  useEffect(() => {
    if (fogColorDebounced && editingFogColor) {
      (window as any).onFogColorChangeDebounced(fogColorDebounced);
      setEditingFogColor(false);
    }
  }, [fogColorDebounced]);

  const setCloud = ({ params }) => updateSlider(0, params.value);
  const setWind = ({ params }) => updateSlider(1, params.value);
  const setSun = ({ params }) => updateSlider(1, params.value);
  const setMoon = ({ params }) => updateSlider(3, params.value);
  const setFog = ({ params }) => updateSlider(2, params.value);
  const setLightDimmerUi = ({ params }) => updateSlider(3, params.value);
  const setFogScaleUi = ({ params }) => setFogScale(params);
  const setFogColorUi = ({ params }) => setFogColor(params);
  const setFogEnabledUi = ({ params }) => setFogEnabled(params.enabled);
  const setGroundEnabledUi = ({ params }) => setGroundEnabled(params.enabled);
  const setGroundVisibleUi = ({ params }) => setGroundVisible(params.enabled);

  useEffect(() => {
    window.addEventListener('setCloudCoverage', setCloud as any);
    window.addEventListener('setWindIntensity', setWind as any);
    window.addEventListener('setSunLightIntensity', setSun as any);
    window.addEventListener('setMoonLightIntensity', setMoon as any);
    window.addEventListener('setFogIntensity', setFog as any);

    window.addEventListener('setLightDimmer', setLightDimmerUi as any);
    window.addEventListener('setFogScale', setFogScaleUi as any);
    window.addEventListener('setFogColor', setFogColorUi as any);

    window.addEventListener('setFogEnabled', setFogEnabledUi as any);
    window.addEventListener('setGroundEnabled', setGroundEnabledUi as any);
    window.addEventListener('setGroundVisible', setGroundVisibleUi as any);

    return () => {
      window.removeEventListener('setCloudCoverage', setCloud as any);
      window.removeEventListener('setWindIntensity', setWind as any);
      window.removeEventListener('setSunLightIntensity', setSun as any);
      window.removeEventListener('setMoonLightIntensity', setMoon as any);
      window.removeEventListener('setFogIntensity', setFog as any);

      window.removeEventListener('setLightDimmer', setLightDimmerUi as any);
      window.removeEventListener('setFogScale', setFogScaleUi as any);
      window.removeEventListener('setFogColor', setFogColorUi as any);

      window.removeEventListener('setFogEnabled', setFogEnabledUi as any);
      window.removeEventListener('setGroundEnabled', setGroundEnabledUi as any);
      window.removeEventListener('setGroundVisible', setGroundVisibleUi as any);
    };
  }, [sliderValues, fogEnabled, groundEnabled]);

  const renderItems = (items: item[]) =>
    items.map(({ id, src, alt, text }) => (
      <React.Fragment key={src}>
        <ImageWithText
          src={`/backlot-library/scene-settings/sky/${src}.svg`}
          alt={alt}
          text={text}
        />
        <input
          className="num-input"
          type="number"
          min={0}
          max={100}
          value={Math.round(sliderValues[id] * 100)}
          onChange={(e) => {
            setEditingSliders({ ...editingSliders, [id]: true });
            const v = +e.target.value;
            const newValue = Math.max(0, Math.min(100, v)) / 100;
            setSliderValues(
              sliderValues.map((oldValue, index) =>
                index === id ? newValue : oldValue
              )
            );
            (window as any).onSliderChange(text, newValue);
          }}
        />
        <Slider
          min={0}
          max={1}
          step={0.01}
          tipFormatter={(v = 0) => `${Math.round(v * 100)}%`}
          value={sliderValues[id]}
          onChange={(newValue: number) => {
            setEditingSliders({ ...editingSliders, [id]: true });
            setSliderValues(
              sliderValues.map((oldValue, index) =>
                index === id ? newValue : oldValue
              )
            );
            (window as any).onSliderChange(text, newValue);
          }}
          defaultValue={1}
        />
      </React.Fragment>
    ));

  return (
    <div className="sky">
      <div className="title">
        <b>Sky</b>
      </div>
      <div id="body">
        <div className="options">{renderItems(items)}</div>

        {/* <div className="options">
          <ImageWithText
            src={`/backlot-library/scene-settings/sky/fog.svg`}
            alt={'FogScale'}
            text={'Fog Scale'}
          />
          <Select
            value={fogScale}
            onChange={(v) => {
              setFogScale(v);
              (window as any).onFogScaleChange(v);
            }}
          >
            <Select.Option value={0}>Small Fog Scale</Select.Option>
            <Select.Option value={1}>Large Fog Scale</Select.Option>
          </Select>
        </div> */}

        <div className="options">
          <ImageWithText
            src={`/backlot-library/scene-settings/sky/fog.svg`}
            alt={'FogScale'}
            text={'Fog Color'}
          />
          <ColorPicker
            color={fogColor}
            disableAlpha={true}
            onChange={(c) => {
              setFogColor(c);
              setEditingFogColor(true);
              (window as any).onFogColorChange(c);
            }}
          />
        </div>

        <div className="option">
          <CheckMark
            label="Enable Fog"
            checked={fogEnabled}
            onChange={(checked) => {
              setFogEnabled(checked);
              (window as any).onAdvancedOptionChange('Fog', checked);
              firebase.analytics().logEvent('scene_settings', { fog: checked });
            }}
          />
        </div>

        <div className="option">
          <CheckMark
            label="Ground Plane Enabled"
            checked={groundEnabled}
            onChange={(checked) => {
              setGroundEnabled(checked);
              (window as any).onAdvancedOptionChange('Ground', checked);
              firebase
                .analytics()
                .logEvent('scene_settings', { ground: checked });
            }}
          />
        </div>

        <div className="option">
          <CheckMark
            label="Grid Visible in Camera"
            checked={groundVisible}
            onChange={(checked) => {
              setGroundVisible(checked);
              (window as any).onAdvancedOptionChange('GroundVisible', checked);
              // firebase
              //   .analytics()
              //   .logEvent('scene_settings', { ground: checked });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Sky;
