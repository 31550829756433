import { sendAmplitudeEvents } from 'redux/common/actions';
import { userIdSelector } from 'redux/slices/auth/authSelectors';
import firebase from 'firebase-main';
import { Action, ThunkAction } from 'redux/common/types';
import axios from 'axios';
import _ from 'lodash';
import { fireRequestTypes } from 'redux/middlewares/firebaseRealtimeApiMiddleware/firebaseRequestTypes';
import { firebaseRealtimeRequest } from 'redux/middlewares/firebaseRealtimeApiMiddleware/firebaseRealtimeApiFactory';
import { toaster } from 'redux/middlewares/toasterMiddleware/toasterFactory';
import { toasterType } from 'redux/middlewares/toasterMiddleware/toasterTypes';
import {
  extractProjectMembersData,
  extractSceneMembersData,
} from './preparers';
import { setAppLoader } from '../app/actions';
import { appAction } from '../app/types';

export const projectActionTypes = {
  CREATE_PROJECT: 'CREATE_PROJECT',
  CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAIL: 'CREATE_PROJECT_FAIL',

  REMOVE_PROJECT: 'REMOVE_PROJECT',
  REMOVE_PROJECT_SUCCESS: 'REMOVE_PROJECT_SUCCESS',
  REMOVE_PROJECT_FAIL: 'REMOVE_PROJECT_FAIL',

  FETCH_PROJECT_MEMBERS: 'FETCH_PROJECT_MEMBERS',
  FETCH_PROJECT_MEMBERS_SUCCESS: 'FETCH_PROJECT_MEMBERS_SUCCESS',
  FETCH_PROJECT_MEMBERS_FAIL: 'FETCH_PROJECT_MEMBERS_FAIL',

  ADD_PROJECT_MEMBERS: 'ADD_PROJECT_MEMBERS',
  ADD_PROJECT_MEMBERS_SUCCESS: 'ADD_PROJECT_MEMBERS_SUCCESS',
  ADD_PROJECT_MEMBERS_FAIL: 'ADD_PROJECT_MEMBERS_FAIL',
  REMOVE_PROJECT_MEMBERS: 'REMOVE_PROJECT_MEMBERS',
  REMOVE_PROJECT_MEMBERS_SUCCESS: 'REMOVE_PROJECT_MEMBERS_SUCCESS',
  REMOVE_PROJECT_MEMBERS_FAIL: 'REMOVE_PROJECT_MEMBERS_FAIL',

  CREATE_SCENE: 'CREATE_SCENE',
  CREATE_SCENE_SUCCESS: 'CREATE_SCENE_SUCCESS',
  CREATE_SCENE_FAIL: 'CREATE_SCENE_FAIL',

  FETCH_SCENE_MEMBERS: 'FETCH_SCENE_MEMBERS',
  FETCH_SCENE_MEMBERS_SUCCESS: 'FETCH_SCENE_MEMBERS_SUCCESS',
  FETCH_SCENE_MEMBERS_FAIL: 'FETCH_SCENE_MEMBERS_FAIL',
  EMPTY_SCENE_MEMBERS: 'EMPTY_SCENE_MEMBERS',

  FETCH_SCENE_VERSIONS: 'FETCH_SCENE_VERSIONS',
  FETCH_SCENE_VERSIONS_SUCCESS: 'FETCH_SCENE_VERSIONS_SUCCESS',
  FETCH_SCENE_VERSIONS_FAIL: 'FETCH_SCENE_VERSIONS_FAIL',

  REVERT_SCENE_VERSION: 'REVERT_SCENE_VERSION',
  REVERT_SCENE_VERSION_SUCCESS: 'REVERT_SCENE_VERSION_SUCCESS',
  REVERT_SCENE_VERSION_FAIL: 'REVERT_SCENE_VERSION_FAIL',

  ADD_SCENE_MEMBERS: 'ADD_SCENE_MEMBERS',
  ADD_SCENE_MEMBERS_SUCCESS: 'ADD_SCENE_MEMBERS_SUCCESS',
  ADD_SCENE_MEMBERS_FAIL: 'ADD_SCENE_MEMBERS_FAIL',
  REMOVE_SCENE_MEMBERS: 'REMOVE_SCENE_MEMBERS',
  REMOVE_SCENE_MEMBERS_SUCCESS: 'REMOVE_SCENE_MEMBERS_SUCCESS',
  REMOVE_SCENE_MEMBERS_FAIL: 'REMOVE_SCENE_MEMBERS_FAIL',

  DELETE_SCENE: 'DELETE_SCENE',
  DELETE_SCENE_SUCCESS: 'DELETE_SCENE_SUCCESS',
  DELETE_SCENE_FAIL: 'DELETE_SCENE_FAIL',
};

// const devApiUrl = 'https://us-central1-prefilm-78ac1.cloudfunctions.net';
// const prodApiUrl = 'https://us-central1-prefilm-production.cloudfunctions.net';

// ------------------------------ Create new project -----------------------------------------
// export const createPersonalProject = (project, userId): ThunkAction => async (
//   dispatch,
//   getState
// ) => {
//   let newProjectKey: string | null = null;
//   try {
//     newProjectKey = await (await firebase.database().ref(`projects/`).push())
//       .key;
//     const newProjectId: any = newProjectKey;
//     const newProject = {
//       [newProjectId]: {
//         name: project.name,
//         owner_id: userId,
//         creator_id: userId,
//         is_public: !project.isPublic,
//       },
//     };

//     firebase
//       .database()
//       .ref('/')
//       .update({
//         [`drives/${userId}/projects/${newProjectKey ?? ''}`]: project.name,
//         [`projects/${newProjectKey}`]: {
//           name: project.name,
//           owner_id: userId,
//           creator_id: userId,
//           is_public: !project.isPublic,
//         },
//       })
//       .catch((err) => dispatch(createNewProjectFail(newProjectKey, err)));
//   } catch (err) {
//     dispatch(createNewProjectFail(newProjectKey, err));
//   }
// };

const createNewProjectFail =
  (projKey, err): ThunkAction =>
  async (dispatch, getState) => {
    try {
      console.error(err);
      dispatch({
        type: appAction('CREATE', 'PROJECT', 'FAIL'),
        payload: err,
        meta: {
          toaster: { type: 'error', message: 'Failed to create project' },
        },
      });
      if (projKey) {
        await firebase.database().ref(`projects/${projKey}`).remove();
      }
    } catch (err) {
      console.error(err);
    }
  };

export const removeProject = (projectId: string): Action => {
  return {
    type: projectActionTypes.REMOVE_PROJECT,
    payload: { removed: true },
    meta: {
      firebaseRealtimeApi: {
        req: 'UPDATE',
        url: `projects/${projectId}`,
        onSuccessDispatches: [removeProjectSuccess],
        onFailDispatches: [removeProjectFail],
      },
    },
  };
};

export const removeProjectSuccess = (): Action => {
  return {
    type: projectActionTypes.REMOVE_PROJECT_SUCCESS,
    meta: {
      toaster: {
        type: 'success',
        message: 'Project removed Successfully',
      },
    },
  };
};

export const removeProjectFail = (err): Action => {
  return {
    type: projectActionTypes.REMOVE_PROJECT_FAIL,
    meta: {
      toaster: {
        type: 'error',
        message: 'Failed to remove project',
        description: err,
      },
    },
  };
};

// --------------------------- Fetch project's members --------------------------------
export const fetchProjectMembers = (projectId, teamMembers) => {
  return {
    type: projectActionTypes.FETCH_PROJECT_MEMBERS,
    meta: {
      ...firebaseRealtimeRequest(
        fireRequestTypes.GET,
        `projects/${projectId}/members`,
        [fetchProjectMembersSuccess],
        [],
        [fetchProjectMembersFail],
        [extractProjectMembersData(teamMembers)]
      ),
    },
  };
};

export const fetchProjectMembersSuccess = (projectMembers) => {
  return {
    type: projectActionTypes.FETCH_PROJECT_MEMBERS_SUCCESS,
    payload: projectMembers,
  };
};

export const fetchProjectMembersFail = (errorMessage) => {
  return {
    type: projectActionTypes.FETCH_PROJECT_MEMBERS_FAIL,
    payload: errorMessage,
    meta: {
      // ...toaster(toasterType.error, 'Failed to fetch teams', errorMessage),
      ...toaster(
        toasterType.warning,
        'Failed to fetch project members.',
        errorMessage
      ),
    },
  };
};

// --------------------------------- Create Scene ---------------------------------
export const createScene =
  (scene, projectId, userId, projectMembers?) => async (dispatch, getState) => {
    const newSceneRef = await firebase
      .database()
      .ref(`scenes/`)
      .push()
      .catch((err) => {
        console.error(err);
      });
    const newSceneId: any = (newSceneRef as any).key;
    const newScene = {
      // [newSceneId]: {
      name: scene.name,
      owner_id: userId,
      project_id: projectId,
      is_public: scene.isPublic,
      // },
    };

    // Add the new scene but with loading for creation
    // dispatch({
    //   type: appActionTypes.SET_SCENES,
    //   payload: {
    //     ...getState().app.scenes,
    //     [newSceneId]: {
    //       name: scene.name,
    //       loading: true,
    //     },
    //   },
    // });

    // dispatch({
    //   type: projectActionTypes.CREATE_SCENE,
    //   payload: newScene,
    //   meta: {
    //     ...firebaseRealtimeRequest(
    //       fireRequestTypes.UPDATE,
    //       `scenes/${newSceneId}`,
    //       [() => listenerForNewScene(userId, newSceneId, projectId)],
    //       [() => listenerForNewScene(userId, newSceneId, projectId)],
    //       [createSceneFail]
    //     ),
    //   },
    // });
    firebase
      .database()
      .ref(`projects/${projectId}/scenes`)
      .update({ [newSceneId]: newScene.name });

    await firebase
      .database()
      .ref(`scenes/${newSceneId}`)
      .update(newScene)
      .catch((err) => {
        console.error(err);
      });

    if (!scene.isPublic) {
      dispatch(addMembersToScene(scene.members, newSceneId, projectMembers));
    }
  };

export const createSceneSuccess = () => {
  return {
    type: projectActionTypes.CREATE_SCENE_SUCCESS,
    meta: {
      // ...toaster(toasterType.error, 'Failed to fetch scenes', errorMessage),
      ...toaster(toasterType.success, 'New scene created successfully.'),
    },
  };
};

export const createSceneFail = (errorMessage) => {
  console.error(errorMessage);

  return {
    type: projectActionTypes.CREATE_SCENE_FAIL,
    payload: errorMessage,
    meta: {
      // ...toaster(toasterType.error, 'Failed to fetch scenes', errorMessage),
      ...toaster(
        toasterType.warning,
        'Failed to create new scene. ',
        errorMessage
      ),
    },
  };
};

//---------------------------Listener for new scene created in the projects---------------------------
export const projectsListenerForNewScene =
  (projectId, newSceneId, userScenes) => (dispatch) => {
    const ref = firebase
      .database()
      .ref(`projects/${projectId}/scenes/${newSceneId}`);
    ref.on('value', (snapshot) => {
      if (snapshot.val() !== null) {
        // dispatch(fetchScenesForProject(projectId, userScenes));
        dispatch(createSceneSuccess());
        ref.off();
      }
    });
  };

//------------------------------------- Fetch scene members ------------------------------------------
export const fetchSceneMembers = (sceneId, projectMembers) => {
  return {
    type: projectActionTypes.FETCH_SCENE_MEMBERS,
    meta: {
      ...firebaseRealtimeRequest(
        fireRequestTypes.GET,
        `scenes/${sceneId}/members`,
        [fetchSceneMembersSuccess],
        [],
        [fetchSceneMembersFail],
        [extractSceneMembersData(projectMembers)]
      ),
    },
  };
};

export const fetchSceneMembersSuccess = (sceneMembers) => {
  return {
    type: projectActionTypes.FETCH_SCENE_MEMBERS_SUCCESS,
    payload: sceneMembers,
  };
};

export const fetchSceneMembersFail = (errorMessage) => {
  return {
    type: projectActionTypes.FETCH_SCENE_MEMBERS_FAIL,
    payload: errorMessage,
    meta: {
      // ...toaster(toasterType.error, 'Failed to fetch teams', errorMessage),
      ...toaster(
        toasterType.warning,
        'Failed to fetch scene members.',
        errorMessage
      ),
    },
  };
};

export const emptySceneMembers = () => {
  return { type: projectActionTypes.EMPTY_SCENE_MEMBERS };
};

//--------------------------------- Add Scene members ---------------------------------------

export const addMembersToScene =
  (members, sceneId, projectMembers) => (dispatch) => {
    if (members) {
      dispatch({
        type: projectActionTypes.ADD_SCENE_MEMBERS,
        payload: members,
        meta: {
          ...firebaseRealtimeRequest(
            fireRequestTypes.UPDATE,
            `scenes/${sceneId}/members`,
            [
              addMembersToSceneSuccess,
              () => fetchSceneMembers(sceneId, projectMembers),
            ],
            [],
            [addMembersToSceneFail]
          ),
        },
      });
    }
  };

export const addMembersToSceneSuccess = () => {
  return {
    type: projectActionTypes.ADD_SCENE_MEMBERS_SUCCESS,
  };
};

export const addMembersToSceneFail = (errorMessage) => {
  return {
    type: projectActionTypes.ADD_SCENE_MEMBERS_FAIL,
    payload: errorMessage,
    meta: {
      // ...toaster(toasterType.error, 'Failed to fetch teams', errorMessage),
      ...toaster(
        toasterType.warning,
        'Failed to add scene members.',
        errorMessage
      ),
    },
  };
};

//--------------------------- Remove members from scene ------------------------------------
export const removeMembersFromScene = (memberId, sceneId, projectMembers) => {
  return {
    type: projectActionTypes.REMOVE_SCENE_MEMBERS,
    payload: { [memberId]: null },
    meta: {
      ...firebaseRealtimeRequest(
        fireRequestTypes.UPDATE,
        `scenes/${sceneId}/members/`,
        [
          removeMembersFromSceneSuccess,
          () => fetchSceneMembers(sceneId, projectMembers),
        ],
        [],
        [removeMembersFromSceneFail]
      ),
    },
  };
};

export const removeMembersFromSceneSuccess = () => {
  return {
    type: projectActionTypes.REMOVE_SCENE_MEMBERS_SUCCESS,
  };
};

export const removeMembersFromSceneFail = (errorMessage) => {
  return {
    type: projectActionTypes.REMOVE_SCENE_MEMBERS_FAIL,
    payload: errorMessage,
    meta: {
      // ...toaster(toasterType.error, 'Failed to fetch teams', errorMessage),
      ...toaster(
        toasterType.warning,
        'Failed to remove this scene member.',
        errorMessage
      ),
    },
  };
};

//------------------------------- Update recent scenes----------------------------------------------------
export const updateRecentScenes =
  (teamId, projectId, sceneId) => async (dispatch, getState) => {
    const config = {
      headers: {
        Authorization: `Bearer ${
          getState().firebase.auth.stsTokenManager.accessToken
        }`,
      },
    };

    const body = {
      teamId,
      projectId,
      sceneId,
    };

    const api = process.env.REACT_APP_REALTIME_URL;

    const res = await axios.post(`${api}/updateRecentScenes`, body, config);
  };

export const openScene =
  (teamId, projectId, sceneId, sceneName) => async (dispatch, getState) => {
    const userId = userIdSelector(getState());
    const body = {
      teamId,
      projectId,
      sceneId,
    };

    dispatch(setAppLoader(true));

    try {
      await firebase.functions().httpsCallable('updateRecentScenes')(body);
    } catch (err) {
      console.error('Failed to update recent scenes');
    }

    try {
      firebase.analytics().logEvent('open_scene', sceneName);
      dispatch(
        sendAmplitudeEvents([{ event_type: 'open_scene', user_id: userId }])
      );
    } catch (err) {
      console.error('failed to log events', err);
    }

    (window as any).selectScene(sceneId, sceneName, projectId);
  };

export const fetchSceneVersions =
  (sceneId = '') =>
  async (dispatch, getState) => {
    if (sceneId.length === 0) {
      return;
    }
    dispatch({ type: projectActionTypes.FETCH_SCENE_VERSIONS });
    const config = {
      headers: {
        Authorization: `Bearer ${
          getState().firebase.auth.stsTokenManager.accessToken
        }`,
      },
    };

    const body = {
      sceneId,
    };

    const api = process.env.REACT_APP_REALTIME_URL;

    await axios
      .post(`${api}/getSceneVersions`, body, config)
      .then((res) => {
        const versions = res.data;
        dispatch(fetchSceneVersionsSuccess(versions));
      })
      .catch((err) => {
        console.log(err);
        dispatch(fetchSceneVersionsFail(err?.response?.data?.message));
      });
  };

export const fetchSceneVersionsSuccess = (versions) => {
  return {
    type: projectActionTypes.FETCH_SCENE_VERSIONS_SUCCESS,
    payload: versions,
  };
};

export const fetchSceneVersionsFail = (errorMessage) => {
  return {
    type: projectActionTypes.FETCH_SCENE_VERSIONS_FAIL,
    payload: errorMessage,
    meta: {
      // ...toaster(toasterType.error, 'Failed to fetch teams', errorMessage),
      ...toaster(
        toasterType.warning,
        'Failed to remove this scene.',
        errorMessage
      ),
    },
  };
};

export const revertSceneVersion =
  (sceneId = '', timestamp = '') =>
  async (dispatch, getState) => {
    if (sceneId.length === 0 || timestamp.length === 0) {
      return;
    }
    dispatch({ type: projectActionTypes.REVERT_SCENE_VERSION });
    const config = {
      headers: {
        Authorization: `Bearer ${
          getState().firebase.auth.stsTokenManager.accessToken
        }`,
      },
    };

    const body = {
      sceneId,
      timestamp,
    };

    const api = process.env.REACT_APP_REALTIME_URL;

    await axios
      .post(`${api}/revertSceneVersions`, body, config)
      .then((res) => {
        const { message } = res.data;
        dispatch(revertSceneVersionSuccess(message));
      })
      .catch((err) => {
        console.log(err);
        dispatch(revertSceneVersionFail(err?.response?.data?.message));
      });
  };

export const revertSceneVersionSuccess = (message): Action => {
  return {
    type: projectActionTypes.REVERT_SCENE_VERSION_SUCCESS,
    meta: {
      // ...toaster(toasterType.error, 'Failed to fetch teams', errorMessage),
      // ...toaster(toasterType.success, message),
      toaster: { type: 'success', message },
      amplitude: { events: [{ event_type: 'revert_scene' }] },
    },
  };
};

export const revertSceneVersionFail = (errorMessage) => {
  return {
    type: projectActionTypes.REVERT_SCENE_VERSION_FAIL,
    payload: errorMessage,
    meta: {
      // ...toaster(toasterType.error, 'Failed to fetch teams', errorMessage),
      ...toaster(
        toasterType.warning,
        'Failed to revert to this version.',
        errorMessage
      ),
    },
  };
};
